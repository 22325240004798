import { Component, ElementRef, EventEmitter, forwardRef, Input, Output, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService } from 'src/app/shared/service/common.service';

@Component({
  selector: 'app-custom-editor',
  templateUrl: './custom-editor.component.html',
  styleUrls: ['./custom-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomEditorComponent),
      multi: true, 
    }
  ]
})
export class CustomEditorComponent implements ControlValueAccessor{

  @Input() formControlName: string = '';
  /**
    * Event emitter for changes in the content, emits form group data, section ID, and group control name
    */
  @Output() contentChange = new EventEmitter();

  /**
   * Reference to the link dialog template element in the DOM
   */
  @ViewChild('linkDialog') linkDialog!: TemplateRef<HTMLDivElement>;

  /**
   * The URL value for the link being inserted
   */
  hrefValue: string = '';

  /**
   * Boolean indicating whether an existing link is being edited
   */
  isEditLink: boolean = false;

  /** 
   * Toolbar buttons configuration for text formatting options.
   */
  toolBarButtons = [
    { icon: 'format_bold', tooltip: 'Bold (Ctrl+B)', toolCommand: 'bold' },
    { icon: 'format_italic', tooltip: 'Italic (Ctrl+I)', toolCommand: 'italic' },
    { icon: 'format_underlined', tooltip: 'Underline (Ctrl+U)', toolCommand: 'underline' },
    { icon: 'strikethrough_s', tooltip: 'Strikethrough', toolCommand: 'strikeThrough' },
    { icon: 'format_list_numbered', tooltip: 'Numbered list', toolCommand: 'insertOrderedList' },
    { icon: 'list', tooltip: 'Unordered list', toolCommand: 'insertUnorderedList' }
  ];

  /** 
   * Reference to the editor element in the DOM.
   */
  @ViewChild('editor', { static: true }) editor!: ElementRef;

  /** 
   * Font size options for the editor.
   */
  fontSizeOptions = [
    { name: 'Heading 1', value: '32px', weight: 'bold' },
    { name: 'Heading 2', value: '24px', weight: 'bold' },
    { name: 'Heading 3', value: '18.72px', weight: 'bold' },
    { name: 'Heading 4', value: '16px', weight: 'bold' },
    { name: 'Heading 5', value: '13.28px', weight: 'bold' },
    { name: 'Heading 6', value: '10.72px', weight: 'bold' },
    { name: 'Paragraph', value: '16px', weight: 'normal' }
  ];

  /** 
   * Define an array of alignment options for text.
   */
  alignments = [
    { value: 'left', tooltip: 'Align Left', icon: 'format_align_left' },
    { value: 'center', tooltip: 'Align Center', icon: 'format_align_center' },
    { value: 'right', tooltip: 'Align Right', icon: 'format_align_right' },
    { value: 'justify', tooltip: 'Align Justify', icon: 'format_align_justify' }
  ];

  /** 
   * Font family options for the editor.
   */
  fontFamilyOptions = [
    { name: 'ABeeZee', value: "'ABeeZee', sans-serif" },
    { name: 'Amatic SC', value: "'Amatic SC', sans-serif" },
    { name: 'AR One Sans', value: "'AR One Sans', sans-serif" },
    { name: 'Archivo', value: "'Archivo', sans-serif" },
    { name: 'Arima', value: "'Arima', system-ui" },
    { name: 'Cormorant', value: "'Cormorant', serif" },
    { name: 'Crimson Text', value: "'Crimson Text', serif" },
    { name: 'Dancing Script', value: "'Dancing Script', serif" },
    { name: 'EB Garamond', value: "'EB Garamond', serif" },
    { name: 'Exo 2', value: "'Exo 2', sans-serif" },
    { name: 'Handlee', value: "'Handlee', cursive" },
    { name: 'Heebo', value: "'Heebo', sans-serif" },
    { name: 'Inconsolata', value: "'Inconsolata', monospace" },
    { name: 'Instrument Sans', value: "'Instrument Sans', sans-serif" },
    { name: 'Inter', value: "'Inter', sans-serif" },
    { name: 'Jost', value: "'Jost', sans-serif" },
    { name: 'Kalam', value: "'Kalam', cursive" },
    { name: 'Lato', value: "'Lato', sans-serif" },
    { name: 'Libre Baskerville', value: "'Libre Baskerville', serif" },
    { name: 'Lora', value: "'Lora', serif" },
    { name: 'monospace', value: 'monospace' },
    { name: 'Montserrat', value: "'Montserrat', sans-serif" },
    { name: 'Nunito', value: "'Nunito', sans-serif" },
    { name: 'Open Sans', value: "'Open Sans', sans-serif" },
    { name: 'Oswald', value: "'Oswald', sans-serif" },
    { name: 'Playfair Display', value: "'Playfair Display', serif" },
    { name: 'Poppins', value: "'Poppins', sans-serif" },
    { name: 'Press Start 2P', value: "'Press Start 2P', cursive" },
    { name: 'Raleway', value: "'Raleway', sans-serif" },
    { name: 'Reem Kufi', value: "'Reem Kufi', sans-serif" },
    { name: 'Roboto', value: "'Roboto', sans-serif" },
    { name: 'Roboto Condensed', value: "'Roboto Condensed', sans-serif" },
    { name: 'Roboto Mono', value: "'Roboto Mono', monospace" },
    { name: 'Satisfy', value: "'Satisfy', cursive" },
    { name: 'Sofia', value: "'Sofia', sans-serif" },
    { name: 'Sorts Mill Goudy', value: "'Sorts Mill Goudy', serif" },
    { name: 'Tangerine', value: "'Tangerine', cursive" }
  ];
  /** Function to propagate changes back to the form model. */
  private onChange: (value: string) => void = () => {};

  /** Function to mark the control as 'touched'. */
  private onTouched: () => void = () => {};

  /** Used do avound using bypassSecurityTrustHtml after the component loaded in dom */
  pos:number=0;

  form!: FormGroup;

  /** 
   * Initializes the component and sets up the form.
   * @param fb Creates the form group
   * @param sanitizer Sanitizes values to prevent XSS
   * @param dialog Manages dialog interactions
   * @param renderer Manipulates the DOM
   */
  constructor(
    private fb: FormBuilder,
    public sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private renderer: Renderer2,
    private commonService: CommonService
  ) {
    // Initialize the form with an empty editorContent control
    this.form = this.fb.group({
      editorContent: ['']
    });
  }

  /**
     * Sets up subscription to editorContent changes.
     * Propagates changes to parent component and form control.
     */
  ngOnInit() {
    this.form.get('editorContent')?.valueChanges.subscribe(value => {
      this.onChange(value);  // Update ControlValueAccessor
      this.contentChange.emit({ [this.formControlName]: value });  // Notify parent
    });
  }

  returnInnerHtml(value : string){
    this.pos++;
    return value && this.pos < 2 ? this.sanitizer.bypassSecurityTrustHtml(value) : value;
  }

  /**
   * Sets editor content when form control value changes externally.
   * @param value New content to be set in the editor
   */
  writeValue(value: string): void {
    if (this.form && this.form.get('editorContent')) {
      this.form.get('editorContent')?.setValue(value!==undefined?this.commonService.decryptAES(value):'');
    }
  }

  /**
   * Registers the callback to be invoked when editor content changes.
   * @param fn Callback function to update form model
   */
  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  /**
   * Registers the callback to be invoked when the editor is blurred.
   * @param fn Callback function to mark control as touched
   */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /**
     * Enables or disables the editor based on form control state.
     * @param isDisabled Whether the editor should be disabled
     */
  setDisabledState(isDisabled: boolean): void {
    if (this.editor && this.editor.nativeElement) {
      this.renderer.setProperty(this.editor.nativeElement, 'contentEditable', !isDisabled);
    }
  }

  /**
   * Adds a specified tag to the selected text in the editor.
   * @param tagName - The HTML tag to apply.
   * @param isActive - Indicates if the tag should be applied or removed.
   */
  addTag(tagName:string, isActive : boolean) {
    document.execCommand(tagName, false);
    this.emit();
  }

  /**
   * Sets the text alignment for the selected block element.
   * @param align - The desired text alignment ('left', 'center', 'right', or 'justify').
   */
  setAlignment(align:string): void {
    const selection = this.getEditorSelection();
    
    if (selection && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      let startParentElement = range.startContainer.parentElement;

      while (startParentElement) {
        if (this.isBlockElement(startParentElement)) {
          startParentElement.style.textAlign = align;
          break;
        } else {
          startParentElement = startParentElement.parentElement;
        }
      }
    }
    this.emit();
  }

  /**
   * Checks if the given HTML element is a block-level element.
   * @param element - The HTML element to check.
   * @returns A boolean indicating whether the element is a block-level element.
   */
  isBlockElement(element: HTMLElement): boolean {
    const display = window.getComputedStyle(element).display;
    return ['block', 'flex', 'table', 'list-item', 'grid'].includes(display);
  }

  /**
   * Retrieves the current selection in the editor.
   * @returns The current selection or null if no selection exists.
   */
  getEditorSelection(): Selection | null {
    const editorElement = this.editor.nativeElement;
    
    if (!editorElement) return null;

    const selection = window.getSelection();
    
    if (!selection || selection.rangeCount === 0) return null;

    const range = selection.getRangeAt(0);
    
    if (!editorElement.contains(range.commonAncestorContainer)) return null;

    return selection;
  }

  /**
   * Applies various text styles to the currently selected text in the document.
   * @param fontFamily - The font family to apply to the selected text. Optional.
   * @param fontSize - The font size to apply to the selected text. Optional.
   * @param fontWeight - The font weight to apply to the selected text. Optional.
   * @param fontColor - An object containing color information and background color flag. Optional.
   * @param event - Optional event to prevent default behavior.
   */
  applyTextStyle(fontFamily?: string, fontSize?: string, fontWeight?: string, fontColor?: { color:string, isBgColor:boolean }, event?: Event): void {
    const selection = window.getSelection();
    
    if (!selection || selection.rangeCount === 0) return;

    const range = selection.getRangeAt(0);
    
    const selectedText = range.toString();
    
    if (!selectedText) return;

    // Extract contents from the range
    const extractedContents = range.extractContents();

    // Create a document fragment to hold styled content
    const fragment = document.createDocumentFragment();

    // Helper function to apply styles to a node
    const applyStyles = (node : HTMLElement) => {
      if (fontSize) node.style.fontSize = fontSize;
      if (fontWeight) node.style.fontWeight = fontWeight;
      if (fontFamily) node.style.fontFamily = fontFamily;
      if (fontColor) {
        node.style.color = !fontColor.isBgColor ? fontColor.color : '';
        node.style.backgroundColor = fontColor.isBgColor ? fontColor.color : '';
      }
    };

    // Iterate through nodes in extracted contents
    extractedContents.childNodes.forEach((node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        const textSpan = document.createElement('span');
        applyStyles(textSpan);
        textSpan.textContent = node.textContent;
        fragment.appendChild(textSpan);
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        const clonedElement = (node as HTMLElement).cloneNode(true) as HTMLElement;
        applyStyles(clonedElement);

        // Recursively apply styles to children
        const walker = document.createTreeWalker(clonedElement, NodeFilter.SHOW_ELEMENT | NodeFilter.SHOW_TEXT, null);
        
        let currentNode : Node | null;
        while ((currentNode = walker.nextNode())) {
          if (currentNode.nodeType === Node.ELEMENT_NODE) {
            applyStyles(currentNode as HTMLElement);
          }
        }

        fragment.appendChild(clonedElement);
      }
    });

    // Insert styled fragment into the range
    range.deleteContents(); // Clear existing content before inserting new styled content
    range.insertNode(fragment);
    this.emit();
    // Restore selection covering newly inserted content
    const newRange = document.createRange();
    if (fragment.lastChild) {
     newRange.setStartAfter(fragment.lastChild);
     newRange.collapse(true);
     selection.removeAllRanges();
     selection.addRange(newRange);
    } else {
        console.warn('Fragment does not have a lastChild.');
    }
  }

 /**
   * Clears all formatting from the content within the editor by removing HTML tags and updating active states.
   */
 clearAllFormatting(): void {
     const editorElement = this.editor.nativeElement;
     if (editorElement) {
         editorElement.innerHTML = editorElement.textContent || '';
     }
     this.emit();
 }

/**
 * Create or edit a link based on the current selection in the editor.
 */
createLink(): void {
  // Get the current selection in the editor.
  const selection: Selection | null = this.getEditorSelection();
  let range: Range;
  let selectedText = '';

  // Check if there is a valid selection with at least one range.
  if (selection && selection.rangeCount > 0) {
    // Retrieve the first range from the selection.
    range = selection.getRangeAt(0);
    
    // Get the text currently selected in the editor.
    selectedText = range.toString();

    // Retrieve the current URL (href) value if editing an existing link.
    this.hrefValue = this.getHref(selection);
    
    // Determine if we are editing an existing link based on the href value.
    this.isEditLink = this.hrefValue.length > 0;

    // Open a dialog to input or edit the link.
    const dialogRef = this.dialog.open(this.linkDialog);

    // Subscribe to the dialog's close event to handle the response.
    dialogRef.afterClosed().subscribe((res) => {
      // If a response is returned from the dialog.
      if (res) {
        // Reset hrefValue and isEditLink for the next operation.
        this.hrefValue = '';
        this.isEditLink = false;

        // If the user chose to unlink the existing link.
        if (res === 'unlink') {
          this.unlink(range);
        } 
        // If the user is editing an existing link.
        else if (res.isEdit) {
          this.editLink(range, res.link);
        } 
        // If the user is creating a new link.
        else if (!res.isEdit) {
          // Create a new anchor (<a>) element.
          const anchor = this.renderer.createElement('a');

          // Set the href attribute for the new anchor.
          this.renderer.setAttribute(anchor, 'href', res.link);

          // Add a pointer cursor style to the anchor.
          this.renderer.setStyle(anchor, 'cursor', 'pointer');

          // Set the target attribute to open the link in a new tab.
          this.renderer.setAttribute(anchor, 'target', '_blank');

          // If there is selected text, wrap it in the anchor.
          if (selectedText.length > 0) {
            range.surroundContents(anchor);
          } else {
            // If no text is selected, create a text node with the link and insert it.
            anchor.appendChild(document.createTextNode(res.link));
            range.insertNode(anchor);
          }
        }

        // Emit any changes to update the state or UI.
        this.emit();
      }
    });
  }
}


/**
 * Updates the href attribute of the nearest anchor (<a>) element within the specified range to a new URL.
 * @param range - The Range object representing the current selection range.
 * @param newHref - The new URL to set as the href attribute.
 */
editLink(range: Range, newHref: string): void {
  // Get the common ancestor container of the current selection range.
  const container = range.commonAncestorContainer;
  
  // Initialize a variable to hold the nearest anchor element, if found.
  let parentAnchor: HTMLAnchorElement | null = null;

  // Check if the common ancestor is a text node.
  if (container.nodeType === Node.TEXT_NODE) {
    // Get the parent element of the text node, which should be an HTMLElement.
    const parentElement = container.parentElement as HTMLElement | null;

    // If a parent element exists, find the closest anchor element.
    if (parentElement) {
      parentAnchor = parentElement.closest('a');
    }
  } 
  // Check if the common ancestor is an element node.
  else if (container.nodeType === Node.ELEMENT_NODE) {
    // Cast the container to an HTMLElement.
    const containerElement = container as HTMLElement;

    // Query for the first anchor element within this container.
    parentAnchor = containerElement.querySelector('a');
  }

  // If an anchor element was found, update its href attribute to the new URL.
  if (parentAnchor) {
    this.renderer.setAttribute(parentAnchor, 'href', newHref);
  }
}


  /**
   * Removes the nearest anchor (<a>) element within the specified range, keeping its child nodes in place.
   * @param range - The Range object representing the current selection range.
   */
  unlink(range: Range): void {
    const container = range.commonAncestorContainer;
    let parentAnchor: HTMLAnchorElement | null = null;

    if (container.nodeType === Node.TEXT_NODE) {
      const parentElement = container.parentElement as HTMLElement | null;
      if (parentElement) {
        parentAnchor = parentElement.closest('a');
      }
    } else if (container.nodeType === Node.ELEMENT_NODE) {
      const containerElement = container as HTMLElement;
      parentAnchor = containerElement.querySelector('a');
    }

    if (parentAnchor && parentAnchor.parentNode) {
      const parent = parentAnchor.parentNode;
      while (parentAnchor.firstChild) {
        parent.insertBefore(parentAnchor.firstChild, parentAnchor);
      }
      parent.removeChild(parentAnchor);
    }
  }

  /**
   * Retrieves the href attribute of the nearest anchor (<a>) element within the current selection.
   * @param selection - The current text selection.
   * @returns The href attribute of the nearest anchor element, or an empty string if no anchor is found.
   */
  getHref(selection: Selection): string {
    let hrefValue = '';
    const range = selection.getRangeAt(0);
    const container = range.commonAncestorContainer;

    if (container.nodeType === Node.TEXT_NODE) {
      const parentElement = (container.parentElement as HTMLElement | null);
      if (parentElement) {
        const parentAnchor = parentElement.closest('a');
        if (parentAnchor) {
          hrefValue = parentAnchor.href;
        }
      }
    } else if (container.nodeType === Node.ELEMENT_NODE) {
      const containerElement = container as HTMLElement;
      const anchorTag = containerElement.querySelector('a');
      if (anchorTag) {
        hrefValue = anchorTag.href;
      }
    }

    return hrefValue;
  }

 emit() {
  const htmlContent = this.editor.nativeElement.innerHTML;
  this.onChange(htmlContent);
  this.contentChange.emit({
    [this.formControlName]: this.form.get(this.formControlName)
  });
}

  /**
   * This method is triggered when the input value changes.
   * It is part of the ControlValueAccessor interface to handle input changes.
   */
  // Update onInput method
  onInput(): void {
    this.emit();
    this.onTouched();
  }

}