import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MailComposeWindowComponent } from 'libs/shared/src/lib/components/mail-compose-window/mail-compose-window.component';

@Injectable({
  providedIn: 'root'
})
export class MailComposerService {
  constructor(public dialog: MatDialog) {}
  openMailComposer(data?:any) {
    const dialogRef = this.dialog.open(MailComposeWindowComponent ,{
      position: {
        bottom: '0',
        right: '0'
      },
      panelClass: 'my-custom-dialog',
      disableClose:true,
      data: data??''
    })
    return dialogRef.afterClosed();
  }
}
