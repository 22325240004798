import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CategoriesService } from '../../services/categories.service';
import { AuthService } from 'src/app/auth/service/auth.service';
import { CategoriesDialogMessages, ConfirmationMsg, CreateResponse, GetOneCategoryResponse, GetSubCategory, SelectOptions } from 'src/app/zen-mail/Categories/models/categories.model';
import { CommonDialogService } from 'src/app/shared/service/common-dialog.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { CommonSnackbarService } from 'src/app/shared/service/common-snackbar.service';
import { MatDialog } from '@angular/material/dialog';
import { Heading } from 'src/app/shared/constants/common-header.constants';
import { DynamicDataSource, dynamicTitle } from 'src/app/shared/models/common-card-data.model';
import { CATEGORIES_MESSAGES } from 'src/app/zen-mail/Categories/constant/categories.contants';
import { SharedModule } from 'src/app/shared/shared.module';
import { CommonModule } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
@Component({
  selector: 'app-add-edit-categories',
  templateUrl: './add-edit-categories.component.html',
  standalone:true,
  imports:[SharedModule,CommonModule],
  styleUrls: ['./add-edit-categories.component.scss']
})
export class AddEditCategoriesComponent implements OnInit, OnDestroy {
  /**
 * Reference to the template for the create or edit dialog.
 * @type {TemplateRef<any>}
 */
  @ViewChild('dialogDisplay', { static: false }) dialogDisplay?: TemplateRef<any>;
  /**
  * Variable loading is used to store the loader value.
  * @type {string}
  */
  loading: boolean = true;
  /**
  * Declaring form group 
  */
  categoriesForm!: FormGroup
  /**
  * Declaring form group 
  */
  subCategoriesForm!: FormGroup
  /**
  * Variable heading is used to store the page headings.
  * @type {string}
  */
  headings: string = '';
  /**
  * Variable pathData is used to store the route path datas.
  * @type {boolean}
  */
  pathData!: string;
  /**
   * Variable paramsId is used to store the page params id.
   * @type {number}
   */
  paramsId!: string;
  /**
  * Variable used to store the observables.
  * @type {Subscription}
  */
  SubscriptionObject: Subscription = new Subscription();
  /**
    * Variable isEdit is used to store the page is edit mode or not.
    * @type {boolean}
    */
  isEdit: boolean = false
  /**
    * Variable isVie is used to store the page is view mode or not.
    * @type {boolean}
    */
  isView: boolean = false
  /**
 * Variable applicationDetails is used to store application details.
 * @type {SelectOptions}
 */
  applicationDetails: SelectOptions[] = [];
  /**
  * @type {CategoriesDialogMessages}
  * Assigning the constant categoriesMessages to the Categories Messages variable.
  */
  categoriesMessages: CategoriesDialogMessages = CATEGORIES_MESSAGES;
   /**
    * Variable getOneResponse is used to store Particular category details.
    * @type {GetOneCategoryResponse}
    */
  getOneResponse!: GetOneCategoryResponse;
   /**
    * Variable isImg is used to store wheather the no data avaliable img is shown or not.
    * @type {boolean}
    */
  isImg: boolean = false;
   /**
    * Variable editIndex is used to store sub category edit index values.
    * @type {number}
    */
  editIndex: number | null = null;
   /**
    * Variable isSubEdit is used to store the message based subcategory is created or updated.
    * @type {boolean}
    */
  isSubEdit: boolean = false;
   /**
    * Variable isSubCategoryUpdate is used to store whether the sub categoru=y is created or not while save the category form.
    * @type {boolean}
    */
  isSubCategoryUpdate: boolean = false;
   /**
    * Variable isSubCategoryValue is used to store subcategory is saved or upadted or not.
    * @type {boolean}
    */
  isSubCategoryValue: boolean = false;
    /**
     * Variable has header data to be displayed on commen header.
     * @type {Heading}
     */
    heading:Heading = {
      title : '',
    }

  /**
   * Type of action (create, view, edit).
   * @type {string}
   */
  action!: string;

  /**
   * Holds all available title according to page functionality.
   */
  dynamicTitle : dynamicTitle = {
    edit :{title : "Edit Category" , description : 'Efficiently manage and update category details to streamline organization and improve navigation.'},
    create : {title : "Create Category" , description : 'Efficiently manage and update category details to streamline organization and improve navigation.'},
    view : {title : "View Category"}
  }
    
  /**
  * component constructor which is used to inject the required services.
  * @param route To refer to the navigate page.
  * @param categoriesService To refer to the categoriesService to access the mail Template functions.
  * @param authService To refer to the AuthService to access the region subscription.
  * @param dialogService to get the dialog information.
  * @param paramsRouter To refer to the params of the current path.
  * @param commonService To used to encryt the id.
  */
  constructor(private route: Router,
    private categoriesService: CategoriesService,
    private authService: AuthService,
    private dialogService: CommonDialogService,
    private paramsRouter: ActivatedRoute,
    private commonService: CommonService,
    private snackbar: CommonSnackbarService,
    private dialog: MatDialog
  ) {
  }
  /**
  * Angular life cycle hook that initiates the component
  */
  ngOnInit(): void {
    this.getParamData();
    this.authService.region$.subscribe(res => {
      if (res) {
        this.loading = true;
        this.paramsRouter.params.subscribe((params) => {
          if (params['data']) {
            this.action = params['data'];
            this.isEdit = params['data'] === 'edit' ? true : false;
            this.isView = params['data'] === 'view' ? true : false;
            this.SubscriptionObject = this.categoriesService.getOneCategories(this.paramsId).subscribe({
              next: (getOneResponse: GetOneCategoryResponse) => {
                if (getOneResponse) {
                  this.getOneResponse = getOneResponse;
                  this.isImg = this.getOneResponse.category.subCategory.length === 0 ? true : false;
                  this.getAllApplication();
                  this.formInitialization();
                  if (this.isEdit === true) {
                    this.heading.title = 'Personalize Category';
                  }
                  if (this.isView === true) {
                    this.heading.title = 'Visual Category';
                  }
                }
              }
            })
          }
          else {
            this.action = params['action'];
            this.heading.title = 'Create Category';
            this.getAllApplication();
            this.isImg = true;
          }
        })
        this.formInitialization();
      }
    })
  }
  /**
  * Method which is used to encrypt the id.
  */
  getParamData() {
    this.paramsRouter.paramMap.subscribe(params => {
      this.pathData = '' + params.get('data');
      this.paramsId = '' + this.commonService.getParam('' + params.get('id'));
    });
  }
  /**
 * method which is used to fetch data from Applications
 */
  getAllApplication() {
    this.SubscriptionObject = this.categoriesService.getApplication().subscribe({
      next: (applicationResponse) => {
        if (applicationResponse) {
          this.applicationDetails = applicationResponse?.applicationData?.rows.map((application) => ({
            id: application.id,
            name: application.applicationName
          }));
        }
      }, error: (err) => {
        this.snackbar.OpenSnackBar({
          message: this.categoriesMessages?.wrongInApplication,
          heading: 'Failed',
          actionType: 'failure',
          duration:2
        });
        this.loading = false;
        this.route.navigate(['app/mail/categories'])
      }
    })
  }
  /**
  * Method which is used to initialize the category form.
   */
  formInitialization() {
    this.categoriesForm = new FormGroup({
      applicationId: new FormControl(this.getOneResponse?.category?.applicationId ?? null, [Validators.required]),
      categoryName: new FormControl(this.getOneResponse?.category?.categoryName ?? null, [Validators.required]),
      categoryCode: new FormControl(this.getOneResponse?.category?.categoryCode ?? null, [Validators.required]),
      subCategory: new FormArray([])
    });

    if (!this.getOneResponse) {
      this.categoriesForm.controls['categoryName'].valueChanges.subscribe(value => {
        this.categoriesForm.controls['categoryCode'].setValue(this.generateCategoryCode(value));
      });
    }

    if (this.getOneResponse?.category?.subCategory) {
      this.isImg = this.getOneResponse?.category?.subCategory.length === 0;
      this.getOneResponse?.category?.subCategory?.forEach(subCategoryArray => {
        this.getSubCategoryControls(subCategoryArray);
      });
    }
    setTimeout(() => {
      this.loading=false
    }, 500);
  }
  /**
 * Method which is used to generate the category and sub category code and change to upperCase.
  */
  generateCategoryCode(categoryName: string): string {
    if (!categoryName) {
      return '';
    }
      return categoryName.toUpperCase().replace(/\s+/g, '_');
  }
  
  /**
  * Method which is used to get sub category array.
  */
  getSubCategoryControls(subCategory?: GetSubCategory) {
    const subCategoryFormArray = this.categoriesForm.get('subCategory') as FormArray;
    const subCategoryGroup = new FormGroup({
      id: new FormControl(subCategory?.id ?? null),
      subCategoryName: new FormControl(subCategory?.subCategoryName ?? null, [Validators.required]),
      subCategoryCode: new FormControl(subCategory?.subCategoryCode ?? null, [Validators.required])
    });
    subCategoryFormArray.push(subCategoryGroup);
  }
  /**
  * Method which is used return the array of sub categories.
  */
  getSubCategory() {
    return (this.categoriesForm.get('subCategory') as FormArray).controls;
  }
  /**
  * Method which is used to create sub category controls
  */
  createSubCategory(subCategory?: GetSubCategory) {
    this.isSubCategoryUpdate = true;
    this.subCategoriesForm = new FormGroup({
      id: new FormControl(subCategory ? subCategory.id : null),
      subCategoryName: new FormControl(subCategory ? subCategory.subCategoryName : null, [Validators.required]),
      subCategoryCode: new FormControl(subCategory ? subCategory.subCategoryCode : null, [Validators.required])
    });
    if (!subCategory) {
      this.subCategoriesForm.controls['subCategoryName'].valueChanges.subscribe(value => {
        this.subCategoriesForm.controls['subCategoryCode'].setValue(this.generateCategoryCode(value))
      })
    }
  }
  /**
  * Method which is used to add sub Categories
  */
  onAddSubCategoryDialog() {
    this.createSubCategory();
    if (this.dialogDisplay) {
      this.dialog && this.dialog.open(this.dialogDisplay, { disableClose: true, width: '400px' });
    }
  }
  /**
   * Method which is used to edit sub Categories
   */
  onEditSubCategory(index: number) {
    this.isSubEdit = true;
    const subCategoryFormArray = this.categoriesForm.get('subCategory') as FormArray;
    const subCategory = subCategoryFormArray.at(index).value;
    this.createSubCategory(subCategory);
    this.editIndex = index;
    if (this.dialogDisplay) {
      this.dialog && this.dialog.open(this.dialogDisplay, { disableClose: true, width: '550px' });
    }
  }
  /**
 * Method which is used to remove sub Categories
 */
  onRemoveSubCategory(index: number) {
    this.isSubCategoryUpdate = true;
    const msgType = {
      header: 'Confirmation',
      message: this.categoriesMessages?.removeSubCategory,
      actionType: 'confirmation',
      button: { right: 'Yes, Remove', left: 'No' },
    };
    this.dialogMsg(msgType).subscribe((dialogResult: boolean) => {
      if (dialogResult) {
        const msgType = {
          header: 'success',
          message: this.categoriesMessages?.deleteSubCategorySuccess,
          actionType: 'success',
          button: { right: 'Okay' },
        };
        this.dialogMsg(msgType);
        const subCategoryFormArray = this.categoriesForm.get('subCategory') as FormArray;
        if (index >= 0 && index < subCategoryFormArray.length) {
          subCategoryFormArray.removeAt(index);
          this.isImg = subCategoryFormArray.length === 0 ? true : false;
        }
      }
    });
  }
  /**
* Method which is used to save and updat the sub Categories
*/
  onSaveSubCategory() {
    if (this.subCategoriesForm.valid) {
      this.isSubCategoryValue=true;
      const subCategoryFormArray = this.categoriesForm.get('subCategory') as FormArray;
      const index = this.editIndex;
      const newSubCategoryFormGroup = new FormGroup({
        id: new FormControl(this.subCategoriesForm.value.id),
        subCategoryName: new FormControl(this.subCategoriesForm.value.subCategoryName, [Validators.required]),
        subCategoryCode: new FormControl(this.subCategoriesForm.value.subCategoryCode, [Validators.required])
      });
      this.dialogReset();
      const msgType = {
        header: '',
        message: this.isSubEdit ? this.categoriesMessages?.updateSubCategorySuccess : this.categoriesMessages?.createSubCategorySuccess,
        actionType: 'success',
        button: { right: 'Okay' }
      };
      this.dialogMsg(msgType).subscribe((dialogResult: boolean) => {
        if (dialogResult) {
          this.isImg = false;
          if (index !== undefined && index !== null) {
            subCategoryFormArray.setControl(index, newSubCategoryFormGroup);
            this.editIndex = null;
          } else {
            subCategoryFormArray.push(newSubCategoryFormGroup);
          }
        }
      })
    } else {
        this.snackbar.OpenSnackBar({
        message: this.categoriesMessages?.mandatoryMsg,
        heading: 'Failed',
        actionType: 'failure',
        duration:2
      });
    }
  }
  /**
   * Method to close the dialog box and resetting the form in the dialogbox
   * @returns { void }
   */
  dialogClose(): void {
    if(this.subCategoriesForm.dirty){
    const msgType = {
      header: 'Confirmation',
      message: this.categoriesMessages?.unSavedMsg,
      actionType: 'confirmation',
      button: { right: 'Yes, Leave', left: 'Stay' },
    };
    this.dialogMsg(msgType).subscribe((dialogResult: boolean) => {
      if(dialogResult){
        this.dialogReset();
      }
    })
  }
  else{
    this.dialogReset();
  }
  }
  /**
* Method which is used to reset the sub category form.
*/
  dialogReset() {
    this.dialog && this.dialog.closeAll();
    this.subCategoriesForm && this.subCategoriesForm.reset();
  }
  /**
   * Method which is used to handle navigation when the user wants to go back
   */
  onBack() {
    if(this.isSubCategoryValue){
      this.categoriesForm.markAsPristine();
      const msgType = {
        header: 'Confirmation',
        message: this.categoriesMessages?.unSavedMsg,
        actionType: 'confirmation',
        button: { right: 'Yes, Leave', left: 'Stay' },
      };
      this.dialogMsg(msgType).subscribe((dialogResult: boolean) => {
        if(dialogResult){
          this.route.navigate(['app/mail/categories'])
        }
      })
    } else{
      this.route.navigate(['app/mail/categories'])
    }
  }
  /**
  * Method which is used to save and update the form
  */
  onSave() {
    if (this.categoriesForm.valid) {
      const formDatas = {
        applicationId: this.categoriesForm.value.applicationId,
        categoryCode: this.categoriesForm.value.categoryCode,
        categoryName: this.categoriesForm.value.categoryName,
        subCategory: this.categoriesForm.value.subCategory ?? []
      }
      if (!this.isEdit) {
        this.SubscriptionObject = this.categoriesService.createCategories(formDatas).subscribe({
          next: (createResponse: CreateResponse) => {
            if (createResponse) {
              this.snackbar.OpenSnackBar({
                message: createResponse?.success ? this.categoriesMessages?.createCategorySuccess : this.categoriesMessages?.createCategoryFailed,
                heading: createResponse?.success ? 'Success' : 'Failed',
                actionType: createResponse?.success ? 'success' : 'failure'
              });
              this.categoriesForm.markAsPristine();
              this.loading = false;
              if(createResponse?.success){
              this.route.navigate(['app/mail/categories'])
              }
            }
          }, error: (err) => {
              this.snackbar.OpenSnackBar({
              message: this.categoriesMessages?.wrongInCategory,
              heading: 'Failed',
              actionType: 'failure',
              duration:2
            });
            this.loading = false;
          }
        })
      }
      else if (this.isEdit && (this.categoriesForm.dirty || this.isSubCategoryUpdate)) {
        this.SubscriptionObject = this.categoriesService.updateCategories(this.paramsId, formDatas).subscribe({
          next: (updatedResponse: HttpResponse<any>) => {
            if (updatedResponse) {
              this.snackbar.OpenSnackBar({
                message:updatedResponse?.status===204?this.categoriesMessages?.updateCategorySuccess:updatedResponse?.status===200?this.categoriesMessages?.updateCategoryFailed:this.categoriesMessages?.wrongInUpadteCategory,
                heading:updatedResponse?.status===204?'Success':'Failed',
                actionType: updatedResponse?.status===204?'success':'failure'
              });
              this.categoriesForm.markAsPristine();
              this.loading = false;
              if(updatedResponse?.status===204){
              this.route.navigate(['app/mail/categories'])
              }
            }
          }, error: (err) => {
            this.snackbar.OpenSnackBar({
              message: this.categoriesMessages?.wrongInUpadteCategory,
              heading: 'Failed',
              actionType: 'failure',
              duration:2
            });
            this.loading = false;
          }
        })
      }
      else {
        this.snackbar.OpenSnackBar({
          message: this.categoriesMessages?.noChanges,
          heading: 'Warning',
          actionType: 'warning',
          duration:2
        });
        this.loading = false;
      }
    } else {
      this.snackbar.OpenSnackBar({
        message: this.categoriesMessages?.mandatoryMsg,
        heading: 'Failed',
        actionType: 'failure',
        duration:2
      });
    }
  }
  /**
  * Method which is used to show dialogs based on Action.
  */
  dialogMsg(msgType: ConfirmationMsg) {
    return this.dialogService.openDialog({
      header: msgType.header ?? '',
      message: msgType.message,
      actionType: msgType.actionType,
      button: { right: msgType.button?.right ?? '', left: msgType.button?.left ?? '' },
      disableClose: true
    });
  }
  /**
  * Angular life cycle hook ngOnDestroy is used to unsubscribe the subscribtion.
  * @type {void}
  */
  ngOnDestroy(): void {
    if (this.SubscriptionObject) {
      this.SubscriptionObject.unsubscribe();
    }
  }
  headerEvent(evnt : any){
    (this as DynamicDataSource)[evnt]();
  }
  /**
  * Method used to deactivate the canDeactivate guard when the form is dirty.
  * @returns { boolean } dialog box
  */
  canDeactivate(): boolean {
    return this.categoriesForm ? this.categoriesForm.pristine : true;
  }
}
