<div style="padding: 0px 0px 25px 20px" *ngIf="!initialLoader">
  <app-common-mails-list
  [columns]="columns"
  [dataSource]="inboxData"
  (iconsClicked)="onStarredChanged($event)"
  [hasPagination]="true"
  [paginationData]="paginationData"
  (paginationChange)="handlePaginationChange($event)"
  [hasSearchField]="true"
  [searchSetting]="searchSettingData"
  (searchChange)="handleSearchChange($event)"
  (actionClicked)="handleActionClicked($event)"
  [sortSetting]="sorting"
  (sortChange)="handleSortingChange($event)"
  (compose)="callMailComposer()"
  [loading]="loader">
</app-common-mails-list>

</div>
<div *ngIf="initialLoader">
  <app-skeleton-loader [isMail]="true"></app-skeleton-loader>
</div>