import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpRoutingService } from 'src/app/common/service/http-routing.service';
import { httpParams } from 'src/app/zen-mail/Reports/models/reports.model';
import { createLanguage, deleteLanguage, getLanguageListResponse, languageDelete, languageUpdate } from '../models/language.model';

@Injectable({
  providedIn: 'root'
})
export class LanguageListService {
  /**
   * component constructor which is used to inject the required services and modules.
   * @param httpClient To refer to the httpClient module to access the get and put methods.
   */
  constructor(private httpClient: HttpRoutingService) { }
  /**
   * Method which is used to get application list data from backend.
   * @param paramData It has the query params of the Url.
   * @type { Observable<httpParams>}
   */
  getLanguagesList(paramData: httpParams) : Observable<getLanguageListResponse> {
    return this.httpClient.getRequest('microservice/mail/languages?', paramData);  
  }

  /**
   * Method which is used to update language data to the backend.
   * @param languageData It has data that jhas to be updated.
   * @type { Observable<languageUpdate>}
   */
  updateLanguage(languageData : languageUpdate) : Observable<getLanguageListResponse> {
    return this.httpClient.putRequest(`microservice/mail/languages/${languageData.id}`, languageData , {});  
  }

  /**
   * Method which is used to update language data to the backend.
   * @param languageData It has data that jhas to be updated.
   * @type { Observable<createLanguage>}
   */
  createLanguage(languageData : createLanguage) : Observable<getLanguageListResponse> {
    return this.httpClient.postRequest(`microservice/mail/languages/create`, languageData );  
  }

  /**
   * Method which is used to get application list data from backend.
   * @param applicationId It has the application id to be deleted.
   * @type { Observable<deleteLanguage>}
   */
  deleteLanguages(languageData: deleteLanguage ) : Observable<languageDelete> {
    return this.httpClient.putRequest( `microservice/mail/languages/${languageData.id}`, languageData , {});
  }
}
