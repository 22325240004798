<!-- <div *ngIf="data.actionType!=='logout'"> --><!-- <div class="dialog-box-card" [ngStyle]="{'border-top':data?.actionType==='success'?'8px solid #35ad6f':data?.actionType==='failure'?'8px solid rgb(237 28 36)':data?.actionType==='verification'?'8px solid #00B5E2':'8px solid #3498DB','border-bottom':data?.actionType==='success'?'4px solid #35ad6f':data?.actionType==='failure'?'4px solid rgb(237 28 36)':data?.actionType==='verification'?'4px solid #00B5E2':'4px solid #3498DB'}">
  <div *ngIf="data?.image" fxLayoutAlign="center center" class="image-container">
      <div fxLayoutAlign="center center" [ngStyle]="{'background': data?.circle2,'border':data?.actionType==='success'?'8px solid #35ad6f':data?.actionType==='failure'?'8px solid rgb(237 28 36)':data?.actionType==='verification'?'8px solid #00B5E2':'8px solid #3498DB'}" class="circle2">
        <div [ngStyle]="{'position':'relative','top':'3%'}">          
          <img [src]= data?.image
          alt="no records found" data-control="dialog-image"
          onerror="this.src='./assets/image-not-found.jpg'"/>
        </div>
      </div>
  </div>
  <div [ngStyle]="{'padding-top':data.actionType==='verification'?'35px':'8px'}">  
    <div *ngIf="data?.header" fxLayoutAlign="center center" data-control="dialog-header" class="dialog-box-header" [ngStyle]="{'color': data?.color}" fxLayout="column">
    <p class="header">{{data?.header}}</p>
    <div [ngStyle]="{'width':'15%'}">
    <hr [ngStyle]="{'border':data?.actionType==='success'?'0.5px solid #18991899':data?.actionType==='failure'?
    '0.5px solid rgb(237 28 36)':'0.5px solid #3498DB'}">
  </div>
  </div>
</div>

  <div *ngIf="data?.message" fxLayoutAlign="center center" data-control="dialog-message" class="dialog-box-message">{{data?.message}}</div>
  <div *ngIf="data?.actionType && data?.actionType !== 'verification' && data?.actionType !== 'confirmation' ; else confirmation"
  mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" class="dialog-box-button">
    <button *ngIf="data?.button?.left" data-control="dialog-left-button" mat-button class="left-button"   mat-dialog-close="true">{{data?.button.left}}</button>
    <button *ngIf="data?.button?.right" data-control="dialog-right-button" class="right-button" mat-raised-button [ngStyle]="{'background': data?.color}"  mat-dialog-close="true">{{data?.button.right}}</button>
  </div>
</div>

<ng-template #confirmation>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center"  class="confirmation-button">
      <div  fxLayoutAlign="center center">
        <mat-form-field  appearance="outline" *ngIf="data?.actionType && data?.actionType =='verification'" >
          <mat-label>Password</mat-label>
          <input matInput [formControl]="passwordField">
        </mat-form-field>
      </div>
      <div fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="3%" fxFlex="100%">
        <button fxFlex="40%" *ngIf="data?.button?.left" mat-button matDialogClose class="left-button">{{data?.button.left}}</button>
        <button fxFlex="40%" *ngIf="data?.button?.right" mat-raised-button [ngClass]=" (passwordField.value == '' && data?.actionType =='verification') ? 'button-disabled' : 'button-yes' " [mat-dialog-close]="passwordField.value || true" class="right-button" [ngStyle]="{'background': data?.color}">{{data?.button.right}}</button>
      </div>
  </div>
</ng-template> -->
<!-- </div> -->
<div>
  <div fxLayout="column" fxLayoutGap="10%" [ngStyle]="{'padding':'20px'}">
    <div fxLayout="row" fxLayoutAlign="center center">
      <div class="icon-style" [ngStyle]="{'background':lightenColor(data.color, 95)}" fxLayout="row" fxLayoutAlign="center center">
        <img [src]="data.image" height="45px" width="45px">
      </div>
    </div>
    <div fxLayout="column">
      <h1 *ngIf="data?.header" fxLayoutAlign="center center" data-control="dialog-header"
        [ngStyle]="{'font-size':'26px','font-weight':'500'}">{{data?.header}}</h1>
      <div *ngIf="data?.message" fxLayoutAlign="center center" data-control="dialog-message"
        [ngStyle]="{'font-size':'17px','color':'gray','text-align':'center'}">{{data?.message}}
      </div>
    </div>
    <div fxLayout="column" [fxLayoutGap]="data?.actionType==='verification'?'-4%':'3%'" fxLayoutAlign="center center">
    <div *ngIf="data?.actionType==='verification'" [ngStyle]="{'width':'100%'}">
      <mat-form-field  appearance="outline" [ngStyle]="{'width':'100%'}" class="custom-field">
        <mat-label>Password</mat-label>
        <div fxLayout="row" fxLayoutGap="3%">
        <input matInput [formControl]="passwordField" [type]="showPassword?.showCurrentPassword ? 'text' : 'password'">
        <img [src]="showPassword?.showCurrentPassword ? '../../../../../../assets/visibility (1).png' : '../../../../../../assets/visible.png'" height="27px" width="27px" *ngIf="passwordField.value" (click)="showPasswordType('showCurrentPassword')"/>
      </div>
      </mat-form-field>
    </div>
    <div fxLayout="column" fxLayoutGap="3%" fxLayoutAlign="center center" [ngStyle]="{'width':'100%'}">  
      <button *ngIf="data?.button?.right" data-control="dialog-right-button"
        class="right-button-container" [ngStyle]="{'background':data.color}"
        mat-dialog-close="true" [ngStyle]="{'background':(passwordField.value == '' && data?.actionType =='verification') ?lightenColor(data.color, 75):data.color,'pointer-events':(passwordField.value == '' && data?.actionType =='verification') ?'none':''}" >{{data?.button.right}}</button>
      <button *ngIf="data?.button?.left" data-control="dialog-left-button"
        class="left-button-container" matDialogClose>{{data?.button.left}}</button>
    </div>
    </div>
  </div>
</div>
