<div style="padding: 0px 0px 25px 20px" *ngIf="!initialLoader">
  <app-common-mails-list
  [columns]="columns"
  [dataSource]="inboxData"
  (iconsClicked)="onStarredChanged($event)"
  (refreshedClicked)="onRefreshedClicked($event)"
  [hasPagination]="true"
  [paginationData]="paginationData"
  (paginationChange)="handlePaginationChange($event)"
  [hasSearchField]="true"
  [searchSetting]="searchSettingData"
  (searchChange)="handleSearchChange($event)"
  (actionClicked)="handleActionClicked($event)"
  [sortSetting]="sorting"
  (sortChange)="handleSortingChange($event)"
  (compose)="callMailComposer()"
  [loading]="loader">
</app-common-mails-list>

</div>
<div *ngIf="initialLoader">
  <app-skeleton-loader [isMail]="true"></app-skeleton-loader>
</div>

<!-- <div style="padding: 2%; line-height: 24px; letter-spacing: 0.4px; background-color: white; font-family: 'Roboto',sans-serif;display: flex;flex-direction: column;align-items: center;place-content: center;">
  <div style="border-radius: 4px;height: 100%;padding: 2%;width: 70%;background-color: #f3f7fa;">
    <div style="text-align: center; width: 60px;height: 60px; margin: auto;border: 3px solid #362bd5;border-radius: 50px;">
      <img src="assets/fav.png" height="40px" width="40px" style="height: 40px;width: 40px;padding-top: 10px;">
    </div>
    <div style="text-align: center;padding: 3% 1% 1% 1%;">
      <h2 style="color:#362bd5;font-weight: 500;letter-spacing:0.01em;font-size: 22px;">Verify Your ZenMail Account</h2>
    </div>
    <div style="background-color: white;border-radius: 4px;height: 100%;padding: 3%;">
    <p style="text-align: justify; font-size: 15px; color: black;padding: 1%;"><span style="font-weight: 600;"> Hi ${firstName},</span></p>
    <div style="text-align: justify;padding: 0% 1% 1% 1%">
      <span style="color:#4f4f4f;font-weight: 500;letter-spacing:0.01em;font-size: 14px;"><span style="margin-left: 60px;"></span>I’m Ram, the admin of Zen Mail, and I’m excited to welcome you to our platform! We’re thrilled to have you join the Zen Mail family.</span>
    </div>  
    <div style="text-align: justify;padding: 2% 1% 1% 1%;">
      <span style="color:#4f4f4f;font-weight: 500;letter-spacing:0.01em;font-size: 14px;">To get started and unlock the full Zen Mail experience, please verify your account by clicking the button below:</span>
    </div>
    <div style="text-align: center;padding: 2% 1% 4% 1%;">
      <a style="text-decoration: none; text-align: center;" href="${verifyLink}">
        <button style="width: auto; padding: 14px 24px; border-radius: 5px; background-color: #362bd5; color: white; border: none !important;font-weight: 600;font-size: 13px;cursor: pointer;">
         Verify My Account
        </button>
      </a>
    </div>
    <hr style="width: 10%;">
    <div style="text-align: justify;padding:2% 1% 1% 1%;">
      <span style="color:#535353;font-weight: 500;letter-spacing:0.01em;font-size: 14px;">If you have any questions or need assistance, feel free to reach out to our support team at<span style="color: #362bd5;cursor: pointer;"> support@zenmail.com</span>.</span>
    </div>
    <div style="text-align: justify;padding:1%;">
      <span style="font-weight: 600;letter-spacing:0.01em;font-size: 13px;">Thank you for joining us! We’re excited to help you elevate your email experience with Zen Mail.</span>
    </div>
    <div style="padding: 1%;">
        <span style="font-family: 'Roboto',sans-serif; font-size: 13px; font-weight: 600;">Warm regards,</span> 
        <br><span style="font-size: 13px; font-weight: 600; text-align: justify; font-family: 'Roboto',sans-serif;">Ram
        <br>Zen Mail Admin</span>
    </div>
  </div>
  </div>
</div> -->