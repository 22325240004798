import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent {
  /**
   * FormGroup instance for the signup form.
   * @type {FormGroup}
   **/
  signUp!: FormGroup;
  constructor() {}
  /**
   * Angular life cycle hooks
   */
  ngOnInit() {
    this.signUp = new FormGroup({
      firstName: new FormControl(null, Validators.required),
      lastName: new FormControl(null, Validators.required),
      email: new FormControl(null, Validators.required),
      password: new FormControl(null, Validators.required),
    });
  }
  /**
   * Handles the form submission for user signup.
   */
  onSubmit(): void {}
}
