<div *ngIf="!initialLoader" class="common-header">
  <app-common-header (methodClicked)="headerEvent($event)" [buttonInfo]="button" [heading]="heading" ></app-common-header>
</div>
<div [ngStyle]="{'margin':' 20px 20px 0px 20px'}" *ngIf="initialLoader"  class="common-card-style">
  <app-skeleton-loader [formLoader]="false"></app-skeleton-loader>
</div>
<div fxLayout.lt-md="column" *ngIf="!initialLoader"  class="common-card-style">
    <app-common-filter *ngIf="isFilter" [filterData]="filterDetails" [appliedFiltersData]="routeParams" [isFilterStatus]="isFilterValue"
       (filterClose)="closeFilter()" (emittedData)="emittedEvent($event)" (AppliedFilters)="appliedFiltersLength($event)">
    </app-common-filter>
    <div fxFlex="100%">
        <app-common-card [buttonInfo]="button"  [sortSetting]="sorting" (sortChange)="sortData($event)" (methodClicked)="headerEvent($event)"
        (primarySelectEmittedData)="primaryDropdownEvent($event)" [dataNotFoundMessage]="noDataMessage" [primaryFilterData]="primaryFilterOptions" [hasPrimaryFilter]="!!(primaryFilterOptions?.options?.length)"
        [columns]="columns" [dataSource]="data" [actionSetting]="actions" [hasSearchField]="true"
        (actionClicked)="onActionClicked($event)" [appliedFilters]="appliedFilters"
        [searchSetting]="searchSettingData" [hasPagination]="true" (paginationChange)="handlePaginationChange($event)" 
        [paginationData]="paginationData"  [optionalEvent]="optionalEvent" [loading]="customersListLoader"
        (searchChange)="handleSearchChange($event)" (optionalEventOccurred)="handleOptionalEvent($event)"></app-common-card>
    </div>
  </div>
  <!-- <div>Standalone works</div> -->