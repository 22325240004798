<mat-nav-list class="sidenav-lists">
  <!-- Header container -->
  <div class="sidenav-header-container">
    <div class="sidenav-heading-container">
      <div class="sidenav-header">
        {{menus?.heading}}
      </div>
      <div class="url-styles" fxLayoutAlign=" center">
        <span>getzenbasket.com</span> <a href="https://getzenbasket.com/" class="url" target="_blank"><mat-icon class="navigate-icon">open_in_new</mat-icon></a>
      </div>
    </div>
    <div class="share-icon" fxLayoutAlign="center">
      <mat-icon>more_vert</mat-icon>
    </div>
  </div>
  <!-- Menu Lists  -->
  <ng-container *ngFor="let menu of menus?.menu">
    <ng-container *ngIf="menu?.pageCustomTitle">
      <!-- List of Menus -->
        <mat-list-item (click)="onRouterNavigation(menu)" class="mat-list-item" [class.selected]="menu && menu.isSelected">
            <div class="menu-header-div list-data" fxLayoutGap="5px">
              <div fxLayoutGap="5px" fxLayout="row" fxLayoutAlign="start center" [ngClass]="(menu.isSelected?'selected-menu':'menu-name')">
              <mat-icon [ngClass]="(menu.isSelected?'selected-content':'menu-icon')" class="menu-custom-icon" *ngIf="menu?.menuCustomIcon">
                {{menu?.menuCustomIcon}}
              </mat-icon>
                <span>{{menu?.pageCustomTitle | uppercase}}</span></div>
                <div *ngIf="menu.subMenu && menu.subMenu.length > 0 " fxLayoutAlign="center center" class="submenu-button-container">
                  <mat-icon class="submenu-button material-symbols-outlined"
                    [ngClass]="{'rotated' : showSubmenu && (menuId === menu?.id)}">{{showSubmenu && (menuId === menu?.id) ? 'minimize':'add'}}</mat-icon>
                </div>
            </div>
        </mat-list-item>

        <!-- List of Submenus -->
        <ng-container *ngIf="showSubmenu  && (menuId === menu?.id) ">
            <ng-container *ngFor="let submenu of menu?.subMenu">
                <mat-list-item (click)="onSubMenuClick(submenu.pageCustomLink, submenu)" [class.selected]="submenu.isSelected">
                    <div class="menu-header-div list-data menuList" >
                        <mat-icon [class.selected-content]="submenu.isSelected" class="menu-icon material-symbols-outlined">
                            {{submenu?.pageCustomIcon}}
                        </mat-icon> &nbsp;&nbsp;
                        <span class="submenu-name" [class.selected-content]="submenu.isSelected" >{{submenu?.pageCustomTitle | titlecase }}</span>
                    </div>
                </mat-list-item>
            </ng-container>
        </ng-container>

        <div class="divider"></div>
    </ng-container>
  </ng-container>

  <!-- Region -->
  <ng-container *ngIf="menus?.isRegion">
    <div class="region" fxLayoutAlign="end">
      <mat-form-field appearance="fill" class="region-data">
        <mat-select [(value)]="selectedOption[0]" (selectionChange)="onSelectionChange($event)">
          <mat-select-trigger >
            <div *ngIf="selectedOption[0]" fxLayoutAlign=" center" fxLayoutGap="10px" fxLayout="row" class="selected-region">
              <img [src]="selectedOption[0].countryImage" class="region-country-image">
              <span [ngStyle]="{'font-size':'12px','font-weight':'500','color':'var(--primary-color)'}" class="text-wrap">{{selectedOption[0].sortValue}}</span>
            </div>
          </mat-select-trigger>
          <mat-option *ngFor="let option of options" [value]="option">
            <div fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="10px">
              <img [src]="option?.countryImage" alt="" class="region-country-image">
              <span [ngStyle]="{'font-size':'12px','font-weight':'500'}" class="text-wrap">{{option?.sortValue}}</span>
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </ng-container>

</mat-nav-list>
