import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { dataConfig } from '../constants/common-constants';
import { dataConfiguration } from 'libs/shared/src/lib/service/config';
import { BehaviorSubject, Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { dynamicRouteParams } from '../models/common-card-data.model';
import { SideNavConfig } from '../models/menu-bar.model';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  /**
   * Variable used to flag the filter is clicked or not
   * @type { BehaviorSubject<boolean> }
   */
  isFilterClicked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  /**
   * Variable used to flag the common-card component initailly loaded or not.
   * @type { BehaviorSubject<boolean> }
   */
  cardInitialLoad: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  /**
   * BehaviorSubject that holds the current menu data.
   * @type { BehaviorSubject<SideNavConfig> }
   */
  menus: BehaviorSubject<SideNavConfig> = new BehaviorSubject<any>({});

  /**
   * Variable to manage and track submenu visibility.
   * @type { BehaviorSubject<boolean> }
   */
  private showSubmenuSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * Variable to manage and track menu visibility.
   * @type { BehaviorSubject<number> }
   */
  private menuIdSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  /**
   * Variable to manage and track previous selected menu id.
   * @type { BehaviorSubject<number> }
   */
  private previousMenuIdSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  /**
   * Observable for submenu visibility state.
   * @type { Observable<boolean> }
   */
  showSubmenu$: Observable<boolean> = this.showSubmenuSubject.asObservable();
  
  /**
   * Observable for the current menu ID
   * @type { Observable<number> }
   */
  menuId$: Observable<number> = this.menuIdSubject.asObservable();

  /**
   * Observable for the previous menu ID
   * @type { Observable<number> }
   */
  previousMenuId$: Observable<number> = this.previousMenuIdSubject.asObservable();

  constructor() { }
  /**
   * Method which is used to encrypt the param value.
   * @param data Parameter which is used to hold the data to encrypt.
   */
  setParamsObj(data: string | number) {
    if (data !== null && data !== undefined) {
      return CryptoJS.AES.encrypt(JSON.stringify(data), dataConfig.secretKey).toString().replace(/\+/g, 'p1L2u3S').replace(/\//g, 's1L2a3S4h').replace(/=/g, 'e1Q2u3A4l');
    }
    return ''
  }

  /**
   * Method which is used to decrypt the param value.
   * @param ciphertext Parameter Which is used to hold the data to decrypt.
   */
   getParam(ciphertext : string) {
     if (ciphertext) {
       const bytes = CryptoJS.AES.decrypt(ciphertext.toString().replace(/p1L2u3S/g, '+').replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '='), dataConfig.secretKey);
       return bytes.toString(CryptoJS.enc.Utf8);
     } else {
       return false;
     }
   }
 /**
   * Method which is used to encrypt the content value.
   * @param data content Which is used to hold the data to encrypt.
   */
   encryptAES(data:string) {
    const ciphertext = CryptoJS.AES.encrypt(data.toString(),dataConfiguration.secretKey).toString();
    return ciphertext.replace(/\\/g, '|');
   };
   /**
   * Method which is used to decrypt the content value.
   * @param data content Which is used to hold the data to decrypt.
   */
   decryptAES(data?:string) {
    if (data) {
      const bytes = CryptoJS.AES.decrypt(data.toString(), dataConfiguration.secretKey);
      const result = bytes.toString(CryptoJS.enc.Utf8).replace(/\|/g, '\\');
      return result;
    } else {
      return null;
    }
  }

  /**
   * Sets the visibility state of the submenu.
   * @param { boolean } value 
   */
  setShowSubmenu(value: boolean): void {
    this.showSubmenuSubject.next(value);
  }

  /**
   * Sets the current menu ID.
   * @param { number } id 
   */
  setMenuId(id: number): void {
    this.menuIdSubject.next(id);
  }

  /**
   * Sets the previous menu ID.
   * @param { number } previousMenuId 
   */
  setPreviousMenuId(previousMenuId: number): void {
    this.previousMenuIdSubject.next(previousMenuId);
  }

  /**
   * Processes query parameters from the provided route and returns a specific filter object based on
   * detected parameters.
   * @param { ActivatedRoute } route
   * @returns { dynamicRouteParams | null }
   */
  routeParamsFilter(route: ActivatedRoute): dynamicRouteParams | null {
    let routeParams;
    route.queryParams.subscribe(params => {
      const routeParam = params[Object.keys(params)[0]];
      if (routeParam) {
        if (params['date_range']) {
          routeParams = { customDateRange: JSON.parse(routeParam) };
        } else if (params['active']) {
          routeParams = { isActive: 'true' };
        } else if (params['id']) {
          routeParams = { customerId: params['id']};
        } else if (params['success']) {
          routeParams = { isSuccess: routeParam };
        }
        this.isFilterClicked.next(false);
      }
    });
    return routeParams ? routeParams : null;
  }

  

  /**
   * Updates the menu and submenu selection states based on provided menu and submenu names.
   * @param { string } menuName 
   * @param { string } subMenu 
   */
  menuBarChanges(menuName: string, subMenu: string) {
    this.menus.subscribe(res => {
      res.menu.forEach((menu: any) => {
        if(menu.pageCustomTitle == menuName) {
          menu.isSelected = true;
          this.setMenuId(menu.id)
          this.setPreviousMenuId(menu.id)
        }
        else menu.isSelected = false;

        menu?.subMenu?.forEach((submenu: any) => {
          if(submenu.pageCustomTitle == subMenu) {
            submenu.isSelected = true;
            this.setShowSubmenu(true);
          }
          else submenu.isSelected = false
        })
      })
    })
  }
}


