import { CommonModule, DatePipe } from '@angular/common';
import { Component, TemplateRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/service/auth.service';
import { ColumnType, NO_RECORD_FOUND_MESSAGES } from 'src/app/shared/constants/common-card-data.constants';
import { DIALOG_HEADER, CONFIRM_MESSAGES, BUTTON_TEXT, DIALOG_TYPE, LANGUAGE_MESSAGES } from 'src/app/shared/constants/common-dialog-data.constants';
import { TableAction, SearchSetting, PaginationData, columnDef, ActionClicked, DynamicDataSource, NoRecordFound } from 'src/app/shared/models/common-card-data.model';
import { CommonDialogService } from 'src/app/shared/service/common-dialog.service';
import { LanguageListService } from '../../services/language-list.service';
import { LanguageListParams, deleteLanguageResponseStructure, getLanguageListResponse, languageListData, languagesTableData } from '../../models/language.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ButtonText, DialogHeader, DialogType } from 'src/app/shared/models/common-dialog-data.model';
import { MatDialog } from '@angular/material/dialog';
import { CommonSnackbarService } from 'src/app/shared/service/common-snackbar.service';
import { snackber_message } from '../../constants/language-list.constants';
import { SNACKBAR_HEADING, SNACKBAR_MESSAGES, SNACKBAR_OPTIONS, SNACKBAR_TYPE } from 'src/app/shared/constants/common-snackbar-data.constants';
import { ButtonInfo, Heading } from 'src/app/shared/constants/common-header.constants';
import  ISO6391  from 'iso-639-1';
import { SharedModule } from 'src/app/shared/shared.module';
@Component({
  selector: 'app-language-list',
  templateUrl: './language-list.component.html',
  standalone:true,
  imports: [SharedModule,CommonModule],
  styleUrls: ['./language-list.component.scss']
})
export class LanguageListComponent {

  /**
   * Variable used to store action detail.
   * @type {boolean}
   */
  action!:string;

  // Constants for language messages
  languageMessages = LANGUAGE_MESSAGES;

  /**
   * Variable used to indicate whether to use search field or not.
   * @type {boolean}
   */
  hasSearchField: boolean = true;

  /**
   * Variable used to store the table data.
   * @type {languageListData[]}
   */
  data!: languageListData[];

  /**
   * Variable used to store the searching text.
   * @type {string}
   */
  searchData: string = '';

  /**
   * Variable used to store the observables.
   * @type {Subscription}
   */
  SubscriptionObject: Subscription = new Subscription();

  /**
   * Flag Variable used to indicate whether to enable loader or not.
   * @type {boolean}
   */
  languagesListLoader: boolean = true;

  /**
   * Variable used to store the table actions data.
   * @type {Array<TableAction>}
   */
  actions: Array<TableAction> = [
    { icon: 'edit', tooltip: 'Edit', method: 'onEdit', color: '#00b907' },
    { icon: 'delete', tooltip: 'Delete', method: 'onDelete', color: 'red' }
  ];

  /**
   * Variable used to store search settings data.
   * @type {SearchSetting}
   */
  searchSettingData: SearchSetting = {
    searchText: '',
    placeHolder: 'Search Language',
    appearance: 'outline'
  };

  /**
   * Variable used to store the table Pagination data.
   * @type {PaginationData}
   */
  paginationData: PaginationData = {
    limit: 5,
    count: 0,
    offset: 0
  };

  /**
   * Variable used to store the table column definition.
   * @type {Array<columnDef>}
   */
  columns: Array<columnDef> = [
    { field: '', header: '', type: ColumnType.Checkbox, columnWidth: '10%', columnAlign: 'start' },
    { field: 'language', header: 'Language', type: ColumnType.Text, columnAlign: 'start' },
    { field: 'languageCode', header: 'Language Code', type: ColumnType.Text },
    { field: 'createdDate', header: 'Created On', type: ColumnType.Date },
    { field: 'actions', header: 'Actions', type: ColumnType.Action, columnWidth: '15%', color: 'black' },
  ];

  /**
   * Template reference for quick edit display.
   * @type {TemplateRef}
   */
  @ViewChild('quickEditDisplay', { static: false }) quickEditDisplay?: TemplateRef<any>;

  // Flag to indicate edit mode
  onEditMode: boolean = false;

  /**
   * Form group for editing data.
   * @type { FormGroup }
   */
  languageFormGroup?: FormGroup;

  /**
   * Assigning the constant CONFIRM_MESSAGES to the dialogConfirmMessages variable.
   */
  message = CONFIRM_MESSAGES;

  /**
   * Assigning the constant BUTTON_TEXT to the buttonText variable
   * @type {ButtonText}
   */
  buttonText: ButtonText = BUTTON_TEXT;

  /**
   * Assigning the constant DIALOG_TYPE to the dialogType variable.
   * @type {DialogType}
   */
  dialogType: DialogType = DIALOG_TYPE;

  /**
   * Assigning the constant DIALOG_HEADER to the dialogHeader variable.
   * @type {DialogHeader}
   */
  dialogHeader: DialogHeader = DIALOG_HEADER;

  /**
   * Assigning the constant CONFIRM_MESSAGES to the dialogConfirmMessages variable.
   */
  dialogConfirmMessages = CONFIRM_MESSAGES;

  /**
   * Message to display when no data is available.
   * @type {NoRecordFound}
   */
  noDataMessage: NoRecordFound = {
    noRecordFound: "No records found",
    noRecordFoundDescription: "No records found in the description."
  };

  /**
   * Variable used to store current search data.
   * @type {string}
   */
  currentSearch!: string;

  /**
   * Variable has button data to be displayed on commen header.
   * @type {Heading}
   */
  button:ButtonInfo[] = [{
    class : 'primary-button',
    color : '#ffffff',    
    name : 'Create',
    method : 'onCreate',
    disabled : false,
    iconClass : 'outline',
    iconToolTip : 'btn'
  }]

  /**
   * Variable has header data to be displayed on commen header.
   * @type {Heading}
   */
  heading:Heading = {
    title : "Languages",
    description : 'Manage languages with options to create, edit, and delete for better multilingual support.',
    fontWeight : '500'
  }
  /**
  * Flag Variable used to initial loader.
  * @type {boolean}
  */
  initialLoader:boolean=false;
  /**
   * Component constructor to inject necessary services.
   * @param snackbar Service for common snackbar operations.
   * @param dialog MatDialog service for dialog operations.
   * @param dialogService Common dialog service for handling dialogs.
   * @param datePipe Angular DatePipe for date formatting.
   * @param languageList Service for language list operations.
   * @param auth Authentication service for region subscription.
   */
  constructor(
    private snackbar: CommonSnackbarService,
    private dialog: MatDialog,
    private dialogService: CommonDialogService,
    private datePipe: DatePipe,
    private languageList: LanguageListService,
    private auth: AuthService
  ) {
    this.noDataMessage.noRecordFound = NO_RECORD_FOUND_MESSAGES?.NO_RECORD_LANGUAGES;
    this.noDataMessage.noRecordFoundDescription = NO_RECORD_FOUND_MESSAGES?.NO_RECORD_LANGUAGES_DESCRIPTION;
  }

  ngAfterViewInit() {
    // Subscribe to region changes for authentication
    this.SubscriptionObject.add(this.auth.region$.subscribe(() => {
      this.initialLoader=true;
      this.languagesListLoader = true;
      this.getLanguagesList({limit : 5 , offset : 0});
    }));
  }

  /**
   * Method to retrieve language details from the backend.
   * @param params Pagination parameters emitted.
   */
  getLanguagesList(params: LanguageListParams): void {
    this.SubscriptionObject.add(this.languageList?.getLanguagesList(params).subscribe({
      next: (res: getLanguageListResponse) => {
        this.languagesListLoader = false;
        this.data = this.transformDateFormat(res?.data?.rows);
        this.paginationData.count = res?.data?.count;
        this.initialLoader=false;
      },
      error: (err) => {
        console.error(snackber_message.LANGUAGE_FETCH_FAILED , err);
        this.initialLoader=false;
        this.languagesListLoader = false;
      }
    }));
  }

  /**
   * Method to transform date format to 'MMM dd, yyyy' format.
   * @param languagesData Data containing language list.
   */
  transformDateFormat(languagesData: languageListData[]): languageListData[] {
    return languagesData.map((item: languageListData) => ({
      ...item,
      createdDate: this.datePipe?.transform(item.createdAt, 'MMM dd, yyyy')
    }));
  }

  /**
   * Method for handling table pagination.
   * @param event Pagination event emitted.
   */
  handlePaginationChange(event: PaginationData): void {
    this.languagesListLoader = true;
    this.paginationData.limit = event.limit;
    this.paginationData.count = event.count;
    this.paginationData.offset = event.offset;
    const data = {
      offset: event.offset,
      limit: event.limit,
      filterData: JSON.stringify({searchData : this.searchData} )    
    };
    this.getLanguagesList(data);
  }

  /**
   * Method for handling table search.
   * @param event Searched string emitted.
   */
  handleSearchChange(event: string): void {
    this.languagesListLoader = true;
    this.searchData = event;
    let data = this.paramAssign();
    this.getLanguagesList(data);
  }

  /**
   * Method to open appropriate dialog box.
   * @param event Method and data for action.
   */
  openDialog(event: ActionClicked<DynamicDataSource>): void {
    (this as DynamicDataSource)[event.method](event.data);
  }

  /**
   * Method to delete language data.
   * @param dataToBeDeleted Language data to be deleted.
   */
  onDelete(dataToBeDeleted: languageListData) {
    const dialogRef = this.dialogService.openDialog({
      header: this.dialogHeader.areYouSure,
      message: this.languageMessages.languageDeleteMsg,
      button: { left: 'No', right: 'Yes, Delete' },
      actionType: this.dialogType.confirmation,
      disableClose: true
    });
    dialogRef.subscribe(result => {
      if (result) {
        this.SubscriptionObject.add(this.languageList.deleteLanguages({ id: dataToBeDeleted.id, isDeleted: true }).subscribe({
          next: (response: any) => {
            const data: LanguageListParams = {
              offset: this.paginationData.offset,
              limit: this.paginationData.limit,
            };
            if (this.searchData) {
              data.searchData = this.searchData;
            }
            this.snackbar.OpenSnackBar({ message: snackber_message.LANGUAGE_DELETION_SUCCESS, actionType: SNACKBAR_OPTIONS.success });
            this.getLanguagesList(data);
          },
          error: () => {
            this.snackbar.OpenSnackBar({ message: snackber_message.LANGUAGE_DELETION_FAILED , actionType: SNACKBAR_OPTIONS.warning });
          }
        }));
      }
    });
  }

  /**
   * Method to set dialog data for displaying messages.
   * @param header Dialog header.
   * @param action Action to be performed.
   * @param buttonData Button configuration.
   * @param messageData Optional message data.
   * @param deleteLanguageResponse API response for delete operation.
   */
  setDialogData(header: string, action: string, buttonData: { right?: string; left?: string }, messageData?: string, deleteLanguageResponse?: deleteLanguageResponseStructure) {
    return this.dialogService?.openDialog({
      header: header,
      message: (deleteLanguageResponse?.success) ? deleteLanguageResponse?.message : deleteLanguageResponse?.error?.message || messageData,
      actionType: action,
      button: buttonData
    });
  }

  /**
   * Angular lifecycle hook to unsubscribe from subscriptions.
   * @type {void}
   */
  ngOnDestroy(): void {
    if (this.SubscriptionObject) {
      this.SubscriptionObject.unsubscribe();
    }
  }

  /**
   * Method to enter edit mode for data.
   * @param event Data to be edited.
   */
  onEdit(event: any): void {
    this.action = 'Edit'
    this.onEditMode = true;
    if (event) {
      this.languageFormGroup = new FormGroup({
        id: new FormControl(event.id ?? null, [Validators.required]),
        language: new FormControl(event.language ?? null, [Validators.required]),
        languageCode: new FormControl(event.languageCode ?? null, [Validators.required]),
      });
      if (this.quickEditDisplay) {
        this.dialog?.open(this.quickEditDisplay, { disableClose: true, width: '550px' });
      }
    }
  }

  /**
   * Method to handle closing of dialog and resetting form.
   */
  dialogClose(): void {
    if (this.languageFormGroup && this.languageFormGroup.dirty) {
      const dialogRef = this.dialogService.openDialog({
        header: this.dialogHeader.areYouSure,
        message: this.message.unsavedChanges,
        button: { left: 'Stay', right: 'Yes, Leave' },
        actionType: this.dialogType.confirmation,
        disableClose: true
      });
      dialogRef.subscribe(result => {
        if (result) {
          this.dialog?.closeAll();
          this.languageFormGroup?.reset();
        }
      });
    } else {
      this.dialog?.closeAll();
      this.languageFormGroup?.reset();
    }
  }

  /**
   * Method to initialize form for creating new language data.
   */
  onCreate() {
    this.action = 'Create'
    this.onEditMode = false;
    this.languageFormGroup = new FormGroup({
      language: new FormControl('', [Validators.required]),
      languageCode: new FormControl('', [Validators.required]),
    });
    if (this.quickEditDisplay) {
      this.dialog?.open(this.quickEditDisplay, { disableClose: true, width: '550px' });
    }
  }

  /**
   * Method to create new language data.
   * @param data Language data to be created.
   */
  createLanguage(data?: any) {
    this.SubscriptionObject.add(this.languageList.createLanguage(data).subscribe({
      next: (response: any) => {
        this.languageFormGroup?.markAsPristine();
        this.snackbar.OpenSnackBar({ message: snackber_message.LANGUAGE_CREATION_SUCCESS, actionType: SNACKBAR_OPTIONS.success });
        this.getLanguagesList(this.setParamData());
        this.dialog.closeAll();
      },
      error: () => {
        this.snackbar.OpenSnackBar({ message: snackber_message.LANGUAGE_CREATION_FAILED , actionType: SNACKBAR_OPTIONS.warning });
      }
    }));
  }

  /**
   * Method to update language data.
   * @param data Language data to be updated.
   */
  updateLanguage(data: any) {
    if (this.languageFormGroup?.valid && this.languageFormGroup?.dirty) {
      this.SubscriptionObject.add(this.languageList.updateLanguage(data).subscribe({
        next: (response: any) => {
          this.languageFormGroup?.markAsPristine();
          this.snackbar.OpenSnackBar({ message: snackber_message.LANGUAGE_UPDATE_SUCCESS, actionType: SNACKBAR_OPTIONS.success });
          this.getLanguagesList(this.setParamData());
          this.dialog.closeAll();
        },
        error: () => {
          this.snackbar.OpenSnackBar({ message: snackber_message.LANGUAGE_UPDATION_FAILED, actionType: SNACKBAR_OPTIONS.warning });
        }
      }));
    }
    else if(this.languageFormGroup && !this.languageFormGroup.dirty) {
      this.snackbar && this.snackbar.OpenSnackBar({
        message: SNACKBAR_MESSAGES.noChanges,
        heading: SNACKBAR_HEADING.warning,
        actionType: SNACKBAR_TYPE.warning,
      });
    }
    else {
      this.snackbar && this.snackbar.OpenSnackBar({ message : SNACKBAR_MESSAGES.mandatoryField,
        heading: SNACKBAR_HEADING.warning, actionType : SNACKBAR_TYPE.warning });
    }
  }

  headerEvent(evnt : any){
    (this as DynamicDataSource)[evnt]();
  }

  /**
   * Method to set parameter data for API calls.
   * @returns {LanguageListParams} Parameters for language list.
   */
  setParamData(): LanguageListParams {
    const paramData: LanguageListParams = {
      offset: 0,
      limit: this.paginationData.limit,
    };
    if(this.searchData){
    paramData.searchData = this.searchData;
    }
    return paramData;
  }
  /**
   * Method to generate language code .
   * @param {languageInput} contains current input data that is being typed.
   */
  codeGenerator(languageInput:string){
    const language = languageInput.replace(' ' , '').toLowerCase()
    const languageCode = ISO6391.getCode(language);
    if(!!(languageCode.length)){
    this.languageFormGroup?.get('languageCode')?.setValue(languageCode.toUpperCase());
    }
  }

  paramAssign(): LanguageListParams {
    let data: LanguageListParams = {
      offset: this.paginationData.offset,
      limit: this.paginationData.limit
    }
    if (this.searchData) {
      data.searchData = this.searchData;
    }
    return data;
  }
}
