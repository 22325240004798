import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/shared/service/common.service';
import { Subscription } from 'rxjs';
import { MailServicesService } from '../../services/mail-services.service';
import { formatDate } from '@angular/common';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CommonSnackbarService } from 'src/app/shared/service/common-snackbar.service';

@Component({
  selector: 'app-view-mail',
  templateUrl: './view-mail.component.html',
  styleUrls: ['./view-mail.component.scss']
})
export class ViewMailComponent implements OnInit, OnDestroy {
/**
* Variable used to store the observables.
* @type {Subscription}
*/
  SubscriptionObject: Subscription = new Subscription();
  /**
  * Variable paramsId is used to store the page params id.
  * @type {number}
  */
  mailId!: string;
    /**
  * Variable is used to store mail details.
  * @type {any}
  */
  getOneMailData: any;
  dateFormat: string = "dd MMM, yyyy";
    /**
  * Variable is used to store the starred default value.
  * @type {boolean}
  */
  starred: boolean = false;
   /**
  * Variable is used to store the html content of mail content.
  * @type {any}
  */
  mailBody!: SafeHtml;
   /**
  * Variable is used to store the sender name.
  * @type {string}
  */
  senderName:string = '';
   /**
  * Variable is used to store the sender mail id.
  * @type {string}
  */
  senderEmail:string = '';
  /**
  * Variable is used to store show semail details or not.
  * @type {boolean}
  */
  isShowMailDetails: boolean=false;
   /**
  * Variable loading is used to store the loader value.
  * @type {boolean}
  */
  isLoading:boolean=false;
    /**
  * Variable loading is used to store initial email details.
  * @type {any[]}
  */
  emailDetails:any[]=[
    {name:'FROM',value:'null',fromAddress:'123@gmail.com'},
    {name:'TO',value:'null'},
    {name:'DATE',value:'null'},
    {name:'SUBJECT',value:'null'},
    {name:'MAILED-BY',value:'null'},
    {name:'SIGNED-BY',value:'null'},
  ]
  private clickListener: () => void;
     /**
  * Variable isViewMore is used to store view more icon is shown or not.
  * @type {boolean}
  */
  isViewMore: boolean=false;
       /**
  * Variable actions is used to store view more icons and values.
  * @type {Array}
  */
  actions = [
    {icon: 'undo',label: 'Reply',onClick: () => this.onReply()},
    {icon: 'redo',label: 'Forward',onClick: () => this.onForward()},
    {icon: 'delete_outline',label: 'Delete this message',onClick: () => this.onDelete()},
    {icon: 'report',label: 'Report spam',onClick: () => this.onSpamMarked(this.getOneMailData?.isMarkedSpam),condition:false},
    {icon: 'drafts',label: 'Mark as unread',onClick: () => this.onMarkUnread('unread')},
    
  ];
/**
 * Constructor to initialize the component or service.
 * 
 * This constructor is used to inject necessary services or perform any 
 * initial setup required when the component is instantiated.
 */
  constructor(private route: Router,
    private mailServices: MailServicesService,
    private commonService: CommonService,
    private paramsRouter: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private snackbar: CommonSnackbarService,
    private renderer: Renderer2
  ) {
    this.clickListener = this.renderer.listen('document', 'click', (event: Event) => {
      this.onDocumentClick(event);
    });
  }
/**
* Angular life cycle hook that initiates the component
*/
  ngOnInit(): void {
    this.getParamData();
    this.checkUrlSegment();
    this.viewMail();
  }
  /**
 * Method which is used to show mail details
 */
  viewMail() {
    this.isLoading=true;
    this.SubscriptionObject = this.mailServices.getOneEmail(this.mailId).subscribe({
      next: (getOneEmail: any) => {
        if (getOneEmail) {
          this.isLoading=false;
          this.getOneMailData = getOneEmail?.data;
          this.starred=this.getOneMailData.isStarred?true:false;
          if (this.getOneMailData?.isMarkedSpam) {
            const spamActionIndex = this.actions.findIndex(action => action.icon === 'report');
              this.actions[spamActionIndex] = {
                ...this.actions[spamActionIndex],
                condition: true
              };
          }
          const mailBodyContent = this.getOneMailData?.body;
           // Check if mailBodyContent is encrypted
          const isMailBodyEncrypted = this.isEncrypted(mailBodyContent);
           // Decrypt only if it is encrypted, otherwise use as is
           const decryptedMailBody = isMailBodyEncrypted ? this.commonService.decryptAES(mailBodyContent) || '': mailBodyContent;
          // Sanitize and assign to mailBody
          this.mailBody = this.sanitizer.bypassSecurityTrustHtml(decryptedMailBody);
          // this.mailBody = this.sanitizer.bypassSecurityTrustHtml(this.getOneMailData?.body);
          this.onSeparateSenderNameAndAddress(this.getOneMailData?.senderId);
          if(this.getOneMailData){
            this.onMarkUnread();
          }
        }
      },error:(err)=>{
        this.snackbar.OpenSnackBar({
          message:'Something went wrong',
          heading:'Failed',
          actionType:'failure',
          duration: 2
        });
        this.isLoading=false;
        this.onBack();
      }
    })
  }
  /**
  * method which is used to Check if `data` is encrypted or not
  */
     isEncrypted(data: string): boolean {
      // Check if `data` is encrypted (you can customize this check as needed)
      return /^[A-Za-z0-9+/=]+$/.test(data) && data.length % 4 === 0;
    }
  /**
  * method which is used to separate the sender's name and email address
  */
    onSeparateSenderNameAndAddress(senderId?:string){
      const senderInfo = senderId;
      if (senderInfo) {
        const match = senderInfo.match(/^(.+?)\s*<(.+?)>$/);
        if (match) {
          this.senderName = match[1];
          this.senderEmail = match[2];
        }
      }
    }
  /**
 * Method which is used to navigate home page
 */
  onBack() {
    const foundSegment = this.checkUrlSegment();
    if (foundSegment) {
    this.route.navigate([`app/zenmails/${foundSegment}`]);
  }
  }
  /**
   * Evaluates specific segments in the current URL path to set page-specific states.
   * updates the corresponding state page. 
   * handle other segments as needed.
   */
  checkUrlSegment(){
    const params = this.route.url;
    const pathSegments = params.split('/');
    const segmentsToCheck = ['inbox','sent','starred','trash','spam','draft'];
    const foundSegment = pathSegments.find(segment => segmentsToCheck.includes(segment));
    return foundSegment;
  }
/**
* method which is used to encrypt the template id
*/
  getParamData() {
    this.paramsRouter.paramMap.subscribe(params => {
      this.mailId = '' + this.commonService.getParam('' + params.get('id'));
    });
  }
/**
* method is used to change date format
*/
  getFormattedDate(date: string): string {
    const now = new Date();
    const givenDate = new Date(date);
    const timeDiffMs = now.getTime() - givenDate.getTime();
    const secondsAgo = Math.floor(timeDiffMs / 1000);
    const minutesAgo = Math.floor(secondsAgo / 60);
    const hoursAgo = Math.floor(minutesAgo / 60);
    const daysAgo = Math.floor(hoursAgo / 24);
    const formattedDate = formatDate(givenDate, 'EEE, MMM dd, hh:mm a', 'en-US');
    let timeAgoString = '';
    if (secondsAgo < 60 && secondsAgo >= 0) {
      timeAgoString = secondsAgo === 0 ? 'just now' : `${secondsAgo} second${secondsAgo > 1 ? 's' : ''} ago`;
    } else if (minutesAgo < 60) {
      timeAgoString = `${minutesAgo} minute${minutesAgo > 1 ? 's' : ''} ago`;
    } else if (hoursAgo < 24) {
      timeAgoString = `${hoursAgo} hour${hoursAgo > 1 ? 's' : ''} ago`;
    } else if (daysAgo < 30) {
      timeAgoString = `${daysAgo} day${daysAgo > 1 ? 's' : ''} ago`;
    }
    if (daysAgo >= 30) {
      return formattedDate;
    }
    return `${formattedDate} (${timeAgoString})`;
  }
    /**
   * method which is used to updated  icons based on starred or not
   */
  updateStarred() {
    this.starred = !this.starred;
    this.onUpdateActions(this.getOneMailData?.id,'is_starred',this.starred);
  }
    /**
   * method which is used to updated  mail status like read or unread
   */
  onMarkUnread(event?:string) {
    const mailId = this.getOneMailData?.id;
    const actionName=event==='unread'?'unread':'read';
    this.onUpdateActions(mailId,'read_status',event==='unread'?'unread':'read',actionName);
  }
  /**
   * method which is used to delete the mail
   */
  onDelete(){
     const mailId = this.getOneMailData?.id;
     const fieldName = 'is_deleted';
     const fieldValue = true;
     this.onUpdateActions(mailId, fieldName, fieldValue,'delete');
  }
   /**
   * method which is used to update spam mails
   */
  onSpamMarked(isSpamMarked:boolean){
  const mailId = this.getOneMailData?.id;
  const fieldName = 'is_marked_spam';
  const fieldValue = !isSpamMarked;
  const actionName=isSpamMarked?'spam':'notSpam';
  this.onUpdateActions(mailId, fieldName, fieldValue,actionName);
  }
  /**
   * method which is used to reply mails
   */
  onReply(){
  }
  /**
   * method which is used to formard mails
   */
  onForward(){
  }
  /**
 * method which is used to updated action values like delete ,read and unread mail status ,starred and not starred ant etc....
 */
    onUpdateActions(id: number, field: string, fieldValue: string | boolean,actionName?:string) {
      const mailId = id;
      const updateValues = {
        'columnName': [field], 'value': [fieldValue] 
      }
      this.SubscriptionObject=(this.mailServices?.updateEmail(mailId, updateValues).subscribe({
        next: (res:any) => {
          if (res) {
            if(actionName && actionName!=='read'){
              this.snackbar.OpenSnackBar({
                message:res?.success?actionName==='delete'?'Mail moved to Trash.':actionName==='unread'?'Mail marked as unread.':actionName==='spam'?'Mail unmarked as spam':actionName==='notSpam'?'Mail marked as spam':'':actionName==='delete'?'Failed to moved Trash.':actionName==='read'?'Mail failed to marked as read.':actionName==='unread'?'Mail failed to marked as unread.':actionName==='spam'?'Mail failed to unmarked as spam':actionName==='notSpam'?'Mail failed to marked as spam':'',
                heading:res?.success ?'Success':'Failed',
                actionType: res?.success ? 'success' : 'failure',
                duration: 2
              });
              const foundSegment = this.checkUrlSegment();
              if (foundSegment) {
              this.route.navigate([`app/zenmails/${foundSegment}`]);
              }
            }
          }
        }, error: (err) => {
          this.snackbar.OpenSnackBar({
            message: 'Something went wrong.',
            heading: 'Failed',
            actionType: 'failure',
            duration: 2
          });
        }
      }))
    }
/**
* Extract email if present, else return original senderId
*/
  extractEmail(senderId: string): string {
    const match = senderId.match(/<([^>]+)>/);
    return match ? match[1] : senderId;
  }
/**
 * method which is used to show email details when icon clicked
 */
  showMailDetails(event: Event) {
    event.stopPropagation();// Prevent event from propagating to document
    this.emailDetails = [
      { name: 'FROM', value: this.getOneMailData?.senderId ? this.getOneMailData.senderId.split('<')[0].trim() : 'null',fromAddress: this.getOneMailData?.senderId ? this.extractEmail(this.getOneMailData.senderId) : 'null', },
      { name: 'TO', value: this.getOneMailData?.recipientId || [] },
      { name: 'DATE', value: this.getOneMailData?.time_stamp ? this.formatDate(this.getOneMailData?.time_stamp): 'null' },
      { name: 'SUBJECT', value: this.getOneMailData?.subject || 'null' },
    ];
    this.isShowMailDetails = !this.isShowMailDetails;
    this.isViewMore=false;
  }
/**
 * method which is used to show the date in a partivular format
 */
  formatDate(dateString: string): string {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  }
  /**
 * method which is used to close the view more and email details design whenever a click occurs outside the relevant areas.
 */
  onDocumentClick(event: Event) {
    const target = event.target as HTMLElement;
    const clickedInside = target.closest('.show-mail-details') || target.closest('.arrow-icon')||target.closest('.view-moew-details');
    if (!clickedInside) {
      this.isShowMailDetails = false;
      this.isViewMore=false;
    }
  }
  /**
 * method which is used to show view more icons and values when clicked view more icon
 */
  viewMore(event: Event){
    event.stopPropagation();
   this.isViewMore=!this.isViewMore;
   this.isShowMailDetails=false;
  }
  /**
* Angular life cycle hook ngOnDestroy is used to unsubscribe the subscribtion.
* @type {void}
*/
  ngOnDestroy(): void {
    if (this.SubscriptionObject) {
      this.SubscriptionObject.unsubscribe();
    }
    if (this.clickListener) {
      this.clickListener();
    }
  }
}
