import { DesignValidation, FilterValidation, PatternValidation } from "../models/common-filter.model";

/**
 * Enum representing the types of control available.
 * @readonly
 * @enum {string}
 */
export enum FilterType {
  /** Represents an date control */
  Date = 'date',
  /** Represents an select control */
  Select = 'select',
  /** Represents an text control */
  Text = 'text'
}

export class filterConstants {
  /**
   * Default color schema for common-filter.
   * @readonly
   * @type {DesignValidation}
   */
  defaultFilterDesign: DesignValidation = {
    headerBackgroundColor: "#009668",
    titleTextColor:"#3498DB",
    titleIconColor:"#3498DB",
    titleTextFontSize:18,
    titleTextFontWeight:'bold',
    controlLabelColor: "#000000",
    resetButtonBorderColor:"var(--primary-color)",
    resetButtonBorderWidth:1,
    buttonTextFontWeight:500,
    class : 'primary-button',
    applyButtonTextColor : 'white'
  }
  /**
   * Icon name and error information for common-filter.
   * @readonly
   * @type {FilterValidation}
   */
  filterInformation: FilterValidation = {
    CLOSE: "close",
    SEARCH:"search",
    APPLY_TEXT:"Apply Now",
    RESET_TEXT: "Reset",
    RESET_ALL_TEXT:"Reset All",
    NO_RECORD: "No Record Found",
    INVALID_DATE:"Please enter begin date and end date",
    SPACE_VALIDATION_ERROR:"Empty spaces are not allowed."
  }
  /**
   * Input value's validation pattern for common-filter.
   * @readonly
   * @type {PatternValidation}
   */
  patternValidator:PatternValidation={
    noSpaceValidation:".*\\S.*[a-zA-z0-9]*"
  }

}