import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { AuthService } from '../../service/auth.service';
import { FeatureDetails, TokenData } from '../../models/auth.model';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { AuthConstants, EMAIL_DOMAIN, MAIL_FEATURES } from '../../constant/auth.constants';
import { CommonSnackbarService } from 'src/app/shared/service/common-snackbar.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { SharedService } from 'src/app/shared/services/shared.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { ValidationService } from '../../service/validation.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
})
export class SigninComponent extends AuthConstants implements OnInit {
  /**
   * @type {Subscription}
   * Subscription for managing subscriptions.
   */
  subscription: Subscription = new Subscription();
  /**
   * @type {FeatureDetails[]}
   * List of features in our mail service.
   */
  featureDetails: FeatureDetails[] = _.cloneDeep(MAIL_FEATURES);
  /**
   * @type {number}
   * variable used to perform autoslide of mail service features.
   */
  selectedIndex: number = 0;
  /**
   * This variable stores the timeout identifier for an automatic slider or timer.
   * @type {ReturnType<typeof setTimeout>}
   */
  autoSliderTimeOutId!: ReturnType<typeof setTimeout>;
  /**
   * This variable stores the timeout identifier for an animation operation.
   * @type {ReturnType<typeof setTimeout>}
   */
  animationTimeOutId!: ReturnType<typeof setTimeout>;
  /**
   * FormGroup instance for the login form.
   * @type {FormGroup}
   */
      loginForm!: FormGroup;
   /**
   * FormGroup instance for the signup form.
   * @type {FormGroup}
   */
      signUpFormGroup!: FormGroup;
    /**
   * FormGroup instance for the forgot password form.
   * @type {FormGroup}
   */
      forgotFormGroup!: FormGroup;
  /**
   * This boolean variable tracks whether a slide change has occurred.
   * @type {boolean}
   */
  isSlideChange: boolean = false;
   /**
   * This boolean variable tracks whether a login is invalid or not.
   * @type {boolean}
   */
  inValidLogin: boolean = false;
   /**
   * This boolean variable tracks whether a api occurre error or not.
   * @type {boolean}
   */
  ApiFailed: boolean = false;
   /**
   * This boolean variable tracks whether a signup-form animation is worked or not.
   * @type {boolean}
   */
  isSignUp: boolean = false;
    /**
   * This boolean variable tracks whether a signin-form animation is worked or not.
   * @type {boolean}
   */
  isSignIn: boolean = false;
    /**
   * This boolean variable tracks whether a forgot-password-form animation is worked or not.
   * @type {boolean}
   */
  isForgotPassword:boolean=false;
    /**
   * This boolean variable tracks whether a set-password-form animation is worked or not.
   * @type {boolean}
   */
  isSetPassword:boolean=false;
    /**
   * This boolean variable tracks whether a loading occured.
   * @type {boolean}
   */
  isLoading:boolean=false;
    /**
   * This boolean variable tracks whether a password types.
   * @type {boolean}
   */
  showPassword: any = {};
     /**
   * This variable is used to store the social media link and its image.
   * @type {Array}
   */
  socialMediaImg: any[] = [
    {
      src: '../../../../assets/icons8-google-24.png',
      link: 'https://accounts.google.com/'
    },
    {
    src: '../../../../assets/icons8-facebook-24.png',
    link: 'https://www.facebook.com/'
  }, 
  {
    src: '../../../../assets/icons8-linkedin-32.png',
    link: 'https://www.linkedin.com/'
  }];
     /**
   * This variable is used to store the privacyPolicy and termsOfService links.
   * @type {Array}
   */
  zenbasketUrls={
  privacyPolicy: 'https://getzenbasket.com/privacy-policy/', 
  termsOfService: 'https://getzenbasket.com/terms-of-service/'}
   /**
   * This variable is used to store the checkbox values.
   * @type {Array}
   */
  isChecked:boolean = false;
  isUser:boolean = false;
  alreadyExistMail: boolean=false;
    /**
* varialbe which is used to indicate password vaidation will be show or not
*/
isErrorShow:boolean=false;
/**
* varialbe which is used to define whether to display the newPassword in text format or not.
*/
showNewPassword = { newPassword: false };
/**
* varialbe which is used to define whether to display the confirmPassword in text format or not.
*/
  showConfirmPassword = { confirmPassword: false };
  /**
* varialbe which is used to define whether to display the signin password field type is text format or not.
*/
  showCurrentPassword = { currentPassword: false };
  EMAIL_DOMAIN=EMAIL_DOMAIN;
  /**
   * Constructor to inject the required services.
   * @param {AuthService} authService - Service to handle authentication operations.
   * @param {Router} router - Router instance for navigation.
   */
  constructor(private authService: AuthService,
     private router: Router, 
     private snackbar: CommonSnackbarService,
     private sharedService : SharedService,
     private commonService: CommonService,
     private validationService:ValidationService
    ) { super(); }
  /**
   * Angular life cycle hooks
   */
  ngOnInit() {
    this.signInFormInitialization();
    this.autoSlide();
  }
  signInFormInitialization(){
    this.loginForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.pattern(this.validator['emailValidationPattern'])]),
      password: new FormControl(null, [Validators.required]),
    });
  }
  /**
   * Handles the form submission for user login.
   * If the form is valid, it calls the signIn method from AuthService and navigates to the application upon successful login.
   */
  onSignIn(): void {
    this.inValidLogin=false;
    this.ApiFailed=false;
    if (this.loginForm.valid) {
      this.isLoading=true;
      this.subscription.add(
        this.authService.signIn(this.loginForm.value).subscribe({
            next: (res: TokenData) => {
              if (res?.token) {
                this.isLoading=false;
                this.subscription.add(this.sharedService.getMenuDetails().subscribe((res: any) => {
                const menuCustomLink = res[0]?.menu[0]?.menuCustomLink??res[0]?.menu[0]?.subMenu[0]?.pageCustomLink;
                this.router.navigate([menuCustomLink]);
                }))
              } else {
                this.isLoading=false;
                this.inValidLogin = true;
              }
            },
            error: (err) => {
              this.isLoading=false;
             this.ApiFailed=true;
            },
        })
      );
    }
  }
  /**
   * Method used to automatically transition to the next slide in a slideshow.
   */
  autoSlide() {
    if (this.featureDetails && this.featureDetails.length > 1) {
      this.autoSliderTimeOutId = setTimeout(() => {
        this.selectedIndex = (this.selectedIndex + 1) % this.featureDetails.length;
        this.triggerSlideChange();
        this.autoSlide();
      }, 5000);
    }
  }
  /**
   * Function to change the active slide to a specified index in a slideshow or carousel.
   * @param index - The index of the slide to make active.
   */
  slideChange(index: number) {
    clearTimeout(this.autoSliderTimeOutId);
    this.selectedIndex = index;
    this.isSlideChange = false;
    this.triggerSlideChange();
  }
  /**
   * Helper method to handle slide change animation.
   */
  triggerSlideChange() {
    this.isSlideChange = true;
    clearTimeout(this.animationTimeOutId);
    this.animationTimeOutId = setTimeout(() => this.isSlideChange = false, 2000);
  }
  /**
* Method which is used to define whether to display the password in text format or not.
* @param property hold the current form of the password.
*/
  showPasswordType(field: 'newPassword' | 'confirmPassword'|'currentPassword') {
  if (field === 'newPassword') {
    this.showNewPassword.newPassword = !this.showNewPassword.newPassword;
  } else if (field === 'confirmPassword') {
    this.showConfirmPassword.confirmPassword = !this.showConfirmPassword.confirmPassword;
  }
  else if(field === 'currentPassword'){
    this.showCurrentPassword.currentPassword = !this.showCurrentPassword.currentPassword;
  }
  }
  /**
   * method which is used to navigate SignUp form.
   */
  navigateToSignUp(): void {
    this.isSignUp = !this.isSignUp;
    this.isChecked=false;
    this.isSignIn = false;
    this.isForgotPassword=false;
    this.ApiFailed=false;
    this.inValidLogin=false;
    this.loginForm.reset();
    this.isUser=false;
    this.isSetPassword=false;
    this.signUpFormInitialize();
  }
   /**
   * method which is used to initialize the SignUp form.
   */
  signUpFormInitialize(signUpFormGroupValues?:any) {
    this.signUpFormGroup = new FormGroup({
      firstName: new FormControl(signUpFormGroupValues?.firstName??null, [Validators.required]),
      lastName: new FormControl(signUpFormGroupValues?.lastName??null),
      phoneNumber:new FormControl(signUpFormGroupValues?.phoneNumber??null,[]),
      email: new FormControl(signUpFormGroupValues?.email, [Validators.required,this.forbiddenEmailValidator(),this.noSpacesValidator()],[this.validationService.emailExistValidator()]),
      newPassword: new FormControl(signUpFormGroupValues?.newPassword, [Validators.required, this.passwordValidator]),
      confirmPassword: new FormControl(signUpFormGroupValues?.confirmPassword, [Validators.required]),
    })
    this.signUpFormGroup.controls['phoneNumber'].valueChanges.subscribe(() => {
      this.phoneNumberValidator();
    });
    this.signUpFormGroup.controls['confirmPassword'].valueChanges.subscribe(() => {
      this.checkPasswordMatch();
    });
  }
  phoneNumberValidator() {
    const phoneNumber = this.signUpFormGroup.controls['phoneNumber'].value;
    const phoneControl = this.signUpFormGroup.controls['phoneNumber'];
    if (!phoneNumber) {
      phoneControl.setErrors(null);
      return;
    }
    const isValid = /^\d{10}$/.test(phoneNumber);
    phoneControl.markAsTouched();
    phoneControl.markAsDirty();
      if (!isValid) {
      phoneControl.setErrors({ invalidPhoneNumber: true });
    } else {
      phoneControl.setErrors(null);
    }
  }
  checkPasswordMatch() {
    const newPassword = this.signUpFormGroup.controls['newPassword'].value;
    const confirmPassword = this.signUpFormGroup.controls['confirmPassword'].value;
    if (newPassword !== confirmPassword) {
      this.signUpFormGroup.controls['confirmPassword'].setErrors({ passwordMismatch: true });
    } else {
      this.signUpFormGroup.controls['confirmPassword'].setErrors(null); // Remove error if passwords match
    }
  }
     /**
   * method which is used to initialize the forgot-password form.
   */
  forgotFormInitialize() {
    this.forgotFormGroup = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
    })
  }
    /**
   * Handles the form submission for SignUp.
   * If the form is valid, it calls the signUn method from AuthService.
   */
  // onSignUp() {
  //   this.ApiFailed=false;
  //   this.alreadyExistMail=false;
  //   if (this.signUpFormGroup.valid && this.isChecked) {
  //     this.isLoading=true;
  //     const signUpValue={
  //       firstName:this.signUpFormGroup.controls['firstName'].value,
  //       lastName:this.signUpFormGroup.controls['lastName'].value,
  //       email:this.signUpFormGroup.controls['email'].value+'@zenmail.in'
  //     }
  //     this.subscription.add(this.authService.signUp(signUpValue).subscribe({
  //       next: (res:any) => {
  //         if (res) {
  //           this.isLoading=false;
  //           if(res.alreadyExist=true){
  //             this.alreadyExistMail=true;
  //           } if(res.response){
  //           this.snackbar.OpenSnackBar({
  //             message: 'Email has been sent to your registered email address. Please check your inbox to set your password.',
  //             heading: 'Success',
  //             actionType: 'success',
  //             duration: 10
  //           });
  //           this.isSignUp=false;
  //           this.isChecked=false;
  //           this.isSignIn=true;
  //         }
  //         }
  //       },
  //       error: (err) => {
  //         this.isLoading=false;
  //         this.ApiFailed=true;
  //       },
  //     })
  //     )
  //   }
  // }
    /**
   * Handles the form submission for forgot-password.
   * If the form is valid, it calls the forgot-password method from AuthService.
   */
  onForgot(){
    this.isUser=false;
    this.ApiFailed=false;
    if(this.forgotFormGroup.valid){
      this.isLoading=true;
      this.authService.forgotPassword(this.forgotFormGroup.value).subscribe({
        next:(forgotpasswordDetails)=>{
           if(forgotpasswordDetails){
             this.isLoading=false;
             this.snackbar.OpenSnackBar({
               message: 'Email has been sent to your registered email address. Please check your inbox to update your password.',
               heading: 'Success',
               actionType: 'success',
               duration: 10
             });
              // this.router.navigate(['/set-password','change-password'])
              this.navigateToSignIn();
           }
        },error:(err)=>{
          this.isLoading=false;
          this.isUser=true;
        }
      })
    }
  }
    /**
   * method which is used to navigate the signIn form.
   */
  navigateToSignIn() {
    this.isSignIn = !this.isSignIn;
    this.isSignUp = false;
    this.isChecked=false;
    this.isForgotPassword=false;
    this.ApiFailed=false;
    this.alreadyExistMail=false;
    this.isSetPassword=false;
    this.signUpFormGroup.reset();
  }
    /**
   * method which is used to navigate the forgot-password form.
   */
  navigateToFotgorPassword() {
    this.isForgotPassword = !this.isForgotPassword;
    this.isSignIn = false;
    this.ApiFailed=false;
    this.isSetPassword=false;
    this.forgotFormInitialize();
  }
    /**
   * method which is used to update the check box values.
   */
  onCheckboxChange(event: MatCheckboxChange): void {
    this.isChecked = event.checked;
  }
     /**
   * method which is used to check email extension.
   */
     forbiddenEmailValidator(): ValidatorFn {
      return (control: AbstractControl): ValidationErrors | null => {
        const email = control.value;
        const forbidden = /[@.]/.test(email);
        return forbidden ? { forbiddenEmail: { value: email } } : null;
      };
    }
     /**
     * method which is used to check no space contains in email
     */
    noSpacesValidator(): ValidatorFn {
      return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;
          if (value && (value.trim() !== value || /\s/.test(value.trim()))) {
          return { noSpaces: true };
        }
        return null;
      };
    }
/**
** method which is used to create password
*/
  onSetPasswordPage(signUpFormGroupValues?:any,method?:string){
    this.ApiFailed=false;
    if(method==='onCreatePassword'){
      if(this.signUpFormGroup.valid){
        this.isLoading=true;
        if(this.signUpFormGroup.valid && this.signUpFormGroup.controls['newPassword'].value ===this.signUpFormGroup.controls['confirmPassword'].value ){
        const signUpValues={
          firstName:this.signUpFormGroup.get('firstName')?.value,
          lastName:this.signUpFormGroup.get('lastName')?.value,
          phoneNumber:this.signUpFormGroup.get('phoneNumber')?.value,
          emailId:this.signUpFormGroup.get('email')?.value+EMAIL_DOMAIN,
          password:(this.signUpFormGroup.get('confirmPassword')?.value),
        }
        this.subscription=this.authService.signUpNew(signUpValues).subscribe({
          next:(response:any)=>{
            if(response.success){
              this.isLoading=false;
              this.snackbar.OpenSnackBar({
                message: 'Account created successfully.',
                heading: 'Success',
                actionType: 'success',
                duration: 5
              });
               this.isSetPassword=false;
               this.isSignUp=false;
               this.isSignIn=true;
               this.signInFormInitialization();
            }
          },error:(err)=>{
            this.ApiFailed=true;
            this.isLoading=false;
          }
        })
        }
      }
    } else if(method===''){
      this.isSetPassword=!this.isSetPassword;
      this.signUpFormInitialize(signUpFormGroupValues);
    }
  }
  backSignUp(){
    this.isSetPassword=false;
  }
/**
* Method which is used to check the password validation.
*/
passwordValidator(control: AbstractControl) {
  const value = control.value || '';
  const errors: any = {};
  if (!/[A-Z]/.test(value)) {
    errors.missingUpperCase = true;
  }
  if (!/[a-z]/.test(value)) {
    errors.missingLowerCase = true;
  }
  if (!/[0-9]/.test(value)) {
    errors.missingNumber = true;
  }
  if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
    errors.missingSpecialChar = true;
  }
  if (value.length < 8) {
    errors.invalidLength = true;
  }
  if (/\s/.test(value)) {
    errors.containsWhitespace = true;
  }
  return Object.keys(errors).length ? errors : null;
}
/**
* Method which is used to show password strength through percentage.
*/
calculatePasswordStrength(): number {
  const errors = this.signUpFormGroup.get('newPassword')?.errors;
  if (!errors) return 100;
  const totalChecks = 5;
  const passedChecks = totalChecks - Object.keys(errors).length;
  return (passedChecks / totalChecks) * 100;
}
/**
* Method which is used to show progress text color based on percentage.
*/
getProgressColor() {
  const strength = this.calculatePasswordStrength();
  if (strength === 100) {
    return 'green'; 
  } else if (strength >= 60) {
    return '#3e32f7';
  } else {
    return 'red';
  }
}
/**
* Method which is used to show password label text based on percentage.
*/
getPasswordStrengthLabel(): string {
  const strength = this.calculatePasswordStrength();
  if (strength === 100) {
    return 'Strong';
  } else if (strength === 60 || strength === 80) {
    return 'Normal';
  } else {
    return 'Weak';
  }
}
/**
* Method which is used to show all password validation.
*/
onShowvalidation(){
  this.isErrorShow=!this.isErrorShow;
}
/**
* Method which is used to disable the paste functionality.
*/
disablePaste(event: ClipboardEvent): void {
  event.preventDefault();
}
/**
* Method which is used to whether show the password requirment while hovering.
*/
toggleErrorShow(value: boolean): void {
  this.isErrorShow = value;
}
    /**
   * Angular life cycle hook which is used to clear subscription.
   */
    ngOnDestroy(): void {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      clearTimeout(this.animationTimeOutId);
      clearTimeout(this.autoSliderTimeOutId);
    }
}
