<div class="mail-compose-window" cdkDrag>
  <!-- Title container for the compose window -->
  <tr class="title-container" cdkDragHandle  fxLayoutAlign="space-between center">
      <!-- Title for the new message -->
      <td fxLayoutAlign="center center">
          <div style="font-size: 14px; font-weight: 500;" class="subject-line">{{(data&&data?.subject)?data?.subject:'New Message'}}</div>
      </td>
      <!-- Close button for the compose window -->
      <td  style="cursor: pointer" class="close-button" fxLayoutAlign="center center" (click)="closeDialog('close')">        <span class="material-symbols-outlined" [ngStyle]="{'font-size' : '22px'}">
          close
        </span>
      </td>
  </tr>
  
  <!-- Form group for the email application -->
  <form [formGroup]="applicationForm" >
      <!-- Container for email recipients -->
      <div class="mail-address" fxLayout="row wrap">
          <!-- Loop through each recipient and display them -->
          <div
              fxLayout="row"
              fxLayoutAlign="center center"
              fxLayoutGap="8px"
              *ngFor="let items of mailRecipients; let index = index"
              class="mail-recipients-container">
              <div>{{ items }}</div>
              <!-- Remove recipient icon -->
              <mat-icon fxLayoutAlign="start center" (click)="removeRecipients(index)" style="font-size:20px;">close</mat-icon>
          </div>
          <!-- Input field for adding new recipients -->
          <div class="mail-recipients-input" fxLayoutAlign="center center">
              <input
                  formControlName="emailValidator"
                  #input
                  (keyup.enter)="addRecipients(input.value, input)"
                  [placeholder]="!(mailRecipients[0]) ? 'Recipients' : ''"
                  class="mail-recipients-text"
                  type="text" (focusout)="addRecipients(input.value, input)"/>
          </div>
          <!-- Section border indicating input validity -->
          <div [style.border-bottom]="!(applicationForm.get('emailValidator')?.hasError('email')) ? '1px solid #cccccc' : '1px solid red'" class="section-border"></div>
          <!-- Error message for invalid email -->
          <mat-error *ngIf="applicationForm.get('emailValidator')?.hasError('email')">Enter a valid email address</mat-error>
      </div>
      
      <!-- Input field for the email subject -->
      <div class="mail-subject" fxLayoutGap="10px" fxLayout="column">
          <input
              #input
              placeholder="Subject"
              class="mail-recipients-text"
              type="text"
              formControlName="emailSubject" /> 
          <div class="section-border"></div>
      </div>

      <!-- Content area for the email body -->
      <div #customEditor class="mail-content" fxLayout="column"  >
          <app-custom-editor  formControlName="emailContent"></app-custom-editor>
          <div class="composer-options" fxLayoutAlign="end center">
            <!-- <button class="button-container" style="background-color:var(--primary-color); color: white; margin: 16px 8px 0px 0;" mat-flat-button><span *ngIf="!isLoading">Save as draft</span><div class="dot-pulse" *ngIf="isLoading"></div></button> -->
            <button class="button-container" style="background-color:var(--primary-color); color: white; margin: 16px 0 0px 0;" mat-flat-button (click)="onSentMail()"><span *ngIf="!isLoading">Sent</span><div class="dot-pulse" *ngIf="isLoading"></div></button>
          </div>
      </div> 
  </form>
</div>
