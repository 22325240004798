import { SortSetting } from "../models/common-mails-data.model";

/**
 * Default sorting settings.
 * @type {Readonly<SortSetting>}
 */
export const DEFAULT_SELECTEDSORT: Readonly<SortSetting> = {
  label: '',
  field: '',
  direction: 'asc',
  color:''
};