import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpRoutingService } from 'src/app/common/service/http-routing.service';
import { DeleteConfigurationDetail, GetConfigurationData, HttpParams, SingleConfigurationData, SingleConfigurationResponse, UpdateResponse } from '../models/configuration.model';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  /**
   * component constructor which is used to inject the required services and modules.
   * @param httpClient To refer to the httpClient module to access the get and put methods.
   */
  constructor(private httpClient: HttpRoutingService) { }
  /**
   * Method which is used to get Configuration list data.
   * @param { HttpParams } paramData It has the query params of the Url.
   * @returns { Observable<GetConfigurationData> }
   */
  getConfigurationList(paramData: HttpParams): Observable<GetConfigurationData> {
    return this.httpClient.getRequest('microservice/mail/configuration?', paramData);  
  }

  /**
   * Method used to handle the delete action when the delete icon is clicked.
   * It uses the provided configuration data to perform the deletion.
   * @param { number } id 
   * @returns { Observable<DeleteConfigurationDetail> }
   */
  deleteConfiguration(id : number): Observable<DeleteConfigurationDetail> {
    return this.httpClient.putRequest( `microservice/mail/configuration/${id}/delete`, {isDeleted: true});
  }

  /**
   * Method which is used to get single configuration data.
   * @param { string } id 
   * @returns { Observable<SingleConfigurationResponse> }
   */
  getSingleConfiguration(id: string): Observable<SingleConfigurationResponse> {
    return this.httpClient.getRequest(`microservice/mail/configuration/${id}`);
  }

  /**
   * Method which is used to create a configuraion.
   * @param { SingleConfigurationData } data 
   * @returns { Observable<SingleConfigurationData> }
   */
  createConfiguration(data: SingleConfigurationData): Observable<SingleConfigurationData> {
    return this.httpClient.postRequest("microservice/mail/configuration/create", data);
  }

  /**
   * Method which is used to update a configuration.
   * @param { SingleConfigurationData } data 
   * @param { string } id 
   * @returns { Observable<UpdateResponse> }
   */
  updateConfiguration(data: SingleConfigurationData, id: string): Observable<UpdateResponse> {
    return this.httpClient.putRequest(`microservice/mail/configuration/${id}/update`, data)
  }
}
