<div *ngIf="!initialLoader" class="common-header">
  <app-common-header (methodClicked)="headerEvent($event)" [buttonInfo]="button" [heading]="heading" ></app-common-header>
</div>
<div [ngStyle]="{'margin':' 20px 20px 0px 20px'}" *ngIf="initialLoader" class="common-card-style" >
 <app-skeleton-loader [formLoader]="false"></app-skeleton-loader>
</div>
<div fxLayout.lt-md="column" *ngIf="!initialLoader" class="common-card-style">
  <app-common-filter *ngIf="isFilter" [filterData]="filterDetails" [isFilterStatus]="isFilterValue"
     (filterClose)=" closeFilter()" (emittedData)="emittedEvent($event)" (AppliedFilters)="appliedFiltersLength($event)">
  </app-common-filter>
  <div fxFlex="100%">
     <app-common-card [buttonInfo]="button"  [sortSetting]="sorting" (sortChange)="sortData($event)" (methodClicked)="headerEvent($event)" [dataNotFoundMessage]="noDataMessage" [columns]="columns" [dataSource]="data" [actionSetting]="actions" [hasSearchField]="true"
         [searchSetting]="searchSettingData" [hasPagination]="true"  (paginationChange)="handlePaginationChange($event)" [paginationData]="paginationData"  [optionalEvent]="optionalEvent" [loading]="userListLoader"
         (searchChange)="handleSearchChange($event)" (dropdownClicked)="onToggleChanged($event)" [appliedFilters]="appliedFilters" 
         (optionalEventOccurred)="handleOptionalEvent($event)" (actionClicked)="onActionClicked($event)"></app-common-card>
  </div>
</div>
<div class="dialog-box">
  <ng-template #dialogDisplay>
    <div class="dialog-container" fxLayout="column" >
        <h5 class="dialog-header" mat-dialog-title>
        {{isEditMode ? "Update User" : "Create User"}}
          <mat-icon (click)="dialogClose();" class="dialogCloseIcon">close</mat-icon>
        </h5>
        <div mat-dialog-content style="padding: 30px">
          <form fxLayout="column" *ngIf="userFormGroup" [formGroup]="userFormGroup" (ngSubmit)="isCreateMode ? onSubmit() : onUpdate()">
            <div fxLayoutGap="2%" fxLayout="column">
              <mat-label>First Name *</mat-label>
              <mat-form-field class="form-container" appearance="outline">
                <input matInput formControlName="firstName" placeholder="Enter First Name" data-control="firstName">
                <mat-error data-control="firstNameError" *ngIf="userFormGroup?.get('firstName')?.hasError('required')">
                  {{userValidators.validators.firstNameRequired}}
                </mat-error>
              </mat-form-field>
            </div>
            <div fxLayoutGap="2%" fxLayout="column">
              <mat-label>Last Name</mat-label>
              <mat-form-field class="form-container" appearance="outline">
                <input matInput formControlName="lastName" placeholder="Enter Last Name" data-control="lastName">
                <!-- <mat-error data-control="lastNameError" *ngIf="userFormGroup?.get('lastName')?.hasError('required')">
                  {{userValidators.validators.lastNameRequired}}
                </mat-error> -->
              </mat-form-field>
            </div>
            <div fxLayoutGap="2%" fxLayout="column">
              <mat-label>Email *</mat-label>
              <mat-form-field class="form-container" appearance="outline">
                <input matInput formControlName="email" placeholder="Enter Email Address" data-control="email">
                <mat-error data-control="emailError" *ngIf="userFormGroup?.get('email')?.hasError('required')">
                  {{userValidators.validators.emailIdRequired}}
                </mat-error>
                <mat-error data-control="emailError" *ngIf="userFormGroup?.get('email')?.hasError('email')">
                  {{userValidators.validators.emailIdPattern}}
                </mat-error>
              </mat-form-field>
            </div>
            <!-- <mat-form-field *ngIf="isCreateMode" appearance="outline">
              <mat-label>Password</mat-label>
              <input matInput formControlName="password" data-control="password">
              <mat-error data-control="passwordError" *ngIf="userFormGroup?.get('password')?.hasError('required')">
                Password is required.
              </mat-error>
            </mat-form-field> -->
            <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="center center">
              <button class="cancel-button btn" type="button" mat-button (click)="dialogClose()"
                data-control="dialogEditCancel">{{"Back"}}</button>
              <button class="btn" type="submit" mat-button data-control="dialogEditUpdate">
                {{isCreateMode ? "Create" : "Update"}}
              </button>
            </div>
        </form>
        </div>
      </div>
  </ng-template>
</div>