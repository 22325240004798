<div class="container" fxLayout="row" fxLayoutAlign="center center" *ngIf="signUp">
  <form [formGroup]="signUp">
    <mat-card fxLayout="row" class="card-container" ngClass.lt-sm="card-container-lt-sm" >
        <div class="form-container" fxLayout="column"fxFlex=100%>
          <h2 class="main-heading">Sign up</h2>
          <mat-label>First Name</mat-label>
          <mat-form-field appearance="outline">
            <input matInput placeholder="firstName" formControlName="firstName">
            <mat-error data-control="fieldRequiredError" *ngIf="signUp?.get('firstName')?.hasError('required')">
              This field required
            </mat-error>
          </mat-form-field>
          <mat-label>Last Name</mat-label>
          <mat-form-field appearance="outline">
            <input matInput placeholder="lastName" formControlName="lastName">
            <mat-error data-control="fieldRequiredError" *ngIf="signUp?.get('lastName')?.hasError('required')">
              This field required
            </mat-error>
          </mat-form-field>
          <mat-label>User Email</mat-label>
          <mat-form-field appearance="outline">
            <input matInput placeholder="Email" formControlName="email">
            <mat-error data-control="fieldRequiredError" *ngIf="signUp?.get('email')?.hasError('required')">
              This field required
            </mat-error>
          </mat-form-field>
          <mat-label>Password</mat-label>
          <mat-form-field appearance="outline">
            <input matInput placeholder="Password" formControlName="password" type="password">
            <mat-error data-control="fieldRequiredError" *ngIf="signUp?.get('password')?.hasError('required')">
              This field required
            </mat-error>
          </mat-form-field>
          <div fxLayout="row" fxLayoutGap="5px" class="terms-check" fxLayoutAlign="none center">
            <mat-checkbox></mat-checkbox>
            <span data-control="termAndConditions" class="sign-up-condition">I agree to the <span class="sign-up-link">Terms of Service</span> and <span class="sign-up-link">Privacy Policy.</span></span>
          </div>
          <button class="button-container" data-control="signInFunction" mat-raised-button (click)="onSubmit()">Sign Up</button>
        </div>
    </mat-card>
  </form>
</div>