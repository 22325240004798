import { Component } from '@angular/core';
import { CustomersComponent } from 'libs/shared/src/lib/components/customers-list/customers-list.component';

@Component({
  selector: 'app-marketing-customers-list',
  templateUrl: './marketing-customers-list.component.html',
  standalone: true,
  imports: [CustomersComponent],
  styleUrls: ['./marketing-customers-list.component.scss']
})
export class MarketingCustomersListComponent {

}
