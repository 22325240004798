import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthModule } from './auth/auth.module';
import { TokenInterceptorService } from './auth/service/token.interceptor.service';
import { CanDeactivateGuard } from './shared/service/can-deactivate-guard.service';
import { ZenMailModule } from './zen-mail/zen-mail.module';
import { ZenMarketingModule } from './zen-marketing/zen-marketing.module';
import { ZenMailsModule } from './zen-mails/zen-mails.module';
import { ViewProfileComponent } from './view-profile/components/view-profile.component';

@NgModule({
  declarations: [
    AppComponent,
    ViewProfileComponent
    ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CommonModule,
    SharedModule,
    AuthModule,
    HttpClientModule,
    ZenMailModule,
    ZenMarketingModule,
    ZenMailsModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    }, CanDeactivateGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
