import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CommonDialogComponent } from '../components/common-dialog/common-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class CommonDialogService {

  constructor(public dialog: MatDialog) {}

  /**
   * Method which is used to open the confirmation dialog box.
   * @param dialogData The data which is used to display in the dialog box.
   */
  openDialog(dialogData: any) {
    const dialogRef = this.dialog.open(CommonDialogComponent, {
      data: dialogData,
      autoFocus: false,
      width: '330px',
      backdropClass: dialogData.backdropClassName,
      disableClose: dialogData.disableClose
    });
    return dialogRef.afterClosed();
  }
}
