import { ButtonText, DialogColor, DialogHeader, DialogType } from "../models/common-dialog-data.model";

/**
 * An object that maps dialog box types.
 * @type {Readonly<DialogType>}
 */
export const DIALOG_TYPE: Readonly<DialogType> ={
  alert: 'alert',
  confirmation: 'confirmation',
  information: 'information',
  success: 'success',
  failure: 'failure',
  warning: 'warning',
  verification: 'verification'
}

/**
 * An object containing confirmation messages.
 * @type {Readonly<ConfirmMessages>}
 */
export const CONFIRM_MESSAGES ={
  paymentCancellation: "Are you sure you want to cancel your payment request?",
  requestPayment: "Are you sure you want to request payment?",
  deleteApplication : " Are you sure you want to delete this application? ",
  deleteCustomer: "Are you sure you want to delete this customer?",
  deleteLanguage : "Are you sure you want to delete this language?",
  deleteConfiguration: "Are you sure you want to delete this configuration?",
  deleteUser: "Are you sure you want to delete your user?",
  unsavedChanges: "You have unsaved changes. Are you sure you want to leave this page?"
}

/**
 * An object that contains dialog box header.
 * @type {Readonly<DialogHeader>}
 */
export const DIALOG_HEADER: Readonly<DialogHeader> ={
  failure: 'Failure',
  confirmation: 'Confirmation',
  verification: 'Verification',
  success: 'Success',
  areYouSure: "Are you sure?",
  successImg:"../../../../assets/correct.png",
  failureImg:"../../../../assets/close (1).png",
  confirmationImg:"../../../../assets/light-bulb.png",
  verificationImg:"../../../../assets/security.png"
}

/**
 * An object containing button text labels.
 * @type {Readonly<ButtonText>}
 */
export const BUTTON_TEXT: Readonly<ButtonText> ={
  ok: 'Ok',
  yes: 'Yes',
  no : 'No',
  cancel : 'Cancel',
  submit: 'Submit',
  save: 'Save',
  leave: 'Leave',
  stay: 'Stay',
  continue: 'Continue',
  delete : 'Delete',
  leaveText : 'Yes, Leave',
  noLeaveText : 'No, Stay'
}

/**
 * Dialog box color and images.
 * @type {Array<DialogColor>}
 */
export const DIALOG_COLOR_AND_IMAGE: Array<DialogColor> = [
  { name: 'success', color: '#35ad6f', outerCircleColor1: '#aaf894', outerCircleColor2: 'white', outerCircleColor3: '#cefcc1', image:'./assets/success1.png' },
  { name: 'failure', color: 'rgb(237 28 36)', outerCircleColor1: '#fbbdbd', outerCircleColor2: 'white', outerCircleColor3: '#fae5e5', image:'./assets/failure1.png' },
  { name: 'confirmation', color: '#3498DB', outerCircleColor1: '#b2effa', outerCircleColor2: 'white', outerCircleColor3: '#eefcff', image:'./assets/question.png' },
  { name: 'verification', color: '#00B5E2', outerCircleColor1: '#b2effa', outerCircleColor2: 'white', outerCircleColor3: '#eefcff', image:'./assets/padlock.png' }

]

/**
 * An object containing confirmation messages fot users.
 * @type {Readonly<messages>}
 */
export const USER_MESSAGES ={
  userEnabled: "Are you sure you want to restore this user's access?",
  userDisabled: "Are you sure do you want to suspend this user?",
  userEnableUpdatedSuccessfull:"User's Access Reinstated",
  userDisableUpdatedSuccessfull:"User Suspended Successfully",
  userUpdatedFailed:"Failed To Update The User",
  userDeleteMsg:"Are you sure do you want to delete this User?",
  userDeletedSuccessfull:"User Deleted Successfully",
  userDeletedFailed:"Failed To Delete User"
}

export const LANGUAGE_MESSAGES = {
  languageDeleteMsg : "Are you sure do you want to delete this Language?"
}

