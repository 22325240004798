import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpRoutingService } from 'src/app/common/service/http-routing.service';
import { DeleteCustomerDetail, GetApplicationList, GetCustomersList, HttpParams, SingleCustomer, SingleCustomerData } from '../models/customers.model';
import { DynamicDataSource } from 'src/app/shared/models/common-card-data.model';

@Injectable({
  providedIn: 'root'
})
export class CustomersService {
  /**
   * component constructor which is used to inject the required services and modules.
   * @param httpClient To refer to the httpClient module to access the get and put methods.
   */
  constructor(private httpClient: HttpRoutingService) { }
  /**
   * Method which is used to get customers list data.
   * @param { HttpParams } paramData It has the query params of the Url.
   * @type { Observable<GetCustomersList> }
   */
  getCustomersList(paramData: any): Observable<GetCustomersList> {
  let filterData: any = { roleId: 3 };
  if (paramData?.filterData) {
    const paramFilter = JSON.parse(paramData.filterData);
    filterData = { ...filterData, ...paramFilter };
  }
  if (paramData?.searchText) {
    filterData.searchText = paramData.searchText;
  }
  const { filterData: _, searchText: __, ...otherParams } = paramData;
  const encodedFilterData = encodeURIComponent(JSON.stringify(filterData));
  return this.httpClient.getRequest(
    `microservice/mail/recipientdetails?filterData=${encodedFilterData}`, 
    otherParams
  );
    // return this.httpClient.getRequest(`microservice/mail/recipientdetails?filterData=${filterData}`, paramData);
  }
  /**
   * Method which is used to get application list data.
   * @param { HttpParams } paramData It has the query params of the Url.
   * @type { Observable<GetApplicationList> }
   */
  getApplicationList(paramData: HttpParams): Observable<GetApplicationList> {
    return this.httpClient.getRequest('microservice/mail/application?', paramData);  
  }

  /**
   * Sends a delete request to remove a customer by their ID.
   * @param customerId - The ID of the customer to be deleted.
   * @returns An Observable of the delete customer detail response.
   */
  deleteCustomer(customerId : number , data: DynamicDataSource ): Observable<DeleteCustomerDetail> {
    return this.httpClient.deleteRequest(`microservice/mail/customer/${customerId}/delete`, {password : '12345'} );
  }

  /**
  * Method which is used to get single customer data.
  * @param { string } id
  * @type { Observable<SingleCustomer>}
  */
  getSingleCustomer(id: string): Observable<SingleCustomer> {
    let filterData = JSON.stringify({ roleId: 3 });
    return this.httpClient.getRequest('microservice/mail/recipientdetails/' + id + '/get?roleId=3');  
  }

  /**
  * Method which is used to create single customer data.
  * @param { SingleCustomerData } data
  * @type { Observable<SingleCustomerData>}
  */
  createCustomer(data: SingleCustomerData): Observable<SingleCustomerData>  {
    return this.httpClient.postRequest('microservice/mail/recipientdetails/create/customer', data);  
  }

  /**
  * Method which is used to update the customer data.
  * @param { SingleCustomerData } data
  * @param { string } id
  * @type { Observable<SingleCustomerData>}
  */
  updateCustomer(data: SingleCustomerData, id: string): Observable<SingleCustomerData> {
    return this.httpClient.putRequest(`microservice/mail/recipientdetails/${id}/update`, data);  
  }
}
