import { Pipe, PipeTransform } from '@angular/core';
/**
 * Pipe to determine whether a tooltip should be disabled based on the length of the input value.
 */
@Pipe({
  name: 'toolTipDisable'
})
export class ToolTipDisablePipe implements PipeTransform {
  /**
   * Transforms the input value into a boolean indicating whether the tooltip should be disabled.
   * @param value The input value (string).
   * @param count The maximum length of the value before the tooltip should be disabled (default is 25).
   * @returns A boolean indicating whether the tooltip should be disabled.
   */
  transform(value: string, count:number=25): boolean {
    return value?.length<count;
  }

}
