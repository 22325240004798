import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActionClicked, columnRef, ColumnType, DynamicMailDataSource, PaginationData, SearchSetting, SortSetting } from '../../models/common-mails-data.model';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { formatDate } from '@angular/common';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { FormControl } from '@angular/forms';
import { debounceTime, map, Subscription } from 'rxjs';
import * as _ from 'lodash';
import { DEFAULT_SELECTEDSORT } from '../../constants/common-mails-data.constants';

@Component({
  selector: 'app-common-mails-list',
  templateUrl: './common-mails-list.component.html',
  styleUrls: ['./common-mails-list.component.scss']
})
export class CommonMailsListComponent implements OnInit, OnChanges {
  /**
     * @type {Array<columnDef>}
     * Array of column definitions for the common card.
     */
  @Input() columns!: Array<columnRef>;
  /**
    * @type {ColumnType}
    * Enum for column types.
    */
  columnType = ColumnType;
  /**
 * @type {number}
 * Number of flexible columns in the common card.
 */
  flexibleColumnCount: number = 5;
  /**
   * Variable which us used to store the current display data for the page
   * @type { any }
   */
  displayData: any = null;
  /**
   * @type { boolean[] }
   * An array that tracks the checked state of individual checkboxes.
   */
  isSingleCheckboxChecked: boolean[] = []
  /**
  * @type {Array<DynamicDataSource>}
  * Array of data sources for the common card.
  */
  @Input() dataSource!: Array<DynamicMailDataSource>;

  /**
  * Variable(array) which is used to store the selected row data in for emitting.
  * @type { any }
  */
  selectedArray: any = [];
  /**
  * @type {string}
  * Date format used for displaying dates as 'dd MMM, yyyy'.
  */
  dateFormat: string = "dd MMM, yyyy";
  selectedCheckBoxIndices: number[] = [];
  /**
 * @type {PaginationData}
 * Pagination data for the common card.
 */
  @Input() paginationData!: PaginationData;
  /**
 * @type {boolean}
 * Flag indicating whether pagination is enabled.
 */
  @Input() hasPagination: boolean = false;
  /**
 * @type {EventEmitter<PaginationData>}
 * Event emitter for pagination changes.
 */
  @Output() paginationChange = new EventEmitter<PaginationData>();
  /**
 * @type {boolean}
 * Flag indicating whether the search field is displayed.
 */
  @Input() hasSearchField: boolean = true;
  /**
 * @type {SearchSetting}
 * Search setting for the common card.
 */
  @Input() searchSetting!: SearchSetting;
  /**
  * @type {FormControl}
  * Form control for the search query.
  */
  searchQuery = new FormControl<string>('');
  /**
 * @type {Subscription}
 * Subscription for managing subscriptions.
 */
  subscription: Subscription = new Subscription();
  /**
* @type {EventEmitter<string>}
* Event emitter for search changes.
*/
  @Output() searchChange = new EventEmitter<string>();
  /**
 * It allows the component to access an instance of MatPaginator from its template.
 */
  @ViewChild(MatPaginator) paginator!: MatPaginator;
//   /**
//  * @type {Array<TableAction>}
//  * Array of action settings for the common card.
//  */
//   @Input() actionSetting!: Array<TableAction>;
  /**
 * @type {EventEmitter<ActionClicked<DynamicDataSource>>}
 * Event emitter for action clicks.
 */
  @Output() actionClicked = new EventEmitter<ActionClicked<DynamicMailDataSource>>();
  onHover: boolean = false;
  /**
 * @type {boolean}
 * Flag indicating whether data is loading.
 */
  @Input() loading: boolean = false;
  /**
* @type {Array<SortSetting>}
* Array of sort settings for the common card.
*/
  @Input() sortSetting!: Array<SortSetting>;
  /**
   * @type {SortSetting}
   * Selected sort setting for the common card.
   */
  @Input() selectedSort: SortSetting = _.cloneDeep(DEFAULT_SELECTEDSORT);
  /**
 * @type {EventEmitter<SortSetting>}
 * Event emitter for sort changes.
 */
  @Output() sortChange = new EventEmitter<SortSetting>();
    /**
    * @type {EventEmitter<string>}
    * Event emitter for search changes.
  */
    @Output() compose = new EventEmitter<string>();
  /**
 * @type {EventEmitter<ActionClicked<DynamicDataSource>>}
 * Event emitter for action clicks.
 */
  @Output() iconsClicked = new EventEmitter<any>();
  /**
 * @type {EventEmitter<refreshedClicked<DynamicDataSource>>}
 * Event emitter for refresh icon clicks.
 */
  @Output() refreshedClicked = new EventEmitter<boolean>();
  tabs = [
    { "icon": "inbox", "field": "Primary" },
    { "icon": "inbox", "field": "Primary" },
    { "icon": "inbox", "field": "Primary" }
  ];
  selected = new FormControl(0);
  constructor(){
  }
  allData:DynamicMailDataSource[]=[];
    /**
* Angular life cycle hook that initiates the component
*/
  ngOnInit(): void {
    this.allData=this.dataSource;
    this.flexibleColumnCount = this.columns?.filter((column: columnRef) => {
      return !column?.columnWidth;
    }).length;
  
    if (this.hasSearchField) {
      this.searchQuery?.setValue(
        this.searchSetting && this.searchSetting?.searchText
          ? this.searchSetting?.searchText
          : ''
      );
  
      this.subscription?.add(
        this.searchQuery?.valueChanges.pipe(debounceTime(500), map((value: string | null) => {
          return value ?? '';
        }))
          .subscribe({
            next: (value: string) => {
              this.searchChange.emit(value);
              if (this.paginator?.pageIndex) {
                this.paginator.pageIndex = 0;
              }
              if (value != '') {
                this.onPageChange({ length: this.paginationData?.count, pageIndex: 0, pageSize: this.paginationData?.limit, previousPageIndex: 0 });
              }
              this.updateCheckboxStates();
            },
          })
      );
    }
  
    this.columns.forEach(column => {
      if (column.field === 'actions') {
        if (column.onHover === true || column.onHover === 'true')
          this.onHover = true;
      }
      else {
        this.onHover = false;
      }
    });
    this.isSingleCheckboxChecked = [];
    this.DisplayMailData();
  }
  /**
   * If the dataSource has data, it sets the displayData and checks for existence and calls setCheckboxExistMethod.
   */
  DisplayMailData(): void {
    if (this.dataSource && this.dataSource.length > 0) {
      this.displayData = this.dataSource;
      if (this.isSingleCheckboxChecked.length === 0) {
        this.displayData.forEach((listData: any) => {
          this.isSingleCheckboxChecked.push(
            this.selectedArray.some((item:any) => item.id === listData.id)
          );
        });
      } else {
        this.updateCheckboxStates();
      }
      this.setCheckboxExist();
    }
  }
  /**
  * Checks if each item in displayData exists in the selectedArray by matching either the id or customerId.
  * Sets the exist array at the corresponding index to true if a match is found, otherwise false. 
  */
  setCheckboxExist(): void {
    if (this.selectedArray && this.selectedArray.length > 0) {
      const selectedArray = this.selectedArray;
      this.displayData && this.displayData.forEach((listData: any, index: number) => {
        this.isSingleCheckboxChecked[index] = !!(selectedArray && selectedArray.find((selectedData: any) =>
          listData.id !== null && listData.id !== undefined ? listData.id === selectedData.id : listData.customerId === selectedData.customerId))
      })
      // if (this.isSingleCheckboxChecked && this.isSingleCheckboxChecked.every((isChecked: any) => isChecked === true)) {
      //   this.isMasterCheckboxChecked = true;
      // }
      // else {
      //   this.isMasterCheckboxChecked = false;
      // }
    }
  }
  // /**
  //  * If the checkbox is unchecked, it removes all items in displayData from the selectedArray.
  //  * If the checkbox is checked, it adds all items in displayData to the selectedArray.
  //  * @param { MatCheckboxChange } event - The checkbox change event.
  //  */
  // masterCheckbox(event: MatCheckboxChange) {
  //   if (event && !event.checked) {
  //     const displayData = this.displayData;
  //     displayData && displayData.forEach((listData: any) => {
  //       const index = this.selectedArray && this.selectedArray.findIndex((selectedData: any) =>
  //         selectedData.id ? selectedData.id === listData.id : selectedData.customerId == listData.customerId);
  //       if (index !== -1) {
  //         this.selectedArray && this.selectedArray.splice(index, 1);
  //       }
  //     });
  //     this.isMasterCheckboxChecked = false;
  //     this.displayData && this.displayData.forEach((listData: any, index: number) => {
  //       this.isSingleCheckboxChecked[index] = false;
  //     });
  //   }
  //   else {
  //     const displayData = this.displayData;
  //     displayData && displayData.forEach((listData: any) => {
  //       const index = this.selectedArray && this.selectedArray.findIndex((selectedData: any) =>
  //         selectedData.id ? selectedData.id === listData.id : selectedData.customerId == listData.customerId);
  //       if (index >= 0) {
  //         this.selectedArray && this.selectedArray.splice(index, 1);
  //       }
  //     })
  //     this.selectedArray.push(...displayData);
  //     // this.selection.select(...selectedData);
  //     // this.setCheckboxExist();
  //     this.isMasterCheckboxChecked = true;
  //     this.displayData.forEach((listData: any, index: number) => {
  //       this.isSingleCheckboxChecked[index] = true;
  //     });
  //   }
  // }
  /**
 * Handles the click event for a checkbox in a row.
 * @param { MatCheckboxChange } event - click event for checkbox click
 * @param data  - it passes the row data of clicked row
 */
  singleCheckBoxClick(event: MatCheckboxChange, eventData: any, itemIndex?: any): void {
    const itemId = eventData.id;
    if (event.checked) {
      if (!this.selectedCheckBoxIndices.includes(itemId)) {
        this.selectedCheckBoxIndices.push(itemId);
        this.selectedArray.push(eventData);
      }
    } else {
      const index = this.selectedCheckBoxIndices.indexOf(itemId);
      if (index !== -1) {
        this.selectedCheckBoxIndices.splice(index, 1);
        this.selectedArray.splice(index, 1);
      }
    }
    this.isSingleCheckboxChecked[itemIndex] = event.checked;
    this.setCheckboxExist();
  }
  updateCheckboxStates(): void {
    this.isSingleCheckboxChecked = this.displayData.map((data: any) => 
      this.selectedArray.some((selectedItem: any) => selectedItem.id === data.id)
    );
  }
  isSelected(itemId: number): boolean {
    return this.selectedCheckBoxIndices.includes(itemId);
  }
  /**
 * method is used to change date format
 */
  getFormattedDate(date: string): string {
    const today = new Date();
    const givenDate = new Date(date);
    const isToday = this.isSameDay(today, givenDate);
    const isYesterday = this.isSameDay(today, this.addDays(givenDate, 1));
    const isTomorrow = this.isSameDay(today, this.addDays(givenDate, -1));
    if (isToday) {
      return formatDate(givenDate, 'hh:mm a', 'en-US');
    } else if (isYesterday || isTomorrow) {
      return formatDate(givenDate, this.dateFormat, 'en-US');
    } else {
      return formatDate(givenDate, this.dateFormat, 'en-US');
    }
  }
  /**
 * method is used to given date is today it will show time only
 */
  isSameDay(date1: Date, date2: Date): boolean {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

  addDays(date: Date, days: number): Date {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  /**
 * @type {void}
 * method is is used to icons updates
 */
  onIconClickEvent(event: any, item: DynamicMailDataSource, column: columnRef) {
    if (item[column.field]) {
      item[column.field] = false;
    } else {
      item[column.field] = true;
    }
    this.iconsClicked.emit({id:item['id'], field: column.field, fieldValue: item[column.field] });
  }
  /**
 * @type {void}
 * Event handler for page changes.
 */
  onPageChange(event: PageEvent): void {
    this.paginationData.count = event?.length;
    this.paginationData.offset = event?.pageIndex * event.pageSize;
    this.paginationData.limit = event?.pageSize;
    this.paginationChange.emit(this.paginationData);
  }

  /**
 * @type {void}
 * Event handler for action clicks.
 */
  onAction(method: string, data: DynamicMailDataSource): void {
    this.actionClicked.emit({ method: method, data: data });
  }
  /**
* @type {void}
* Event handler for navigate the pae action clicks.
*/
  onClick(item: any) {
    this.actionClicked.emit({ method: 'onPageNavigate', data: item });
  }
  /**
 * @type {void}
 * Event handler for sort changes.
 */
  onSortChange(sort: SortSetting, index?: number): void {
    if (this.selectedSort && this.selectedSort.label === sort.label) {
      this.selectedSort.direction = 'asc';
      this.selectedSort.label = '';
      this.selectedSort.field = '';
      this.sortChange.emit(this.selectedSort);
    } else {
      this.selectedSort.label = sort?.label;
      this.selectedSort.field = sort?.field;
      this.selectedSort.direction = sort?.direction;
      this.selectedSort.color = sort?.color;
      this.sortChange.emit(this.selectedSort);
    }
    this.onPageChange({ length: this.paginationData?.count, pageIndex: 0, pageSize: this.paginationData?.limit, previousPageIndex: 0 });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes){
    this.DisplayMailData();
    }
  }
  /**
 * Method which is used to show if the content conatin any html format it will be show some particular text to lists
 */
  extractContent(content: string): string {
    if (!content) {
      return '';
    }
    const isHtmlContent = /<\/?[a-z][\s\S]*>/i.test(content);
    if (isHtmlContent) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(content, 'text/html');
      const extractedText = doc.querySelector('p')?.textContent?.trim() ||
                            doc.querySelector('h1')?.textContent?.trim() ||
                            doc.body.textContent?.trim() || '-';
      
      return extractedText;
    }
    return content.trim(); 
  }
/**
 * Method which is used to check width for overflow text types
 */
  parseWidth(width: string | undefined): number {
    return parseFloat(width || '0');
  }
  /**
 * Method which is used to emit refresh value
 */
  onRefreshedClicked(clicked:boolean){
    this.refreshedClicked.emit(clicked)
  }
}
