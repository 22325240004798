import { Injectable } from '@angular/core';
import { HttpRoutingService } from 'src/app/common/service/http-routing.service';
import { CategoryApplicationResponse, CategoryResponse, CategoryResponses, CreateResponse, DeleteResponse, GetOneCategoryResponse } from '../models/categories.model';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {
  /**
   * component constructor which is used to inject the required services and modules.
   * @param httpClient To refer to the httpClient module to access the get and put methods.
   */
  constructor(private httpClient: HttpRoutingService,
    private http: HttpClient
  ) { }
  /**
    * Method which is used to get categories list data.
    * @param datas It has the query params of the Url.
    * @type { Observable<string>}
    */
  getAllCategories(datas: { [datas: number]: string | string[] }): Observable<CategoryResponse> {
    return this.httpClient.getRequest('microservice/mail/category', datas);
  }
  /**
    * Method which is used to delete category data.
    * @type { Observable<string>}
    */
  // deleteCategories(deleteId: number, data: { isDeleted: boolean }): Observable<DeleteResponse> {
  //   return this.httpClient.putRequest('microservice/mail/category/' + deleteId + '/delete', data);
  // }
  private readonly APIUrl = environment.APIUrl;
  deleteCategories(deleteId: number, data: { isDeleted: boolean }): Observable<HttpResponse<any>> {
    const url = 'microservice/mail/category/' + deleteId + '/delete';
    const fullUrl = this.APIUrl + url?.replace(/#/g, "%23");
    return this.http.put<any>(fullUrl, data, {
        observe: 'response',
    });
}

  /**
    * Method which is used to create new Category.
    * @param data It has the query params of the Url.
    * @type { Observable<string>}
    */
  createCategories(data: CategoryResponses): Observable<CreateResponse> {
    return this.httpClient.postRequest('microservice/mail/category/create', data);
  }
  /**
    * Method which is used to get category details.
    * @param data It has the query params of the Url.
    * @type { Observable<string>}
    */
  getOneCategories(id: string): Observable<GetOneCategoryResponse> {
    return this.httpClient.getRequest('microservice/mail/category/' + id + '/get');
  }
  /**
   * Method which is used to get category details.
   * @param data It has the query params of the Url.
   * @type { Observable<string>}
   */
  // updateCategories(id: string, data: CategoryResponses): Observable<CreateResponse> {
  //   return this.httpClient.putRequest('microservice/mail/category/' + id + '/update', data);
  // }
  updateCategories(id: string, data: CategoryResponses): Observable<HttpResponse<CreateResponse>> {
    const url = 'microservice/mail/category/' + id + '/update';
    const fullUrl = this.APIUrl + url?.replace(/#/g, "%23");
    return this.http.put<CreateResponse>(fullUrl, data, {
      observe: 'response',
    });
  }
  /**
*Method which is used to get mail template Application list.
* @type { Observable<CategoryResponse>}
*/
  getApplication(): Observable<CategoryApplicationResponse> {
    return this.httpClient.getRequest("microservice/mail/application");
  }
}
