<div *ngIf="!initialLoader" class="common-header">
  <app-common-header [heading]="heading" ></app-common-header>
</div>
<div *ngIf="initialLoader" class="common-card-style">
  <app-skeleton-loader [formLoader]="false"></app-skeleton-loader>
</div>
<div fxLayout.lt-md="column" *ngIf="!initialLoader" class="common-card-style">
   <app-common-filter *ngIf="isFilter" [filterData]="filterDetails" [isFilterStatus]="isFilterValue"
    (emittedData)="emittedEvent($event)" (filterClose)="closeFilter()" (AppliedFilters)="appliedFiltersLength($event)">
   </app-common-filter>
   <div fxFlex="100%">
      <app-common-card 
      (optionalEventOccurred)="handleOptionalEvent($event)" 
      (searchChange)="handleSearchChange($event)"  
      (paginationChange)="handlePaginationChange($event)" 
      (primarySelectEmittedData)="primaryDropdownEvent($event)" 
      (actionClicked)="handleActionClicked($event)" 
      [dataNotFoundMessage]="noDataMessage" 
      [columns]="columns" 
      [dataSource]="data" 
      [actionSetting]="actions" 
      [hasSearchField]="true"
      [primaryFilterData]="primaryfilterDetails" 
      [hasPrimaryFilter]="!!(primaryfilterDetails?.options?.length)"
      [searchSetting]="searchSettingData" 
      [optionalEvent]="optionalEvent" 
      [loading]="reportListLoader" 
      [hasPagination]="true"  
      [paginationData]="paginationData"  
      [optionalEvent]="optionalEvent"
      [appliedFilters]="appliedFilters">
   </app-common-card>
   </div>
</div>
