import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SigninComponent } from './components/signin/signin.component';
import { SignupComponent } from './components/signup/signup.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { RegistorComponent } from './components/registor/registor.component';
import { SharedModule } from "../shared/shared.module";

@NgModule({
  declarations: [
    SigninComponent,
    SignupComponent,
    DashboardComponent,
    RegistorComponent
  ],
  imports: [
    CommonModule,
    SharedModule
],
  exports: [
    DashboardComponent
  ]
})
export class AuthModule { }
