import { Component, ElementRef, HostListener, NgZone, Renderer2, ViewChild } from '@angular/core';
import { AuthService } from '../../service/auth.service';
import { FormattedDateQuery, graphData, Overview, OverviewData ,totalCount } from '../../models/auth.model';
import { Router } from '@angular/router';
import { trigger, transition, style, animate, state, keyframes } from '@angular/animations';
import { ChartConfiguration, registerables} from 'chart.js';
import 'chartjs-adapter-date-fns';
import Chart from 'chart.js/auto';
import { DashboardService } from '../../service/dashboard.service';
import { debounceTime, fromEvent, Subscription, takeUntil } from 'rxjs';
import { formatDate } from '@angular/common';
import { CommonService } from 'src/app/shared/service/common.service';
import { dynamicRouteParams } from 'src/app/shared/models/common-card-data.model';
Chart.register(...registerables)
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  animations: [
    trigger('expandHeight', [
      transition(':enter', [
        style({ height: '0' }),
        animate('0.7s linear', style({ height: '*' })),
      ]),
    ]),
    trigger('fillAnimation', [
      state('initial', style({
        background: 'conic-gradient(#fff 360deg, transparent 360deg)'
      })),
      state('filled', style({
        background: 'conic-gradient(#fff 0deg, transparent 0deg);',
        zIndex : 0
      })),
      transition('initial => filled', [
        animate('0.7s ease-in-out', keyframes(generateKeyframes()))
      ]),
      transition(':leave', [
        animate('0.5s', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class DashboardComponent {
  @ViewChild('pieChartLabel') chartLabel!: ElementRef;
  @ViewChild('mouseTarget') chartElement!: ElementRef;
  label!: {title : string , value : string}[] ;
  currentLabel:{title : string , value : string} = {title : '' , value : ''};
  subscriptionObject: Subscription = new Subscription()
  mouseMoveSubscription: Subscription = new Subscription()
  myChart: Chart | undefined; // Define a class-level variable to store the chart instance
  /**
   * References the container element in the component's template.
   */
  @ViewChild('containerRef') containerRef!: ElementRef;
  /**
   * To flag to show or hidden the hr tag.
   * @type { boolean }
   */
  showSecondHr: boolean = true;
  /**
  * Store the dashboard card details.
  * @type {totalCount}
  */
  cardDetails!: totalCount;
  /**
  * Store the dashboard top card details.
  * @type {Array}
  */
  sections = [
    { path: 'logs', menuName: 'Logs', subMenuName: 'Logs', value: 'true', queryParam: 'isSuccess', backgroundColor: '#1177ff', color: 'white', title: 'Success Mails', titleFontSize: '20px', subTitle: 'Total', subTitleFontSize: '12px', img: '../../../../assets/mail1.png', count: 0, animatedCount: 0, isCount: true },
    { path: 'logs', menuName: 'Logs', subMenuName: 'Logs', value: 'false', queryParam: 'isSuccess', backgroundColor: '#ff9c42', color: 'white', title: 'Failed Mails', titleFontSize: '20px', subTitle: 'Total', subTitleFontSize: '12px', img: '../../../../assets/mail2.png', count: 0, animatedCount: 0, isCount: true },
    { path: 'logs', menuName: 'Logs', subMenuName: 'Logs', queryParam: 'subscription', backgroundColor: '#ffd542', color: 'black', title: 'Subscription', titleFontSize: '20px', subTitle: 'BASIC', subTitleFontSize: '25px', img: '../../../../assets/plan.png', count: 0, animatedCount: 0, isCount: false }
  ];
  profileData = [
    { name: "Applications", menuName: "Applications", count: 0, path: 'application', subMenuName: 'Applications' },
    { name: "Configurations", menuName: "Settings", count: 0, path: 'configuration', subMenuName: 'Configurations' },
    { name: "Languages", menuName: "Settings", count: 0, path: 'languages', subMenuName: 'Languages' }
  ]

  graphNavData = { path: 'logs', menuName: 'Logs', subMenuName: 'Logs'}

  /** 
   * Stores data related to customers and templates.
   */
  activeData = [
    { menuName: 'Customers', subMenuName: 'Customers', path: 'customers', title: 'Active Customers', fill: '#C6D5FC', total: 0, active: 0, queryParam: 'isActive' },
    { menuName: 'Templates', subMenuName: 'Templates', path: 'templates', title: 'Active Templates', fill: '#F9CBA1', total: 0, active: 0, queryParam: 'isActive' }
  ]

  /** 
   * Holds the calculated percentage of active data..
   * @type { string[] }
   */
  activePercent: string[] = ['0%', '0%'];

  /** 
   * Holds the calculated percentage of active templates as a string.
   * @type { string }
   */
  templateActivePercent: string = '';

  /**
   * Boolean to track if the screen size is large.
   * @type { boolean }
   */
  isScreenLarge: boolean = true;

  /** Time range options for data filtering. 
   * @type {string[]}
  */
  durationOptions:Overview[] = [
    {data : 'Last week' , value : 'lastSevenDays' , filterValue : 'day'},
    {data : 'Last month' , value : 'lastMonth' , filterValue : 'week'},
    {data : 'Last six months' , value : 'lastSixMonths' , filterValue : 'month'},
    {data : 'Last year' , value : 'lastYear' , filterValue : 'month'}
  ];
  
  /** Mail status options for data filtering. 
   * @type {string[]}
  */
  mailFilterOptions:Overview[] = [{data : 'Sent' , value : 'send'},{data : 'Delivered' , value : 'delivered'},{data : 'Opened' , value : 'opened'},{data : 'Clicked' , value : 'clicked'}];
 
  /** Counts of sent and filed mails. */
  mailValues = {mailsSent: this.sections[0].count, mailsFiled: this.sections[1].count};
  
  /** Current percentage of mails to display in chart. 
    * @type {number} 
  */
  mailsPercentage: number = 0;
  
  /** Currently selected time range filter. 
    * @type {string} 
  */
  durationFilterSelection: OverviewData = {duration : 'lastSevenDays' , event : 'sent' , filterValue : 'day'};
  
  /** Currently selected mail status filter. 
    * @type {string} 
  */
  mailFilterSelection: Overview = {data : 'sent' , value : 'Send'};
  
  /** Controls chart element layering for animations. 
    * @type {number}
  */
  zIndex = 1;

  /**
    * Store the animation state of the pie chart.
    * @type {string}
  */
  state:string = 'initial';

  graphData!:graphData[];

  userData: any;
  /**
    * constructor for injecting needed services
    * @param AuthService used to access methods from Auth service
    * @param router - An instance of the Angular Router, used to navigate between different routes in the application.
  */
  constructor(public authservice: AuthService,
    private router: Router,
    private ngZone: NgZone,
    private renderer: Renderer2,
    private dashboardService : DashboardService,
    private commonService: CommonService) { }

  /**
   * component onInit life cycle hook.
  */
  ngOnInit() {
    this.authservice.user$.subscribe((res: any) => {
      this.userData = res;
    })

    this.subscriptionObject.add(this.authservice.region$.subscribe({
      next: (region) => {
        if (region) {
          this.subscriptionObject.add(this.authservice.getDashboardDetails().subscribe((res: any) => {
            if (res) {
              this.cardDetails = res?.totalCount;
              this.sections[0].count = this.cardDetails?.successLogsCount;
              this.sections[1].count = this.cardDetails?.failedLogsCount;
              this.profileData[0].count = this.cardDetails?.applicationCount;
              this.profileData[1].count = this.cardDetails?.configurationCount;
              this.profileData[2].count = this.cardDetails?.languageCount;
              this.activeData[0].active = this.cardDetails?.activeCustomerCount;
              this.activeData[1].active = this.cardDetails?.activeTemplateCount;
              this.activeData[0].total = this.cardDetails?.totalCustomerCount;
              this.activeData[1].total = this.cardDetails?.totalTemplateCount;
              this.mailValues = {mailsSent : this.cardDetails?.successLogsCount, mailsFiled : this.cardDetails?.failedLogsCount}
              this.mailsPercentage = this.mailValues.mailsSent;
              this.label = [{title : 'Total Mails sent' , value : ''+this.cardDetails?.successLogsCount},{title : 'Mails failed' , value : ''+this.cardDetails?.failedLogsCount}]
              this.animateCounts();
              this.getOverViewData();
              setTimeout(() => {
                this.state = 'filled';
                if(this.chartElement?.nativeElement){
                this.debounceMouce();
                }
              }, 0);
               this.onResize();
               this.activePercent = this.activeData.map(data =>
                 this.calculatePercent(data.active, data.total)
               );
            }
          }))
        }
      }
    }))
  }

  ngAfterViewInit() {
    setTimeout(() => {
    const container = this.containerRef?.nativeElement;
    if(container) {
      const resizeObserver = new ResizeObserver((entries) => {
        const entry = entries[0];
        const width = entry.contentRect.width;
        this.showSecondHr = width > 245;
      });

    resizeObserver.observe(container);
    }
  },0)

  }

  debounceMouce() {
    this.ngZone.runOutsideAngular(() => {
      this.mouseMoveSubscription.add(fromEvent<MouseEvent>(this.chartElement.nativeElement, 'mousemove')
        .pipe(
          debounceTime(0),
        )
        .subscribe((event: MouseEvent) => {
          this.ngZone.run(() => {
            this.onMouseMove(event);
          });
        }));

        this.mouseMoveSubscription.add(fromEvent<MouseEvent>(this.chartElement.nativeElement, 'mouseleave')
        .pipe(
          debounceTime(0),
        )
        .subscribe((event: MouseEvent) => {
          this.ngZone.run(() => {
            this.renderer.setStyle(this.chartLabel.nativeElement, 'visibility', 'hidden');
          });
        }));
    });

  }


  /**
 * Calculates the percentage of active items out of the total count.
 * @param { number } active - The number of active items.
 * @param { number } total - The total number of items.
 * @returns { string } A string representing the percentage, rounded to two decimal places
 */
  calculatePercent(active: number, total: number): string {
    return total ? ((active / total) * 100).toFixed(2) + '%' : '0%';
  }

  /**
    * Method which is used to animate the success and failure mails count.
   */
  animateCounts() {
    this.sections.forEach(section => {
      const total = section?.count;
      if (total === 0) {
        section.animatedCount = 0;
        return;
      }
      if (total > 0) {
        const step = Math.ceil(total / 100);
        const quo = total / step;
        const rem = total % step;
        let counter = 1;
        section.animatedCount = 0;
        const update = () => {
          if (counter <= quo) {
            section.animatedCount += step;
            counter++;
            setTimeout(update, 20);
          } else {
            section.animatedCount += rem;
          }
        };
        update();
      }
    });
  }

  /**
    * Method which is used to a given number into a more readable format by converting large numbers into abbreviations like 'K' for thousands and 'M' for millions .
   */
  formatCount(value: number): string {
    if (value >= 0 && value <= 99) {
      return value.toString().padStart(3, '0');
    } else if (value >= 1000000) {
      return (value / 1000000).toFixed(1) + 'M';
    } else if (value >= 1000) {
      return (value / 1000).toFixed(1) + 'K';
    } else {
      return value.toString();
    }
  }

  @HostListener('window:resize', ['$event'])

  /**
   * Updates the `isScreenLarge` property based on the window's current width.
   * @param event
   */
  onResize() {
    this.isScreenLarge = window.innerWidth >= 960;
  }

  /**
   * Navigates to a specified path within the mail section of the app.
   * @param { string } path 
   * @param { string } menuName 
   * @param { string } subMenu 
   * @param { string } queryParam 
   * @param { number } count 
   * @param { value } value 
   */
  navigation(path: string, menuName: string, subMenu: string, queryParam?: string, count?: number, value?: string) {
    this.menuBarChanges(menuName, subMenu);
    if(count && count > 0 && queryParam == 'isActive') { 
      this.router.navigate(["app/mail/", path], {
        queryParams: { active: true}
      });
    }
    else if(count && count > 0 && path == 'logs') {
      let queryParams;
      if(queryParam == 'customDateRange') {
        const formattedDate = this.setQueryDate(this.durationFilterSelection.duration);
        queryParams = {
          date_range: JSON.stringify({
            begin: formattedDate?.startDate,
            end: formattedDate?.endDate
          })
        }
      }
      else if(count && count > 0 && queryParam == 'isSuccess') {  
        queryParams = { success: value }
      }
      this.router.navigate(['app/mail/', path], { queryParams })

    }
    else {
      this.router.navigate(["app/mail/", path])
    }
  }

  /**
   * Updates the menu and submenu selection states based on provided menu and submenu names.
   * @param { string } menuName 
   * @param { string } subMenu 
   */
  menuBarChanges(menuName: string, subMenu: string) {
    this.subscriptionObject.add(this.commonService?.menus.subscribe(res => {
      res.menu.forEach((menu: any) => {
        if(menu.pageCustomTitle == menuName) {
          menu.isSelected = true;
          this.commonService.setMenuId(menu.id)
          this.commonService.setPreviousMenuId(menu.id)
        }
        else menu.isSelected = false;

        menu?.subMenu?.forEach((submenu: any) => {
          if(submenu.pageCustomTitle == subMenu) {
            submenu.isSelected = true;
            this.commonService.setShowSubmenu(true);
          }
          else submenu.isSelected = false
        })
      })
    }))
  }

  /**
   * Sets the start and end dates for a given duration and formats them in 'yyyy-MM-dd' format.
   * @param { string } duration 
   * @returns { FormattedDateQuery }
   */
  setQueryDate(duration: string): FormattedDateQuery {
    const today = new Date();
    const startDate = new Date();
    const dateOffsets: any = {
      lastSevenDays: 7,
      lastMonth: 30,
      lastSixMonths: 180,
      lastYear: 365
    };
    const offsetDays = dateOffsets[duration] || 0;
    startDate.setDate(today.getDate() - offsetDays);
    const formattedStartDate = formatDate(startDate, 'yyyy-MM-dd', 'en-us');
    const formattedEndDate = formatDate(today, 'yyyy-MM-dd', 'en-us');
    return { startDate: formattedStartDate, endDate: formattedEndDate }
  }

  /**
   * Renders a line chart showing daily mail sent counts.
   * 
   * configures chart options, and uses Chart.js
   * to create a responsive line chart with custom styling and
   * tooltips.
   */
  renderChart():void {
    const chartData: graphData[] = this.graphData;
    const shortLabels = this.formatChartData(this.durationFilterSelection);
    const data: ChartConfiguration<'line'>['data'] = {
      labels: shortLabels, // Use short labels for the x-axis
      datasets: [
        {
          label: 'Total mails sent',
          data: chartData.map((item: graphData) => item.count), // Use the values from JSON
          borderColor: '#2B5BFC',
          fill: false,
          cubicInterpolationMode: 'monotone' as const,
          tension: 0.8
        }
      ]
    };
    const config: ChartConfiguration<'line'> = {
      type: 'line',
      data: data,
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              title: (context) => {
                const index = context[0].dataIndex;
                return chartData[index].day || chartData[index].month || chartData[index].week;
              }
            }
          },
        },
        scales: {
          x: {
            title: {
              display: false,
            },
            grid: {
              display: false,
            }
          },
          y: {
            border : {
              display : false
            },
            ticks: {
              count: 5,
            },
            title: {
              display: false,
            }
          }
        }
      },
    };
    this.myChart= new Chart("myChart", config);
  }
  
  /**
   * Formats chart data labels based on the selected time interval.
   * @param value - Object containing filter value for time interval
   * @returns Array of formatted labels for chart x-axis
   */
  formatChartData(value: OverviewData) {
    switch (value.filterValue.toLowerCase()) {
    case 'day':
      return this.graphData.map(data => data['day']?.substring(0, 3));
    case 'month':
      return this.graphData.map(data => {
        const month = data['month'];
        return `${month?.substring(0, 3)} ${month?.substring(month.length - 2)}`;
      });
    case 'week':
      return this.graphData.map(data => `week${data['week']}`);
    default:
      return ['Invalid selection'];
    }
  }

  /**
   * Tracks mouse position relative to a circular chart and updates hover state.
   * 
   * Calculates the hover angle from the chart's center, determines which
   * section (mails sent or filed) is being hovered, and updates component
   * properties accordingly. Used for interactive feedback on the chart.
   * 
   * @param event - Mouse event object
   */
  onMouseMove(event:MouseEvent):void {
    // Get element details and center coordinates
    const chartElement = event.target as HTMLElement;
    const rect = chartElement.getBoundingClientRect();
    const centerX = rect.left + rect.width / 2;
    const centerY = rect.top + rect.height / 2;
    // Calculate hover angle relative to center
    const hoverAngle = ((Math.atan2(event.clientY - centerY, event.clientX - centerX) * (180 / Math.PI) + 180) - 90 + 360) % 360;
    
    // Calculate proportional angles for colored sections
    const mailsSentAngle = (this.mailValues.mailsSent / (this.mailValues.mailsSent + this.mailValues.mailsFiled)) * 360 ;
    const mailsFiledAngle = (this.mailValues.mailsFiled / (this.mailValues.mailsSent + this.mailValues.mailsFiled)) * 360 ;
   
    //place the lable element on where the users pointer is at
    this.renderer.setStyle(this.chartLabel.nativeElement, 'top', `${event.clientY - (rect.top+this.chartLabel.nativeElement.offsetHeight)}px`);
    this.renderer.setStyle(this.chartLabel.nativeElement, 'left', `${event.clientX - (rect.left+this.chartLabel.nativeElement.offsetHeight)}px`);
    this.renderer.setStyle(this.chartLabel.nativeElement, 'visibility', 'visible');

    // Determine hover area based on angle
    if (hoverAngle >= mailsSentAngle && hoverAngle < mailsSentAngle + mailsFiledAngle) {
      this.mailsPercentage = this.mailValues.mailsFiled;
      this.zIndex = 2;
      this.currentLabel = this.label[1];
    } else {
      this.mailsPercentage = this.mailValues.mailsSent;
      this.zIndex = 0;
      this.currentLabel = this.label[0];
    }
  }

  /**
   * Fetches overview data and updates the chart.
   * @returns Observable from the HTTP request
   */
  getOverViewData() {
  return this.mouseMoveSubscription.add(this.dashboardService.getOverviewDetails(this.durationFilterSelection).subscribe({
    next: (res: any) => {
      // Destroy existing chart to prevent memory leaks
      this.myChart?.destroy();
      this.graphData = this.durationFilterSelection.filterValue === 'day' 
        ? res.report 
        : res.report.slice(1);
      // Re-render the chart with new data
      this.renderChart();
    }
  }));
  }
  calculateMailDataValue(){
    return( parseFloat((this.mailsPercentage / (this.mailValues.mailsSent + this.mailValues.mailsFiled)).toFixed(2)) * 100)
  }

  ngOnDestroy(){
    this.myChart?.destroy();
    this.mouseMoveSubscription?.unsubscribe();
    this.subscriptionObject?.unsubscribe();
  }
}

/**
 * Generates keyframes for a circular filling animation.
 * 
 * @returns An array of animation style metadata for a 360-degree rotation.
 * Each keyframe adjusts the conic gradient angle.
 */
function generateKeyframes(): import("@angular/animations").AnimationStyleMetadata[] {
  const totalFrames = 360;
  const keyframesArray = [];
  
  for (let i = 0; i <= totalFrames; i++) {
    const angle = 360 - i;  // Reverse the angle calculation
    const offset = i / totalFrames;
    if(i >= 360){
      keyframesArray.push(
        style({ 
          background: `conic-gradient(#fff ${angle}deg, transparent ${angle}deg)`, 
          offset: offset,
          zIndex: 0 
        })
      );
    }
    else{
    keyframesArray.push(
      style({ 
        background: `conic-gradient(#fff ${angle}deg, transparent ${angle}deg)`, 
        offset: offset,
        zIndex: 3 
      })
    );
  }
  }
  return keyframesArray;
}

