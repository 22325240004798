import { Component } from '@angular/core';
import { TemplateListComponent } from 'libs/shared/src/lib/components/template-list/template-list.component';

@Component({
  selector: 'app-mail-templates-list',
  templateUrl: './mail-templates-list.component.html',
  standalone: true,
  imports: [TemplateListComponent],
  styleUrls: ['./mail-templates-list.component.scss']
})
export class MailTemplatesListComponent {

}
