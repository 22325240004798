import { Component, OnDestroy, OnInit } from '@angular/core';
import { CategoryResponse, Category, CategoryParamData, ConfirmationMsg, CreateResponse, CategoryApplicationResponse, CategoriesDialogMessages, DeleteResponse } from 'src/app/zen-mail/Categories/models/categories.model';
import { Subscription } from 'rxjs';
import { ActionClicked, DynamicDataSource, NoRecordFound, PaginationData, SearchSetting, SelectOptions, SortSetting, TableAction, columnDef } from 'src/app/shared/models/common-card-data.model';
import { ColumnType, NO_RECORD_FOUND_MESSAGES } from 'src/app/shared/constants/common-card-data.constants';
import { AuthService } from 'src/app/auth/service/auth.service';
import { CategoriesService } from '../../services/categories.service';
import { CommonDialogService } from 'src/app/shared/service/common-dialog.service';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/shared/service/common.service';
import { CommonSnackbarService } from 'src/app/shared/service/common-snackbar.service';
import { FilterData } from 'src/app/shared/models/common-filter.model';
import { FilterType } from 'src/app/shared/constants/common-filter-data.contants';
import { NullFilter } from 'libs/shared/src/lib/models/template.model';
import { ButtonInfo, Heading } from 'src/app/shared/constants/common-header.constants';
import { CATEGORIES_MESSAGES } from 'src/app/zen-mail/Categories/constant/categories.contants';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-categories-list',
  templateUrl: './categories-list.component.html',
  standalone:true,
  imports:[SharedModule,CommonModule],
  styleUrls: ['./categories-list.component.scss']
})
export class CategoriesListComponent implements OnInit, OnDestroy {
  /**
   * Variable used to store the table data.
   * @type {Category[]}
   */
  data!: Category[];
  /**
 * Variable used to store the searching text.
 * @type {string}
 */
  searchData: string = '';
  /**
* Flag Variable used to indicate whether enable loader or not.
* @type {boolean}
*/
  categoryListLoader: boolean = true;
  /**
* Variable used to store the observables.
* @type {Subscription}
*/
  SubscriptionObject: Subscription = new Subscription();
  /**
* Variable used to store the table Pagination data.
* @type {PaginationData}
*/
  paginationData: PaginationData = {
    limit: 5,
    count: 0,
    offset: 0
  }
  /**
* Variable used to store the table data length.
* @type {number}
*/
  responseCount!: number;
  /**
* @type {CategoriesDialogMessages}
* Assigning the constant categoriesMessages to the Categories Messages variable.
*/
  categoriesMessages: CategoriesDialogMessages = CATEGORIES_MESSAGES;
  /**
* Variable used to store the table column definition.
* @type {Array<columnDef>}
*/
  columns: Array<columnDef> = [
    { field: '', header: '', type: ColumnType.Checkbox, columnWidth: '10%', columnAlign: 'start' },
    { field: 'categoryName', header: 'Category Name', type: ColumnType.Text, removeUnderscore: true, columnAlign: 'start' },
    { field: 'categoryCode', header: 'Category Code', type: ColumnType.Text, columnAlign: 'start' },
    { field: 'createdAt', header: 'Created On', type: ColumnType.Date, format: "dd MMM, yyyy" },
    { field: 'actions', header: 'Actions', type: ColumnType.Action, columnWidth: '15%' },
  ];
  /**
* Variable used to store the table actions data.
* @type {Array<TableAction>}
*/
  actions: Array<TableAction> = [
    { icon: 'visibility', tooltip: 'View Category', method: 'onView', color: 'rgb(0, 104, 181)' },
    { icon: 'edit', tooltip: 'Edit Category', method: 'onEdit', color: '#00b907' },
    { icon: 'delete', tooltip: 'Delete category', method: 'onDelete', color: 'red' }
  ];
  /**
* Variable used to store search settings data.
* @type {SearchSetting}
*/
  searchSettingData: SearchSetting = {
    searchText: '',
    placeHolder: 'Category Name',
    appearance: 'outline'
  }
  /**
   * Message to display when no data is available.
   * @type {NoRecordFound}
   */
  noDataMessage: NoRecordFound = {
    noRecordFound: "No records found",
    noRecordFoundDescription: "No records found in the description."
  };
  /**
  * Variable used to store the application of the mail to perform primaty filter.
  * @type {FilterData}
  */
  primaryfilterDetails!: FilterData;
  /**
* Variable used to store the categories of the mail to perform filter.
* @type {SelectOptions[]}
*/
  filterApplication: SelectOptions[] = [];
  filterValue!: SelectOptions;
    /**
   * Variable has button data to be displayed on commen header.
   * @type {Heading}
   */
    button:ButtonInfo[] = [{
      class : 'primary-button',
      color : '#ffffff',    
      name : 'Create',
      method : 'onCreateCategory',
      disabled : false,
      iconClass : 'outline',
      iconToolTip : 'btn'
    }]
  /**
   * Variable has header data to be displayed on commen header.
   * @type {Heading}
   */
  heading:Heading = {
    title : "Categories",
    description : 'Manage template categories with options to create, edit, view, and delete efficiently.',
    fontWeight : '500'
  }
    /**
  * Flag Variable used to initial loader.
  * @type {boolean}
  */
    initialLoader:boolean=false;

    /**
    * Variable used to store sorting setting.
    * @type {SortSetting}
    */
    sorting: Array<SortSetting> = [
      { label: 'Category name A-Z', field: 'categoryName', direction: 'asc' },
      { label: 'Category name Z-A', field: 'categoryName', direction: 'desc' },
      { label: 'Category code A-Z', field: 'categoryCode', direction: 'asc' },
      { label: 'Category code Z-A', field: 'categoryCode', direction: 'desc' },
      { label: 'Recently created first', field: 'createdAt', direction: 'desc' },
      { label: 'Recently created last', field: 'createdAt', direction: 'asc' },
    ]
    /**
    * Variable used to store default sorting setting.
    * @type {SortSetting}
    */
    sortingOrder : SortSetting = { label: 'Category name A-Z', field: 'categoryName', direction: 'asc' };

  /**
   * component constructor which is used to inject the required services.
   * @param categories To refer to the CategoriesService to access the mail categories functions.
   * @param auth To refer to the AuthService to access the region subscription.
   */
  constructor(
    private categoriesService: CategoriesService,
    private authService: AuthService,
    private dialogService: CommonDialogService,
    private route: Router,
    private commonService: CommonService,
    private snackbar: CommonSnackbarService,) {
      this.noDataMessage.noRecordFound = NO_RECORD_FOUND_MESSAGES?.NO_RECORD_CATEGORIES;
      this.noDataMessage.noRecordFoundDescription = NO_RECORD_FOUND_MESSAGES?.NO_RECORD_CATEGORIES_DESCRIPTION; 
    }
  /**
   * Angular life cycle hooks
   */
  ngOnInit(): void {
    this.initialLoader=false;
    this.SubscriptionObject.add(this.authService.region$
      .subscribe(() => {
        this.categoryListLoader = true;
        this.SubscriptionObject.add(this.categoriesService.getApplication().subscribe({
          next: (templateApplicationResponse: CategoryApplicationResponse) => {
            this.initialLoader = true;
            this.filterApplication = templateApplicationResponse.applicationData.rows.map((application) => ({
              id: application.id,
              name: application.applicationName
            }));
            if (this.filterApplication.length) {
              this.primaryfilterDetails = { title: 'Choose Application', type: FilterType.Select, options: this.filterApplication, field: 'applicationId', displayName: 'name', valueName: 'id' };
            }
            // if(this.filterApplication.length == 0){
            this.filterValue = { 'applicationId':this.filterApplication.length? this.filterApplication[0]['id']:0 }
            const data = {
              offset: 0,
              limit: 5,
              filterData: JSON.stringify(this.filterValue),
              order : JSON.stringify([['categoryName', 'ASC']])
            }
            this.getAllCategories(data);
          // }
          }, error: (err) => {
            this.snackbar.OpenSnackBar({
              message: this.categoriesMessages?.wrongInApplication,
              heading: 'Failed',
              actionType: 'failure',
              duration:2
            });
            this.categoryListLoader = false;
          }
        }))
      }));
  }
  /**
 * To get all categories details list.
 * @param data To store data.
 */
  getAllCategories(data: CategoryParamData) {
    const datas = {
      offset: data.offset,
      limit: data.limit,
      searchText: data.searchData ?? '',
      filterData: data.filterData ?? '',
      order: data.order || ''
    }
    this.SubscriptionObject.add(this.categoriesService.getAllCategories(datas).subscribe({
      next: (response: CategoryResponse) => {
        this.data = response?.category?.rows;
        this.responseCount = response?.category?.count;
        this.paginationData.count = this.responseCount;
        this.initialLoader=false;
        this.categoryListLoader = false;
      },
      error: (err) => {
        this.data = [];
        this.paginationData.count = 0;
        this.initialLoader = false;
        this.categoryListLoader = false;
        this.snackbar.OpenSnackBar({
          message: this.categoriesMessages?.wrongInCategory,
          heading: 'Failed',
          actionType: 'failure',
          duration:2
        });
      }
    }));
  }
  /**
  * Method which is used for table Pagination.
  * @param event It has the Pagination value which is emitted.
  */
  handlePaginationChange(event: PaginationData): void {
    const data = {
      offset: event.offset,
      limit: event.limit,
      searchData: this.searchData ?? '',
      filterData: JSON.stringify(this.filterValue) ?? '',
      order: JSON.stringify([[this.sortingOrder?.field, this.sortingOrder?.direction]])
    }
    this.paginationData.limit = event.limit;
    this.paginationData.count = event.count;
    this.paginationData.offset = event.offset;
    this.categoryListLoader=true;
    this.getAllCategories(data);
  }
  /**
  * Method which is used for searching the table.
  * @param event It has the searched string which is emitted.
  */
  handleSearchChange(event: string): void {
    this.searchData = event;
    let data;
    if (event) {
      data = {
        searchData: event,
        offset: this.paginationData.offset,
        limit: this.paginationData.limit,
        filterData: JSON.stringify(this.filterValue) ?? '',
        order: JSON.stringify([[this.sortingOrder?.field, this.sortingOrder?.direction]])
      }
      this.categoryListLoader = true;
      this.getAllCategories(data);
    }
    else {
      data = {
        offset: 0,
        limit: 5,
        searchData: event,
        filterData: JSON.stringify(this.filterValue) ?? '',
        order: JSON.stringify([[this.sortingOrder?.field, this.sortingOrder?.direction]])
      }
      this.categoryListLoader = true;
      this.getAllCategories(data);
    }
  }
  /**
  * Method which is used to perform action of categories page.
  * @param event It has the id of the selected category.
  */
  handleActionClicked(event: ActionClicked<DynamicDataSource>): void {
    if (event?.method === 'onDelete') {
      const data = {
        isDeleted: true
      }
      const msgType = {
        header: 'confirmation',
        message: this.categoriesMessages?.deleteCategory,
        actionType: 'confirmation',
        button: { right: 'Yes, Delete', left: 'No' },
      }
      this.dialogMsg(msgType).subscribe((dialogResult: boolean) => {
        if (dialogResult) {
          this.SubscriptionObject.add(this.categoriesService.deleteCategories(event.data['id'], data).subscribe({
            next: (deleteResponse: HttpResponse<any>) => {
              if (deleteResponse) {
                console.log("delete....",deleteResponse)
                this.snackbar.OpenSnackBar({
                  message: deleteResponse?.status===204 ? this.categoriesMessages?.deleteCategorySuccess :deleteResponse?.status===200? this.categoriesMessages?.deleteCategoryFailed:this.categoriesMessages?.wrongInDeleteCategory,
                  heading: deleteResponse?.status===204 ? 'Success' : 'Failed',
                  actionType: deleteResponse?.status===204 ? 'success' : 'failure',
                  duration:2
                });
                const data = {
                  offset: 0,
                  limit: 5,
                  filterData: JSON.stringify(this.filterValue) ?? '',
                  order: JSON.stringify([[this.sortingOrder?.field, this.sortingOrder?.direction]])
                }
                this.categoryListLoader = true;
                this.getAllCategories(data);
              }
            }, error: (err) => {
              this.categoryListLoader=false;
              this.snackbar.OpenSnackBar({
                message: this.categoriesMessages?.wrongInDeleteCategory,
                heading: 'Failed',
                actionType: 'failure',
                duration:2
              });
            }
          }))
        }
      })
    }
    if (event.method === 'onView' || event.method === 'onEdit') {
      this.route.navigate(['app/mail/addeditcategories', event.method === 'onView' ? 'view' : 'edit', this.commonService.setParamsObj(event.data['id'])]);
    }
  }
  /**
 * Method which is used to show dialog based on actiontype.
 * @param msgType It has the delete category dialog information.
 */
  dialogMsg(msgType: ConfirmationMsg) {
    return this.dialogService.openDialog({
      header: msgType.header ?? '',
      message: msgType.message,
      actionType: msgType.actionType,
      button: { right: msgType.button?.right ?? '', left: msgType.button?.left ?? '' },
      disableClose: true
    });
  }
  /**
* This method removes any null or undefined objects in the filterValue.
* @param { NullFilter } filterValue 
* @returns { NullFilter }
*/
  removeNullValues(filterValue: NullFilter): NullFilter {
    return Object.keys(filterValue)
      .filter((key: string) => filterValue[key] !== null && filterValue[key] !== undefined)
      .reduce((acc: NullFilter, key: string) => {
        if (typeof filterValue[key] === 'object' && !Array.isArray(filterValue[key])) {
          const cleanedValue = this.removeNullValues(filterValue[key]);
          if (Object.keys(cleanedValue).length > 0) {
            acc[key] = cleanedValue;
          }
        } else {
          acc[key] = filterValue[key];
        }
        return acc;
      }, {});
  }
  /**
* Method which is used to show primaryDropdownEvent.
*/
  primaryDropdownEvent(applicaionId: SelectOptions) {
    this.filterValue = this.removeNullValues(Object.keys(applicaionId).length === 0 && applicaionId.constructor === Object?{'applicationId':0}:applicaionId);
    const data = {
      offset: 0,
      limit: 5,
      searchText: '',
      filterData: JSON.stringify(this.filterValue),
      order: JSON.stringify([[this.sortingOrder?.field, this.sortingOrder?.direction]])
    }
    this.categoryListLoader=true;
    this.getAllCategories(data)
  }
  onCreateCategory() {
    this.route.navigate(['app/mail/addeditcategories' , 'create'])
  }
  headerEvent(evnt : any){
    (this as DynamicDataSource)[evnt]();
  }

  /**
  * Method which used to handle sorting operation.
  * @param event contains souring event 
  */
  sortData(event : SortSetting){
    this.categoryListLoader = true;
    this.sortingOrder = event;
    const data = {
      offset: this.paginationData.offset,
      limit: this.paginationData.limit,
      searchData: this.searchData ?? '',
      filterData: JSON.stringify(this.filterValue) ?? '',
      order: JSON.stringify([[this.sortingOrder.field, this.sortingOrder.direction]])
    }
    this.categoryListLoader=true;
    this.getAllCategories(data);
  }

  /**
  * method ngOnDestroy used to unsubscribe the subscribtion .
  * @type {void}
  */
  ngOnDestroy(): void {
    if (this.SubscriptionObject) {
      this.SubscriptionObject.unsubscribe();
    }
  }
}
