<!-- <div class="dashboard-container" fxLayout="column">
  <div class="dashboard-banner" fxLayout="row">
    <div fxFlex="50%" fxFlex.lt-sm="100%" fxFlex.lt-md="40%" fxLayout="column" fxLayoutAlign="center center" class="banner-text-section">
      <h5 data-control="dashboard-banner-text" class="dashboard-banner-text">
        Welcome To ZenMail
      </h5>
      <p data-control="dashboard-banner-subtext" class="dashboard-banner-subtext">
        A Personalized Email Service Designed Just for You!
      </p>
      <button mat-button data-control="banner-button" class="banner-button">Try Zen Mail</button>
    </div>
    <div class="banner-image-section" fxFlex="50%" fxFlex.lt-md="60%" fxHide.lt-sm fxLayoutAlign="center center">
      <img class="banner-image" data-control="banner-image" src="../../../../assets/banner.png" >
    </div>
   </div>
  <div *ngIf="cardDetails" class="dashboard-card" fxLayout="row wrap" fxFlex="100%" fxLayoutAlign="center center" fxLayoutAlign.lt-md="center center" fxLayoutGap="2%">
    <div class="card-container" *ngFor="let data of cardDetails;let id=index" fxLayoutAlign="end center" fxFlex.lt-md="80%" fxFlex.lt-sm="100%" fxFlex="48%" fxLayout="row">
      <div class="contentCardContainer mat-elevation-z6" fxLayoutAlign="center center" fxLayout="row" [ngStyle]="{'background-color':data?.contentCardColor}" [ngStyle.lt-sm]="{'min-height':'200px','background-color':data?.contentCardColor}">
        <div fxLayout="column" fxLayoutAlign="center center" class="content-container">
          <div *ngIf="data?.headingEnable" class="card-heading" [ngStyle.lt-sm]="{'text-align':'center'}" [attr.data-control]="'dashboard-card-heading'+id">
            {{data?.heading}}
          </div>
          <div class="card-text" [ngStyle.lt-sm]="{'font-size':'15px'}" [attr.data-control]="'dashboard-card-content'+id">
            {{data?.text}}
          </div>
          <div *ngIf="data?.buttonEnable">
            <button mat-button class="card-button" [attr.data-control]="'dashboard-card-button'+id" [ngStyle]="{'background-color':data?.buttonColor,'color':data?.buttonTextColor}">{{data?.buttonText}}</button>
          </div>
        </div>
        <div fxLayoutAlign="center center" class="cardImageContainer mat-elevation-z6" [ngStyle]="{'background-color':data?.imageCardColor}">
          <img  class="cardImage" [attr.data-control]="'dashboard-card-image'+id"  [src]="data?.imageUrl">
        </div>
      </div>
    </div>
  </div>
</div>  -->

<!-- New Dashboard Design-->
<div [ngStyle]="{'padding':'15px 5px 5px 25px','height':'98%'}" [ngStyle.lt-md]="{'height' : 'max-content', 'padding':'15px 5px 15px 25px'}" fxLayout="column" fxLayoutGap="2%"
  fxLayoutGap.lt-sm="5%">
  <div fxFlex="15%" fxFlex.lt-md="30" fxFlex.lt-sm="50" fxLayout="row wrap" fxLayoutAlign="start stretch"  class="section-1-container"
    fxLayoutGap="2%" fxLayoutGap.lt-sm="0%" [ngStyle]="{'row-gap':'16px'}">
    <div *ngFor="let section of sections, let i = index" fxFlex="32" fxFlex.lt-md="48" fxFlex.lt-sm="100"
      [ngStyle]="{'position': 'relative', 'background-color': section?.backgroundColor,'color':section?.color,'font-weight':'500','border-radius':'3px'}"
      fxLayout="row" fxLayout.lt-sm="row" fxLayoutAlign.lt-sm="start center">
      <div class="inside-container1">
        <img src="../../../../assets/email_back.png" alt="" class="background-image">
      </div>
      <div class="container" [ngStyle]="i == 2 ? {'cursor' : ''} : {'cursor' : 'pointer'}"  fxLayoutAlign="center center" (click)="section.count >= 0 && i != 2 ? navigation(section.path, section.menuName, section.subMenuName, section.queryParam, section.count, section.value) : null">
        <div fxLayout="row" fxLayoutGap="15px" class="inside-container2" fxLayoutAlign="space-between">
          <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start center">
            <div fxLayoutAlign="center center" *ngIf="section?.img">
              <div class="image-container">
                <img [src]="section?.img" class="image">
              </div>
            </div>
            <div fxLayout="column" fxLayoutGap="3%" fxLayoutAlign="center start">
              <span [ngStyle]="{'font-size': section?.titleFontSize}" [ngStyle.lt-md]="{'font-size':'16px'}"
                *ngIf="section?.title">{{ section?.title }}</span>
              <span [ngStyle]="{'font-size': section?.subTitleFontSize}" [ngStyle.lt-md]="{'font-size':'12px'}"
                *ngIf="section?.subTitle">{{ section?.subTitle }}</span>
            </div>
          </div>
          <div fxLayoutAlign="end center"
            fxLayoutAlign.lt-md="end center" fxLayoutAlign.lt-sm="end center"
            *ngIf="section?.isCount; else ribben">
            <span [ngStyle]="{'font-size':'32px'}">{{ formatCount(section?.animatedCount ?? 0) }}</span>
          </div>
          <ng-template #ribben>
            <div class="ribben">
              <img [src]="'../../../../assets/ribben.png'" height="100px" width="115px">
              <span class="ribben-text" >30 Days Free Trial</span>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
  <div fxFlex="80%" fxFlex.lt-sm="60%" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="2%">
    <div fxFlex="68%" fxFlex.lt-sm="50%" fxLayout="column" fxLayoutGap="3%">
      <mat-card  fxFlex="65%" fxLayout="column"  [ngStyle]="{'position':'relative', 'width':'100%','background-color':'white' , 'border-radius' : '4px'}">
        <div style=" border-bottom: solid 1px #D0CCD0">
          <div fxLayout="row" fxLayoutAlign="space-between center" style="padding: 4px 8px 0px 12px;" >
            <div style="width: 100%;">
              <div fxLayoutAlign="start center" style="font-weight: 500;">Mail overview</div>
            </div>
            <tr>
              <td class="Duration-filter-container" (mouseleave)="mailFilter.style.display='none'">
                <span  (mouseover)="mailFilter.style.display='block'" class="material-symbols-outlined overview-icons overview-icon-calender">tune</span>
                <div #mailFilter class="Duration-option-container" >
                  <div  [ngClass]=" item.value == durationFilterSelection.event ? 'Active-option' : '' " *ngFor="let item of mailFilterOptions" (click)="durationFilterSelection.event=item.value; mailFilter.style.display='none' ; getOverViewData()" class="Duration-option">{{item.data}}</div>
                </div> 
              </td>
              <td class="Duration-filter-container" (mouseleave)="dropdown.style.display='none'">
                  <span  (mouseover)="dropdown.style.display='block'"  #option class="material-symbols-outlined overview-icons overview-icon-calender">calendar_month</span>
                  <div #dropdown class="Duration-option-container" >
                    <div *ngFor="let item of durationOptions" [ngClass]=" item.value == durationFilterSelection.duration ? 'Active-option' : '' " (click)="durationFilterSelection.duration = item.value; durationFilterSelection.filterValue = item.filterValue || ''; dropdown.style.display='none' ; getOverViewData()" class="Duration-option">{{item.data}}</div>
                  </div>
              </td>
              <td (click)="navigation(graphNavData.path, graphNavData.menuName, graphNavData.subMenuName, 'customDateRange', mailValues.mailsSent + mailValues.mailsFiled)">
                <span class="material-symbols-outlined overview-icons">arrow_outward</span>
              </td>
            </tr>
          </div>
        </div> 
        <div fxFlex="auto" fxLayoutAlign="space-evenly " style="padding: 10px;">
          <div fxLayout="column" style="position: relative;" fxLayoutAlign="center center" *ngIf="mailValues.mailsSent && (mailValues.mailsSent != 0 || mailValues.mailsFiled != 0)"> 
          <div #mouseTarget class="circle-container" fxLayoutAlign="center center">
          <div class="first-circle" [style.background]="'conic-gradient(#2B5BFC 0deg ' + ((mailValues.mailsSent / (mailValues.mailsSent + mailValues.mailsFiled)) * 360)+ 'deg, transparent ' + ((mailValues.mailsSent / (mailValues.mailsSent + mailValues.mailsFiled)) * 360) + 'deg 360deg)'"></div>
          <div [style.z-index]="zIndex" class="second-circle" [style.background]="'conic-gradient(transparent 0deg ' + ((mailValues.mailsSent / (mailValues.mailsSent + mailValues.mailsFiled)) * 360)+ 'deg, #809DFD ' + ((mailValues.mailsSent / (mailValues.mailsSent + mailValues.mailsFiled)) * 360) + 'deg 360deg)'"></div>
          <div class="inner-circle" fxLayoutAlign="center center" fxLayout="column">
            <div class="total-mails-title"> 
              {{calculateMailDataValue()}}%</div>
            <div class="total-mails-description" >
              {{mailValues.mailsSent + mailValues.mailsFiled}} <span style="color: rgba(0,0,0,0.66);">Mails sent</span>
            </div>
          </div>
          <div style=" border-radius: 50%; position: absolute;width: 100%;height: 100%; z-index: 10;" [@fillAnimation]="state"></div>
          </div>
          <div class="chart-description-container">
          <tr class="title-container"  fxLayoutAlign="space-between center">
            <!-- Title for the new message -->
            <td style="margin:0 14px 0 0" fxLayoutAlign="center center">
              <tr>
                <td>
                  <div style="width:10px;height:10px;background-color:#2B5BFC;border-radius:50%;"></div>
                </td>
                <td style="font-size:14px;font-weight: 500;">Mails Sent</td>
              </tr>              
            </td>
            <!-- Title for the new message -->
            <td fxLayoutAlign="center center">
              <tr>
                <td>
                  <div style="width:10px;height:10px;background-color:#809DFD;border-radius:50%;"></div>
                </td>
                <td style="font-size:14px;;font-weight: 500;">Mails Failed</td>
              </tr>   
            </td>
          </tr>
          </div>
          <div #pieChartLabel class="pie-chart-lable">
            <div style="font-size: 16px; font-weight: 500; padding-bottom: 8px;">
              {{currentLabel.title}}
            </div>
            <div style="font-size: 14px">
              {{currentLabel.value}}
            </div>
            </div>
          </div>
          <div fxLayoutAlign="center center" *ngIf="!(mailValues.mailsSent && (mailValues.mailsSent != 0 || mailValues.mailsFiled != 0))">
            <div fxLayout="column" fxLayoutAlign="center center">
              <div style="max-width:180px;">
                <img width="100%" src="../../../../assets/noOverviewData.jpg" alt="">
              </div>
            </div>
          </div>
          <div fxHide.lt-sm="true" fxLayout="column" class="chart" fxLayoutAlign="center start">
            <div><canvas id="myChart" style="min-width: 350px; max-width:350px;"></canvas></div>
          </div>
        </div>
        <div *ngIf="!(myChart)" class="overviewLoader" fxLayoutAlign="center center">
          <span class="loader"></span>
        </div>
      </mat-card>
      <div fxFlex="35%" fxLayout="row" fxLayoutGap="3%">
        <mat-card *ngFor="let data of activeData; let i = index" class="dashboard-wave-container" [ngStyle.lt-md]="{'height': '160px'}" (click)="navigation(data.path, data.menuName, data.subMenuName, data.queryParam, data.active)" fxFlex="50%">
          <div fxFlex="100%" fxLayout="column" fxLayoutAlign="end start" class="wave-container">
            <div class="wave" fxLayout="row">
              <svg id="wave-svg" [ngStyle]="{'fill': data.fill}" *ngFor="let _ of [].constructor(2)"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 70" shape-rendering="geometricPrecision" text-rendering="geometricPrecision">
                <g transform="translate(-54.342342 0.000013)">
                  <path d="M0,20.2l41.6,4.7c40,5.7,120,15.7,200,5.4c80-10.7,145.1-27.1,225.1-21.8c80,5.7,174.9,32.1,254.9,21.8c80-10.7,137.5-17,231.8-17c94.2,6.9,178.1,29.9,262.3,29.9c62.1,0,125-12.9,125-12.9s97.225395-10.996608,99.3-10.1c-.1,1.4,0,49.8,0,49.8h-46.3c-40,0-116.1,0-196.1,0s-169.4-.4-249.4-.4-161.2.4-241.2.4-182.5,0-262.5,0-138.2,0-218.2,0-95.5,0-135.5,0L0,70v-49.8Z" transform="translate(54.342342-.000015)" />
                </g>
              </svg>
            </div>
            <div class="wave-fill" [ngStyle]="{'height': 'calc(' + activePercent[i] + ' - 15%)', 'background-color': data.fill}"></div>
          </div>
          <div class="navigate-arrow-container" fxLayoutAlign="center center" [fxHide]="data.active === 0">
            <span class="material-symbols-outlined navigate-arrow" fxLayoutAlign="center center">
              north_east
            </span>
          </div>
          <div fxLayout="column" fxLayoutGap="15px" class="dashboard-wave-details">
            <div class="wave-number wave-number" fxLayoutAlign="center center">{{data.active}}</div>
            <div class="wave-text" fxLayoutAlign="center center">{{data.title}}</div>
          </div>
        </mat-card>
      </div>
    </div>
    <mat-card fxFlex="32%" fxFlex.lt-sm="auto" fxFlex.lt-md="auto" 
      class="dashboard-profile-container" fxLayout="column" fxLayout.lt-md="row" fxLayout.lt-sm="column" fxLayoutGap="15px" fxLayoutAlign="space-evenly center">
      <div fxLayout="column" fxFlex.lt-md="40%" fxFlex.lt-sm="auto" fxLayoutGap="15px" fxLayoutAlign="center center">
        <div class="profile-image-container" fxLayoutAlign="center center">
          <img class="dashboard-profile-image" src="../../../../assets/dashboard-profile-image.jpg" width="150px" height="150px">
        </div>
        <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center center" *ngIf="userData">
          <div fxLayoutAlign="center center">
            <div class="profile-name" [matTooltip]="isScreenLarge ? userData?.firstName + ' ' + userData?.lastName : ''" [matTooltipShowDelay]="300" [matTooltipDisabled]="userData?.firstName + ' ' + userData?.lastName| toolTipDisable :35">{{userData?.firstName}} {{userData?.lastName}}</div>
          </div>
          <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="center center" *ngIf="userData?.emailactivity?.emailId">
            <mat-icon class="icon" style="color: #575757">mail_outline</mat-icon>
            <div class="profile-sub-text mail-text" [matTooltip]="isScreenLarge ? userData?.emailactivity?.emailId : ''" [matTooltipShowDelay]="300" [matTooltipDisabled]="userData?.emailactivity?.emailId | toolTipDisable: 18" [ngStyle]="{'width': '85%'}" [ngStyle.lt-md]="{'width': 'max-content'}">{{userData?.emailactivity?.emailId}}</div>
          </div>
        </div>
      </div>
      <div fxLayout="column" fxFlex.lt-md="60%" fxFlex.lt-sm="auto" style="width: 100%" fxLayoutGap="15px" fxLayoutAlign="center center">
        <div fxLayout="row wrap" #containerRef fxLayoutGap="7px" style="width: 100%; row-gap: 10px;" fxLayoutAlign="space-evenly">
          <ng-container  *ngFor="let item of profileData; let i = index" >
            <div fxLayout="column" fxLayoutGap="5px" fxLayoutAlign="center center" style="transition: all 0.3; cursor: pointer" (click)="navigation(item.path, item.menuName, item.subMenuName)">
              <div class="profile-numbers" fxLayoutAlign="center center">{{ item.count < 10 ? '0' + item.count : item.count }}</div>
              <div class="profile-sub-text" fxLayoutAlign="center center">{{item.name}}</div>
            </div>
            <hr *ngIf="(i === 1 && showSecondHr) || i === 0" class="profile-hr">
          </ng-container>
        </div>
        <button mat-raised-button type="button" class="profile-button btn" style="width: 80%; height: 40px; box-shadow: none; padding: 2px 0; color: #2B2B63; font-size: 12px; background-color: #EEF7FE;">
          {{"PLAN DETAILS"}}
        </button>
      </div>
    </mat-card>
  </div>
</div>