<mat-card fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="5px" fxLayout.lt-sm="column" fxLayoutGap.lt-sm="5px" class="header-div">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center center">
        <div *ngIf="heading" fxLayout="column" class="heading-content-div">
            <span fxLayout="row" class="header" fxLayoutAlign="start center">
                <h1 checkElipsis (titleElipsisEnabled)="isTitleEnable($event)"
                    [style.color]="'black'"
                    class="header-card-title head-title-wrap" [matTooltip]="heading.title || ''"
                    [style.font-weight]="heading.fontWeight"
                    [matTooltipDisabled]="!titleEllipsis" [ngStyle.lt-sm]="{'text-align':'center'}">
                    {{heading.title}}</h1>
                <mat-icon fxLayoutAlign="center center" *ngIf="heading.isHeaderIcon" class="headerIcon" data-control="header-link"
                    (click)="headerIconNavigate(heading)"
                    [matTooltip]="heading.iconToolTip ? heading.iconToolTip : 'Help'"
                    [style.color]="'black'">
                    {{heading.iconName ? heading.iconName : 'link'}}
                </mat-icon>
            </span>
            <h3 *ngIf="heading.description" checkElipsis
                [style.color]="'black'"
                (descElipsisEnabled)="isDescriptionEnable($event)" class="header-card-subtitle head-subtitle-wrap"
                [matTooltip]="heading.description || '' " [matTooltipDisabled]="!descriptionEllipsis"
                [style.font-size]="'15px'"
                [ngStyle.lt-sm]="{'text-align':'center'}">
                {{heading.description}}
            </h3>
        </div>
    </div>
    <div fxShow.gt-md fxShow.lt-sm fxHide.lt-md fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutGap="5px"
        fxLayout.lt-sm="row wrap" fxLayoutAlign.lt-sm="center center" fxLayoutGap.lt-sm="5px"
        ngStyle.lt-sm="padding-bottom:10px" class="buttons-inner-div">
        <div *ngFor="let item of buttonInfo">
            <button [style.outline]="item.outline || 'none'" mat-flat-button [style.backgroundColor]="item.backgroundColor" [style.color]="item.color" [attr.data-control]="item.name"
                class="normal-button-style {{item.class}}" [disabled]="item.disabled"
                (click)="buttonClicked(item)">
                <div class="normal-button-info" fxLayoutAlign="start center">
                    <span class="material-icons-outlined pointer-event" *ngIf="item.icon" [attr.data-control]="item.icon" [matTooltip]="''+item.iconToolTip || ''">
                        {{item.icon}}</span>
                    <span>{{item.name}}</span>
                </div>
            </button>
        </div>
    </div>
    <div *ngIf="buttonInfo && buttonInfo.length > 0" fxHide fxHide.lt-sm fxShow.lt-md fxLayoutAlign="end center">
        <div [matMenuTriggerFor]="menu" class="more-menu-button" data-control="actionMenu">
            <mat-icon class="header-more-menu-icon">more_vert</mat-icon>
        </div>
        <mat-menu #menu="matMenu" class="hide-header-page-menu-List">
            <div *ngFor="let item of buttonInfo" fxHide fxHide.lt-sm fxShow.lt-md>
                <div *ngIf="!item.condition || item.condition && item.type !== 'menu'">
                    <button mat-menu-item class="{{item.class}}" [disabled]="item.disabled" (click)="buttonClicked(item)" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="3px" [attr.data-control]="item.name">
                        <div class="more-menu-buttons" fxLayoutAlign="start center">
                            <span  class="material-icons-outlined pointer-event {{item.class}}" *ngIf="item.icon" [attr.data-control]="item.icon" [matTooltip]="''+item.iconToolTip || ''">
                                {{item.icon}}</span>
                            <span class="button-item">{{item.name}}</span>
                        </div>
                    </button>
                </div>
            </div>
        </mat-menu>
    </div>
</mat-card>