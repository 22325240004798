<div fxLayout="column" style="margin-left: 20px;">
<mat-card class="skeleton-header" fxFlex="100%" fxLayoutAlign="space-between" *ngIf="!isRegion && !isMail">
  <div fxLayout="row"  fxFlex="100%">
    <div fxLayout="column" fxFlex="50%" fxLayoutGap="3%">
      <div class="skeleton-header-label1"></div>
      <div class="skeleton-header-label2"></div>
    </div>
    <div fxFlex="50%" fxLayoutAlign="end center">
      <!-- <div class="skeleton-header-button1"></div> -->
    </div>
  </div>
</mat-card>

<mat-card class="skeleton-list" *ngIf="!formLoader && !isRegion && !isMail">
  <div fxLayout="row" fxLayoutGap="3%">
    <div fxFlex="30%" fxLayoutAlign="start start">
    <div class="skeleton-avatar0" ></div>
  </div>
  <div fxFlex="70%" fxLayoutAlign="end end">
    <div class="skeleton-avatar6"></div>
  </div>
  </div>
  <div fxLayout="row" fxLayoutGap="2%">
    <div class="skeleton-avatar5"></div>
  </div>
  <div class="skeleton-item" *ngFor="let item of items">
    <div fxLayout="row" fxFlex="100%" fxLayoutGap="7%"  fxLayout.xs="column" fxLayoutAlign.xs="center center" fxLayoutGap.xs="7%" [ngStyle]="{'padding-top':'8px','padding-bottom':'8px'}">
      <div class="skeleton-avatar1" [ngStyle.xs]="{'height':'100px','width':'10%'}"></div>
      <div class="skeleton-avatar2" fxFlex.xs="100%" [ngStyle.xs]="{'height':'60px','width':'70%'}"></div>
      <div class="skeleton-avatar3" fxFlex.xs="100%" [ngStyle.xs]="{'height':'60px','width':'70%'}"></div>
      <div class="skeleton-avatar4" fxFlex.xs="100%" [ngStyle.xs]="{'height':'60px','width':'70%'}"></div>
    </div>
  </div>
</mat-card>
  <mat-card *ngIf="formLoader &&  module !== 'reports'" class="skeleton-form" fxLayout="column">
    <!-- <div class="skeleton-form-header"></div> -->
    <div fxLayout="row" fxLayoutGap="4%" fxFlex="100%" [ngStyle]="{'padding-left':'30px', 'padding-right': '30px'}" [ngStyle.lt-sm]="{'padding-top':'30px','padding-left':'1px'}">
      <div fxLayout="row" fxLayoutGap="4%" fxFlex="60%" fxLayout.lt-md="column">
        <div class="skeleton-form-header1"></div>
        <div *ngIf="module == 'application' && !isCreate" [ngStyle]="{'width': '80%'}" class="skeleton-form-header1"></div>
      </div>
      <div fxFlex="auto" fxLayoutGap="20px" fxLayoutGap.lt-sm="20px" fxLayoutAlign="end center" fxLayout="row" fxLayout.lt-sm="column">
        <div *ngIf="module == 'customer' && !isCreate" class="skeleton-toggle-1"></div>
        <div class="skeleton-form-button1"></div>
        <div *ngIf="!isView" class="skeleton-form-button2"></div>
        <div *ngIf="module == 'template'" class="skeleton-form-button1"></div>
        <ng-container *ngIf="module == 'customer' && isView">
          <div class="skeleton-icon"></div>
          <div class="skeleton-icon"></div>
        </ng-container>
      </div>
    </div>
    <ng-container *ngIf="module == 'log'">
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="2%" fxLayoutGap.lt-sm="35px" [ngStyle]="{'padding-top':'50px','padding-left':'30px'}" [ngStyle.lt-sm]="{'padding': '20px 30px'}">
        <mat-card fxLayout="column" fxFlex="65%" fxLayoutGap="5%" [ngStyle]="{'padding':'30px 20px'}">
          <div *ngFor="let _ of [].constructor(4)" fxFlex="100%%" fxFlex.lt-sm="100%">
            <div fxLayout="column" fxLayoutGap="2%" fxFlex="100%">
              <div class="skeleton-form-label1"></div>
              <div class="skeleton-form-field2"></div>
            </div>
          </div>
        </mat-card>  
        <mat-card fxLayout="column" fxFlex="35%" fxLayoutGap="16px" [ngStyle]="{'padding':'30px 20px'}">
          <div fxLayout="column" fxFlex="100%" fxLayoutAlign="center center">
            <div class="skeleton-header-label2" [ngStyle]="{'height':'25px', 'width': '80%'}"></div>
          </div>
          <div fxLayout="column" fxLayoutGap="0%" [ngStyle]="{'width':'100%'}">
            <ng-container *ngFor="let _ of [].constructor(4);let i=index">
              <div fxLayout="row">
                <div fxFlex="35%" fxLayout="column" fxLayoutAlign="start">
                  <div class="skeleton-logs-label"></div>
                </div>
                <div fxFlex="30%">
                  <div fxLayout="column" fxLayoutAlign="center center" class="tracking-container">
                    <div>
                      <div class="default-tracking-dots"></div>
                    </div>
                    <div *ngIf="i != 3">
                      <hr class="default-hr-tag">
                    </div>
                  </div>
                </div>
                <div fxFlex="35%" fxLayout="row">
                  <div class="skeleton-logs-label"></div>
                </div>
              </div>
            </ng-container>
          </div>
        </mat-card>  
      </div>
      <div fxLayout="column" fxLayoutGap="4%" fxFlex="100%" [ngStyle]="{'padding': '20px 30px 0'}" [ngStyle.lt-sm]="{'padding-top':'30px','padding-left':'1px'}">
        <div fxLayoutGap="4%" fxFlex="60%" fxLayout.lt-md="column">
          <div class="skeleton-form-header1"></div>
        </div>
        <mat-card [ngStyle]="{'margin-top': '40px'}">
          <div class="skeleton-image-2" style="margin: 10px; width: auto"></div>
        </mat-card>
      </div>
    </ng-container>
    <div [ngStyle]="{'padding-top':'50px','padding-left':'30px'}" *ngIf="module !== 'log'" [ngStyle.lt-sm]="{'padding-top':'30px','padding-left':'1px'}">
      <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="5%" [ngStyle]="{'row-gap':'30px'}">
        <div *ngFor="let _ of [].constructor(items_form)" fxFlex="45%" fxFlex.lt-sm="100%">
          <div fxLayout="column" fxLayoutGap="2%" fxFlex="100%">
            <div class="skeleton-form-label1"></div>
            <div class="skeleton-form-field2"></div>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="module == 'template'">
      <div fxLayout="row" fxFlex="100%" [ngStyle]="{'margin-top':'80px','padding-left':'30px', 'padding-right': '30px'}" [ngStyle.lt-sm]="{'padding-top':'30px','padding-left':'1px'}">
        <div fxLayout="row" fxFlex="60%" fxLayout.lt-md="column">
          <div class="skeleton-form-header1"></div>
        </div>
        <div fxFlex="auto" fxLayoutGap="20px" fxLayoutGap.lt-sm="20px" fxLayoutAlign="end center" fxLayout="row" fxLayout.lt-sm="column">
          <div class="skeleton-toggle-2"></div>
        </div>
      </div>
      <div [ngStyle]="{'padding-top':'25px'}" [ngStyle.lt-sm]="{'padding-top':'30px','padding-left':'1px'}">
        <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="space-between" [ngStyle]="{'row-gap':'30px', 'padding': '0 30px'}">
          <div *ngFor="let _ of [].constructor(4); let i = index" [fxFlex]="i == 2 ? '70%' : i == 3 ? '25%' : '47.5%'" fxFlex.lt-sm="100%">
            <div fxLayout="column" fxLayoutGap="2%" fxFlex="100%">
              <div class="skeleton-form-label1"></div>
              <div class="skeleton-form-field2"></div>
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="column" fxLayoutGap="2%" fxFlex="100%" [ngStyle]="{'padding': '0 30px', 'margin-top': '30px'}">
        <div class="skeleton-form-label1"></div>
        <div class="skeleton-image-2"></div>
      </div>
    </ng-container>
  </mat-card>
  <!--Category loader-->
  <mat-card *ngIf="formLoader && module == 'category'" [ngStyle]="{'margin-top': '15px'}" class="skeleton-form" fxLayout="column" fxLayoutGap="4%">
    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center center" fxLayoutGap="4%" fxFlex="100%" [ngStyle]="{'padding-left':'30px', 'padding-right': '30px'}">
      <div fxLayoutGap="4%" style="width: 100%;" fxFlex="auto" fxLayout.lt-md="column" fxFlex.lt-md="100%" fxLayoutAlign.lt-sm="center center">
        <div class="skeleton-form-header1"></div>
      </div>
      <div fxFlex="auto" fxLayoutGap="20px" fxLayoutGap.lt-sm="20px" fxLayoutAlign="end center">
        <div *ngIf="!isView" class="skeleton-form-button3"></div>
      </div>
    </div> 
    <div fxLayout="column" *ngIf="isCreate" fxLayoutAlign="center center">
      <div class="skeleton-image-1"></div>
      <div class="skeleton-header-label3"></div>
      <div class="skeleton-header-label2"></div>
    </div>
    <div *ngIf="!isCreate" [ngStyle]="{'padding-left':'30px', 'padding-right': '30px'}" fxLayout="column" fxLayoutGap="15px" [ngStyle.lt-sm]="{'padding-top':'30px','padding-left':'1px'}">
      <mat-card>
        <div fxLayout="row" fxFlex="100%" fxLayoutGap="7%" fxLayoutAlign="space-around" fxLayoutGap.xs="7%" [ngStyle]="{'padding-top':'8px','padding-bottom':'8px'}">
          <div class="skeleton-avatar2"></div>
          <div class="skeleton-avatar3"></div>
          <div class="skeleton-avatar1"></div>
        </div>
      </mat-card>
      <mat-card>
        <div fxLayout="row" fxFlex="100%" fxLayoutGap="7%" fxLayoutAlign="space-around" fxLayoutGap.xs="7%" [ngStyle]="{'padding-top':'8px','padding-bottom':'8px'}">
          <div class="skeleton-avatar2"></div>
          <div class="skeleton-avatar3"></div>
          <div class="skeleton-avatar1"></div>
        </div>
      </mat-card>
  </div>
  </mat-card>
  <div fxLayout="row" fxLayout.lt-md="column" fxFlex="100%" *ngIf="module == 'reports'" fxLayoutGap="20px">
    <mat-card fxFlex="50%" style="padding: 30px">
      <div fxLayout="row" fxLayoutGap="4%" fxFlex="100%">
        <div fxLayout="column" fxLayoutGap="4%" fxFlex="100%">
          <div class="skeleton-form-header1" style="height: 35px"></div>
          <div fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="30px">
            <div class="skeleton-chart-1"></div>
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
              <div class="skeleton-chart-label-1"></div>
              <div class="skeleton-chart-label-2"></div>
              <div class="skeleton-chart-label-1"></div>
              <div class="skeleton-chart-label-2"></div>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
              <div class="skeleton-form-button1"></div>
              <div class="skeleton-form-button2"></div>
              <div class="skeleton-form-button2"></div>
            </div>
          </div>
        </div>
      </div>
    </mat-card>
    <mat-card fxFlex="50%" *ngIf="module == 'reports'" style="padding: 50px">
      <div style="row-gap: 35px" fxLayout="row wrap" fxLayout.lt-sm="column" fxFlex="100%" fxLayoutAlign="space-around">
        <div fxLayout="column" fxLayoutGap="15px" fxFlex="50%" *ngFor="let _ of [].constructor(4)" fxLayoutAlign="end center">
          <div class="skeleton-form-button3"></div>
          <div class="skeleton-form-label1"></div>  
          <div fxLayoutGap="7px" fxLayout="row">
            <div fxLayout="column" fxLayoutGap="5px" fxLayoutAlign="end center">
              <div class="skeleton-chart-label-3"></div>
              <div class="skeleton-chart-2"></div>
            </div>
            <div fxLayout="column" fxLayoutGap="5px" fxLayoutAlign="end center">
              <div class="skeleton-chart-label-3"></div>
              <div class="skeleton-chart-3"></div>
            </div>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
  <mat-card class="skeleton-list" *ngIf="isRegion">
    <div fxLayout="row" fxLayoutGap="3%">
      <div fxFlex="30%" fxLayoutAlign="start start">
      <div class="skeleton-avatar0" ></div>
    </div>
    <div fxFlex="70%" fxLayoutAlign="end end">
      <div class="skeleton-avatar6"></div>
    </div>
    </div>
    <div fxLayout="row" fxLayoutGap="2%">
      <div class="skeleton-avatar5"></div>
    </div>
    <div class="skeleton-item" *ngFor="let item of items">
      <div fxLayout="row" fxFlex="100%" fxLayoutGap="7%"  fxLayout.xs="column" fxLayoutAlign.xs="center center" fxLayoutGap.xs="7%" [ngStyle]="{'padding-top':'8px','padding-bottom':'8px'}">
        <div class="skeleton-avatar1" [ngStyle.xs]="{'height':'100px','width':'10%'}"></div>
        <div class="skeleton-avatar2" fxFlex.xs="100%" [ngStyle.xs]="{'height':'60px','width':'70%'}"></div>
        <div class="skeleton-avatar3" fxFlex.xs="100%" [ngStyle.xs]="{'height':'60px','width':'70%'}"></div>
        <div class="skeleton-avatar4" fxFlex.xs="100%" [ngStyle.xs]="{'height':'60px','width':'70%'}"></div>
      </div>
    </div>
  </mat-card>
  <mat-card class="skeleton-list1" *ngIf="isMail">
    <div fxLayout="row" fxLayoutGap="5%" [ngStyle]="{'padding-bottom':'10px','padding-top':'10px'}">
      <div fxLayout="row" fxLayoutGap="3%" fxFlex="85%">
      <div class="skeleton-avatar11" ></div>
      <div class="skeleton-avatar12" ></div>
      </div>
      <div fxLayout="row" fxLayoutGap="10%">
      <div class="skeleton-avatar0-1" ></div>
      <div class="skeleton-avatar0-1" ></div>
      <div class="skeleton-avatar0-1" ></div>
      <div class="skeleton-avatar0-1" ></div>
      </div>
    </div>
    <div class="skeleton-item" *ngFor="let item of mailLists">
      <div fxLayout="row" fxFlex="100%" fxLayoutGap="3%"  fxLayout.xs="column" fxLayoutAlign.xs="center center" fxLayoutGap.xs="7%" [ngStyle]="{'padding-top':'8px','padding-bottom':'8px'}">
        <div class="skeleton-avatar10" [ngStyle.xs]="{'height':'100px','width':'10%'}"></div>
        <div class="skeleton-avatar10" [ngStyle.xs]="{'height':'100px','width':'10%'}"></div>
        <div class="skeleton-avatar7" fxFlex.xs="100%" [ngStyle.xs]="{'height':'60px','width':'70%'}"></div>
        <div class="skeleton-avatar8" fxFlex.xs="100%" [ngStyle.xs]="{'height':'60px','width':'70%'}"></div>
        <div class="skeleton-avatar9" fxFlex.xs="100%" [ngStyle.xs]="{'height':'60px','width':'70%'}"></div>
      </div>
    </div>
  </mat-card>
</div>