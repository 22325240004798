import { NgModule } from '@angular/core';
import { CoreModule } from '../common/common.module';
import { CommonCardComponent } from './components/common-card/common-card.component';
import { ToolTipDisablePipe } from './pipe/tool-tip-disable.pipe';
import { SideMenuBarComponent } from './components/side-menu-bar/side-menu-bar.component';
import { MenuDrawerComponent } from './components/menu-drawer/menu-drawer.component';
import { RouterModule } from '@angular/router';
import { CommonFilterComponent } from './components/common-filter/common-filter.component';
import { CommonModule, DatePipe } from '@angular/common';
import { CommonDialogComponent } from './components/common-dialog/common-dialog.component';
import { CommonSnackbarComponent } from './components/common-snackbar/common-snackbar.component';
import { RemoveUnderscorePipe } from './pipe/remove-underscore.pipe';
import { CommonHeaderComponent } from './components/common-header/common-header.component';
import { SkeletonLoaderComponent } from './components/skeleton-loader/skeleton-loader.component';
import { CommonMailsListComponent } from './components/common-mails-list/common-mails-list.component';


import { CustomEditorComponent } from 'libs/shared/src/lib/components/custom-editor/custom-editor.component';

@NgModule({
  declarations: [
    CommonCardComponent,
    ToolTipDisablePipe,
    SideMenuBarComponent,
    MenuDrawerComponent,
    CommonFilterComponent,
    CommonDialogComponent,
    CommonSnackbarComponent,
    RemoveUnderscorePipe,
    CommonHeaderComponent,
    SkeletonLoaderComponent,
    CommonMailsListComponent,
    CustomEditorComponent
  ],
  imports: [
    CoreModule,
    CommonModule,
    RouterModule,
  ],
  
  exports:[
    CommonCardComponent,
    ToolTipDisablePipe,
    SideMenuBarComponent,
    MenuDrawerComponent,
    CommonFilterComponent,
    CoreModule,
    CommonHeaderComponent,
    SkeletonLoaderComponent,
    CommonMailsListComponent,
    CustomEditorComponent
  ],
  providers: [DatePipe]
})
export class SharedModule { }
