import { Component } from '@angular/core';
import { CustomersComponent } from 'libs/shared/src/lib/components/customers-list/customers-list.component';

@Component({
  selector: 'app-mail-customers-list',
  templateUrl: './mail-customers-list.component.html',
  standalone: true,
  imports: [CustomersComponent],
  styleUrls: ['./mail-customers-list.component.scss']
})
export class MailCustomersListComponent {

}
