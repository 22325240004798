import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as _ from 'lodash';
import { SNACKBAR_DETAILS } from '../constants/common-snackbar-data.constants';
import { SnackbarDetails } from '../models/common-snackbar-data.model';
import { CommonSnackbarComponent } from '../components/common-snackbar/common-snackbar.component';

@Injectable({
  providedIn: 'root'
})
export class CommonSnackbarService {
  snackBarDetails: Array<SnackbarDetails> = _.cloneDeep(
    SNACKBAR_DETAILS
  );

  constructor(private snackBar: MatSnackBar) { }

  OpenSnackBar(snackBarData: any): any {    
    let type = this.snackBarDetails.find(({ type }) => type === snackBarData.actionType);
    
    let snackBarRef = this.snackBar.openFromComponent(CommonSnackbarComponent, {
      data: snackBarData,
      duration: (type?.type) ? (snackBarData.duration ? snackBarData.duration * 1000 : 5000) : 5000,
      horizontalPosition: snackBarData?.position?.hPosition ? snackBarData?.position?.hPosition : 'center',
      verticalPosition: snackBarData?.position?.vPosition ? snackBarData?.position?.vPosition : 'bottom',
      panelClass: type?.panelClass
    });
    return snackBarRef;
  }
}
