import { Injectable } from '@angular/core';
import { HttpRoutingService } from 'src/app/common/service/http-routing.service';

@Injectable({
  providedIn: 'root'
})
export class MailServicesService {
   /**
   * component constructor which is used to inject the required services and modules.
   * @param httpClient To refer to the httpClient module to access the get and put methods.
   */
  constructor(private httpClient: HttpRoutingService) {
  }
    /**
    * Method which is used to get Email list data.
    * @param data It has the query params of the Url.
    */
  getAllEmailsList(data: any) {
    return this.httpClient.getRequest('microservice/mail/emails', data);
  }
   /**
    * Method which is used to get particular email data.
    * @param mailId It has the query params of the Url.
    */
  getOneEmail(mailId: string | number) {
    return this.httpClient.getRequest('microservice/mail/emails/' + mailId);
  }
    /**
    * Method which is used to update the email.
    * @param mailId It has the query params of the Url.
    * @param mailDetails It has the body data of the email details.
    */
  updateEmail(mailId: string | number|null, mailDetails: any) {
    return this.httpClient.putRequest('microservice/mail/emails/' + mailId, mailDetails)
  }
   /**
    * Method which is used to send the email .
    */
  onSentMail(mailContent:any){
    return this.httpClient.postRequest('microservice/mail/emails/send',mailContent)
  }
   /**
    * Method which is used to store the email in draft .
    */
  onDraftMail(mailDetails:any){
    return this.httpClient.postRequest('microservice/mail/emails/create',mailDetails)
  }
}
