
<div fxLayout="column">
  <div *ngIf="!initialLoader" class="common-header">
    <app-common-header (methodClicked)="headerEvent($event)" [buttonInfo]="button" [heading]="heading" ></app-common-header>
  </div>
   <div *ngIf="isRegionChanged" [ngStyle]="{'padding':'16px'}">
    <app-skeleton-loader [isRegion]="true"></app-skeleton-loader>
  </div>
  <div  [ngStyle]="{'margin':' 20px 20px 0px 20px'}"  *ngIf="initialLoader && !isRegionChanged"  class="common-card-style">
    <app-skeleton-loader [formLoader]="false"></app-skeleton-loader>
  </div>
  <div fxLayout="row" fxLayout.lt-md="column" *ngIf="!initialLoader && !isRegionChanged"  class="common-card-style">
     <app-common-filter *ngIf="isFilter" [appliedFiltersData]="routeParams" [filterData]="filterDetails" [isFilterStatus]="isFilterValue"
    (filterClose)="closeFilter()" (emittedData)="filterEmittedEvent($event)" (AppliedFilters)="appliedFiltersLength($event)">
    </app-common-filter>
  <div fxFlex="100%">
    <app-common-card  [sortSetting]="sorting" (sortChange)="sortData($event)" [buttonInfo]="button" (methodClicked)="headerEvent($event)" [dataNotFoundMessage]="noDataMessage" [columns]="isEnabledNotification?enabledNotifyColums:columns" [dataSource]="templateData" [actionSetting]="actions" [hasSearchField]="true"
      [searchSetting]="searchSettingData" [hasPagination]="true" (paginationChange)="handlePaginationChange($event)"
      [paginationData]="paginationData" (primarySelectEmittedData)="primaryDropdownEvent($event)" [appliedFilters]="appliedFilters"
    [primaryFilterData]="primaryfilterDetails" [hasPrimaryFilter]="!!(this.filterApplication.length)" [optionalEvent]="filterData" [loading]="templateListLoader"
      (searchChange)="handleSearchChange($event)" (actionClicked)="handleActionClicked($event)"
      (optionalEventOccurred)="handleOptionalEvent($event)" (toggleClicked)="onToggleChanged($event)" (dropdownClicked)="onToggleChanged($event)"></app-common-card>
  </div>
</div>
</div>