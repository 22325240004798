import { Component, ElementRef, Input, NgZone, OnDestroy, OnInit, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { ReportService } from '../../services/report.service';
import * as echarts from 'echarts';
import { Category, CategoryResponse, MailCategories, MailCounts, customerReport, customerReportResponse } from '../../models/reports.model';
import { EChartsOption } from 'echarts';
import { Observable, Subscription, debounceTime, forkJoin, fromEvent } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Heading } from 'src/app/shared/constants/common-header.constants';
import { SharedModule } from 'src/app/shared/shared.module';
import { CommonModule } from '@angular/common';
import { NgxEchartsModule } from 'ngx-echarts';
import { ActionClicked, columnDef, DynamicDataSource, NoRecordFound, PaginationData, TableAction } from 'src/app/shared/models/common-card-data.model';
import { ColumnType } from 'src/app/shared/constants/common-card-data.constants';
import { GetLogsList, LogsListParameters, LogsTableData } from 'src/app/zen-mail/Logs/models/logs.model';
import { SNACKBAR_HEADING, SNACKBAR_TYPE } from 'src/app/shared/constants/common-snackbar-data.constants';
import { LOG_SNACKBAR_MESSAGES } from 'src/app/zen-mail/Logs/constants/log-constant';
import { CommonSnackbarService } from 'src/app/shared/service/common-snackbar.service';
import { LogService } from 'src/app/zen-mail/Logs/service/log.service';
import { trigger, transition, style, animate, state, keyframes } from '@angular/animations';
import { ViewMailLogComponent } from "../../../Logs/components/view-mail-log/view-mail-log.component";
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-view-report',
  templateUrl: './view-report.component.html',
  standalone:true,
  imports: [SharedModule, CommonModule, NgxEchartsModule, ViewMailLogComponent],
  styleUrls: ['./view-report.component.scss'],
  animations: [
    trigger('expandHeight', [
      transition(':enter', [
        style({ height: '0' }),
        animate('0.7s linear', style({ height: '*' })),
      ]),
    ]),
    trigger('fillAnimation', [
      state('initial', style({
        background: 'conic-gradient(#fff 360deg, transparent 360deg)'
      })),
      state('filled', style({
        background: 'conic-gradient(#fff 0deg, transparent 0deg);',
        zIndex : 0
      })),
      transition('initial => filled', [
        animate('0.7s ease-in-out', keyframes(generateKeyframes()))
      ]),
      transition(':leave', [
        animate('0.5s', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class ViewReportComponent implements OnInit, OnDestroy {
  /**
   * Stores the ID for the log entry.
   * @type {number}
   */
  logId!: number;

  /**
   * represents the interactability of the primary dropdown. 
   * @type { NoRecordFound }
   */
  @Input() dataNotFoundMessage: NoRecordFound ={
    noRecordFound: "No records found",
    noRecordFoundDescription: "No record data found."
  }; ;

  /**
   * Reference to the chart label element in the DOM.
   * @type {ElementRef}
   */
  @ViewChild('pieChartLabel') chartLabel!: ElementRef;

  /**
   * Reference to the chart element that tracks mouse events.
   * @type {ElementRef}
   */
  @ViewChild('mouseTarget') chartElement!: ElementRef;

  /**
  * Variable used to store the table column definition.
  * @type { Array<columnDef> }
  */
  columns: Array<columnDef> = [
    { field: 'from', header: 'From', type: ColumnType.Text,columnAlign:'start' },
    { field: 'to', header: 'To', type: ColumnType.Text,columnAlign:'start'},
    { field: 'isSuccess', header: 'Status', columnWidth: '12%', type: ColumnType.Chip,columnAlign:'start',
      displayBackgroundColor: { true: 'green', false: 'red' },
      displayText: { true: 'Success', false: 'Failed'},
      displayColor: { true: 'white', false: 'white'}},
    { field: 'createdAt', header: 'Created', type: ColumnType.Date, columnWidth: '13%', format: "dd MMM, yyyy" ,columnAlign:'start'},
    { field: 'createdAt', header: 'Time', type: ColumnType.Date, columnWidth: '13%', format: "h:mm:ss a" ,columnAlign:'start'},
    { field: 'actions', header: 'Actions', type: ColumnType.Action, columnWidth: '10%', color: 'black' ,columnAlign:'start'},
  ];

  /**
   * Template reference for quick edit display.
   * @type {TemplateRef}
   */
  @ViewChild('viewReport', { static: false }) viewReport?: TemplateRef<any>;

  /**
   * Message to display when no data is available.
   * @type {NoRecordFound}
   */
  noDataMessage: NoRecordFound = {
    noRecordFound: "No records found",
    noRecordFoundDescription: "No records found in description"
  };

  /**
   * Variable used to display the table data.
   * @type { logsTableData[] }
   */
  data!: LogsTableData[];

  /**
   * Variable used to store the table actions data.
   * @type { Array<TableAction> }
   */
  actions: Array<TableAction> = [
    { icon: 'visibility', tooltip: 'View Mail Log', method: 'onView', color: '#0068b5'}
  ];

  mouseMoveSubscription: Subscription = new Subscription()

  /** Current percentage of mails to display in chart. 
   * @type {number} 
   */
  mailsPercentage: number = 0;

  /**
   * Controls the stacking order of elements.
   * @type {number}
   */
  zIndex = 1;

  /**
   * Stores the currently selected label with its title and value.
   * @type {{ title: string, value: string }}
   */
  currentLabel: { title: string, value: string } = { title: '', value: '' };

  /**
   * Holds an array of label objects, each with a title and value.
   * @type {{ title: string, value: string }[]}
   */
  label!: { title: string, value: string }[];


  /**
   * Variable used to store the observables.
   * @type { Subscription }
   */
  subscriptionObject: Subscription = new Subscription();

  /**
   * Holds the current state for the `fillAnimation`.
   * @type { string }
   */
  state: string = 'initial';

  /**
   * Flag Variable used to initial loader.
   * @type {boolean}
   */
  initialLoader:boolean=false;

  /**
   * Variable used to store the pie chart configurations.
   * @type {EChartsOption}
   */
  pieChartOptions!: EChartsOption;

  /**
   * Variable used to store the total number of success mails.
   * @type {number}
   */
  totalSuccessCount: number = 0;

  /**
   * Variable used to store the total number of failed mails.
   * @type {number}
   */
  totalFailureCount: number = 0;

  /**
   * Variable used to store the total number of mails.
   * @type {number}
   */
  totalMails: number = 0;

  /**
   * Variable used to store the mail counts.
   * @type {MailCounts[]}
   */
  mailCounts: MailCounts[] = []

  /**
   * Variable used to store the categories of the mails.
   * @type {MailCategories[]}
   */
  mailCategories: MailCategories[] = []

  /**
   * Variable used to store the customer name.
   * @type {string}
   */
  customerName!: string;

  /**
   * Variable used to store the observables.
   * @type {Subscription}
   */
  SubscriptionObject: Subscription = new Subscription();

  /**
   * Variable used to store the parameter Id from the URL.
   * @type {number}
   */
  paramId!: number

  /**
   * Variable used to store the mail report data..
   * @type {customerReportResponse}
   */
  mailReportData!: customerReportResponse;

  /**
   * Variable has header data to be displayed on commen header.
   * @type {Heading}
   */
  headerDetails:Heading = {
    title : "View Reports"
  }
  
  /**
   * Reference to the dialog instance. 
   * It can be used to control the dialog (e.g., closing it).
   * @type {MatDialogRef<any>}
   */
  dialogRef!: MatDialogRef<any>;

  /**
   * component constructor which is used to inject the required services.
   * @param report To refer to the ReportService to access the mail report functions.
   * @param route  To subscribe the params from the URL.
   * @param router To refer to the router to navigate to the report page.
   */
  constructor(    private dialog: MatDialog, private ngZone: NgZone,private renderer: Renderer2,private logService: LogService,private report: ReportService, private route: ActivatedRoute, private router: Router, private snackbar: CommonSnackbarService) { }

  /**
   * Angular life cycle hook.
   * @type {void}
   */
  ngOnInit(): void {
    let data: LogsListParameters = {
      offset: 0,
      limit: 5,
    }
    this.initialLoader = true;
    this.route.params.subscribe((res: Params) => {
      this.paramId = res['id'];
      data.filterData = JSON.stringify({customerId : res['id']})
    })
    this.SubscriptionObject = forkJoin({
      categoryList: this.report.getMailCategories(),
      mailReport: this.report.getMailReport(this.paramId),
      logList : this.logService.getMailLogList(data)
    }).subscribe(({ categoryList, mailReport,logList }) => {
      setTimeout(() => {
        this.initialLoader = false;
        this.processMailCategories(categoryList);
        this.processLogList(logList);
        setTimeout(() => {
          this.processMailReport(mailReport);
        },0)
        setTimeout(() => {
          this.state = 'filled';
          if(this.chartElement?.nativeElement){
          this.debounceMouce();
          }
        }, 0);
      }, 500);
    });
  }

  /**
   * Method used to get the categories list.
   * @type {void}
   * @param categoryList To refer to an Element
   */
  processMailCategories(categoryList: CategoryResponse): void {
    this.mailCategories = categoryList.category.rows.map((element: Category) => ({
      categoryName: element.categoryName,
      categoryId: element.id
    }));
  }

  /**
   * Method used to get the list of logs details based on pagination, searching and filtering.
   * @param { LogsListParameters } params - Parameters for pagination, sorting, searching, and filtering.
   * @returns { void }
   */
  processLogList(response: GetLogsList) {
    this.data = response && response.logs && response.logs.rows;
  }

  /**
   * Method used to get the mail report counts and calls the chart initialization functions.
   * @type {void}
   */
  processMailReport(mailReport: customerReportResponse): void {
    this.mailReportData = mailReport
    this.calculateMailCounts(mailReport);
    this.customerName = mailReport.report[0].recipientDetails.customerName;
    this.mailCounts = mailReport?.report?.map((element: customerReport) => ({
      successMails: element.successCount,
      failedMails: element.failureCount,
      totalMails: element.successCount + element.failureCount
    }));
  }

  /**
   * Method used to calculate the success, failed and total mails.
   * @type {void}
   */
  calculateMailCounts(reportData: customerReportResponse): void {
    this.totalSuccessCount = this.calculateTotal(reportData.report, 'successCount');
    this.totalFailureCount = this.calculateTotal(reportData.report, 'failureCount');
    this.totalMails = this.totalSuccessCount + this.totalFailureCount;
    this.mailsPercentage = this.totalSuccessCount;
    this.label = [{title : 'Total Mails sent' , value : ''+this.totalSuccessCount},{title : 'Mails failed' , value : ''+this.totalFailureCount}];
  }

  /**
   * Method used to calculate the total mails in all categories mails.
   * @type {void}
   */
  calculateTotal(reportArray: customerReport[], key: string): number {
    return reportArray.reduce((acc, curr) => acc + (curr[key] || 0), 0);
  }

  /**
   * Sets up mouse move and mouse leave event listeners with debouncing on a chart element.
   * The `mousemove` event triggers a callback for mouse movement, while the `mouseleave` event hides the chart label.
   */
  debounceMouce() {
    this.ngZone.runOutsideAngular(() => {
      this.mouseMoveSubscription.add(fromEvent<MouseEvent>(this.chartElement.nativeElement, 'mousemove')
        .pipe(
          debounceTime(0),
        )
        .subscribe((event: MouseEvent) => {
          this.ngZone.run(() => {
            this.onMouseMove(event);
          });
        }));

        this.mouseMoveSubscription.add(fromEvent<MouseEvent>(this.chartElement.nativeElement, 'mouseleave')
        .pipe(
          debounceTime(0),
        )
        .subscribe((event: MouseEvent) => {
          this.ngZone.run(() => {
            this.renderer.setStyle(this.chartLabel.nativeElement, 'visibility', 'hidden');
          });
        }));
    });
  }

  /**
   * Tracks mouse position relative to a circular chart and updates hover state.
   * 
   * Calculates the hover angle from the chart's center, determines which
   * section (mails sent or filed) is being hovered, and updates component
   * properties accordingly. Used for interactive feedback on the chart.
   * 
   * @param event - Mouse event object
   */
  onMouseMove(event:MouseEvent):void {
    // Get element details and center coordinates
    const chartElement = event.target as HTMLElement;
    const rect = chartElement.getBoundingClientRect();
    const centerX = rect.left + rect.width / 2;
    const centerY = rect.top + rect.height / 2;
    // Calculate hover angle relative to center
    const hoverAngle = ((Math.atan2(event.clientY - centerY, event.clientX - centerX) * (180 / Math.PI) + 180) - 90 + 360) % 360;
    
    // Calculate proportional angles for colored sections
    const mailsSentAngle = (this.totalSuccessCount / (this.totalSuccessCount + this.totalFailureCount)) * 360 ;
    const mailsFiledAngle = (this.totalFailureCount / (this.totalSuccessCount + this.totalFailureCount)) * 360 ;
    
    //place the lable element on where the users pointer is at
    this.renderer.setStyle(this.chartLabel.nativeElement, 'top', `${event.clientY - (rect.top+this.chartLabel.nativeElement.offsetHeight)}px`);
    this.renderer.setStyle(this.chartLabel.nativeElement, 'left', `${event.clientX - (rect.left+this.chartLabel.nativeElement.offsetHeight)}px`);
    this.renderer.setStyle(this.chartLabel.nativeElement, 'visibility', 'visible');

    // Determine hover area based on angle
    if (hoverAngle >= mailsSentAngle && hoverAngle < mailsSentAngle + mailsFiledAngle) {
      this.mailsPercentage = this.totalFailureCount;
      this.zIndex = 2;
      this.currentLabel = this.label[1];
    } else {
      this.mailsPercentage = this.totalSuccessCount;
      this.zIndex = 0;
      this.currentLabel = this.label[0];
    }
    }

    /**
     * Method used to calculate percentage of hovered element.
     * @type {number}
     */
    calculateMailDataValue():number{
      return( parseFloat((this.mailsPercentage / (this.totalSuccessCount + this.totalFailureCount)).toFixed(2)) * 100)
    }

    /**
     * Method used to generate random color by index.
     * @type {number}
     */
    getColorByIndex(index: number): number {
      const hue = (index * 137) % 360; // 137 is a prime number to spread colors
      return hue; // Adjust saturation and lightness as needed
  } 

  /**
   * Handles the action clicked event.
   * 
   * @param {ActionClicked<DynamicDataSource>} event - The event containing action and data.
   */
  handleActionClicked(event: ActionClicked<DynamicDataSource>) : void{
    this.logId = event.data['mailtemplate'].id; 
    if(this.logId){
      const dialogConfig = new MatDialogConfig();
      dialogConfig.maxHeight = '90vh';
      dialogConfig.width = '600px';
      dialogConfig.panelClass = 'log-dialog-container';
      dialogConfig.disableClose = false;
      dialogConfig.width = '700px';
      if(this.viewReport){
        this.dialogRef = this.dialog?.open(this.viewReport,dialogConfig);
      }
    }
  }

  /**
   * Closes the currently open dialog.
   * 
   * @param void
   */
  closeDialog():void{
    this.dialogRef.close();
  }

  /**
   * Calculate bar height
   * 
   * @param void
   */
  calculateBarHeight(value:number , total:number ):string{
    // Check if total is 0 to avoid division by zero
    const percentage = (value == 0 || total == 0) ? 0.05*100 : (value / total) * 100;
    // Return the percentage with two decimal places
    return percentage.toFixed(2) + '%';  
  }

  /**
   * Angular life cycle hook.
   * @type {void}
   */
  ngOnDestroy(): void {
    if (this.SubscriptionObject) {
      this.SubscriptionObject.unsubscribe();
      this.mouseMoveSubscription.unsubscribe();
    }
  }
}

/**
 * Generates keyframes for a circular filling animation.
 * 
 * @returns An array of animation style metadata for a 360-degree rotation.
 * Each keyframe adjusts the conic gradient angle.
 */
function generateKeyframes(): import("@angular/animations").AnimationStyleMetadata[] {
  const totalFrames = 360;
  const keyframesArray = [];
  
  for (let i = 0; i <= totalFrames; i++) {
    const angle = 360 - i;  // Reverse the angle calculation
    const offset = i / totalFrames;
    if(i >= 360){
      keyframesArray.push(
        style({ 
          background: `conic-gradient(#fff ${angle}deg, transparent ${angle}deg)`, 
          offset: offset,
          zIndex: 0 
        })
      );
    }
    else{
    keyframesArray.push(
      style({ 
        background: `conic-gradient(#fff ${angle}deg, transparent ${angle}deg)`, 
        offset: offset,
        zIndex: 3 
      })
    );
  }
  }
  return keyframesArray;
}