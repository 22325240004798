/**
 * Class which exports the required validation pattern and error messages which is used in user component
 */
export class UserValidators {
    validators = {
        firstNameRequired: "First Name is required.",
        lastNameRequired: "Last Name is required.",
        emailIdRequired: "Email Id is required",
        emailIdPattern: "Invalid Email Id",
    }
}

export const USER_SNACKBAR_MESSAGES = {
    userFetchError: "Failed to fetch users",
    userUpdateSuccess: "User updated successfully",
    userUpdateFailed: "Failed to update user",
    userCreateSuccess: "User created successfully",
    userCreateFailed: "Failed to create user",
    userDeleteFailed: "Failed to delete user"
}