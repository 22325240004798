import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  constructor() { }
  /**
   * Stores the headers for different URLs.
   * @type {{ [url: string]: { [key: string]: string } }}
   */
  headers: { [url: string]: { [key: string]: string } } = {}
  /**
   * Sets a specific header for the given URL.
   * @param {string} url - The URL for which the header should be set.
   * @param {string} key - The key of the header to be set.
   * @param {string} value - The value of the header to be set.
   */
  public setheader(url: string, key: string, value: string):void {
    if (this.headers && this.headers.hasOwnProperty(url)) {
      this.headers[url][key] = value;
    }
    else {
      this.headers[url] = { [key]: value };
    }
  }
  /**
   * Clears a specific header for the given URL.
   * @param {string} url - The URL for which the header should be cleared.
   * @param {string} key - The key of the header to be cleared.
   */
  public clearHeaders(url: string, key: string):void {
    if (this.headers && this.headers.hasOwnProperty(url) && this.headers[url].hasOwnProperty(key)) {
      delete this.headers[url][key]
    }
  }
  /**
 * Returns the headers to be used for an HTTP request to the specified URL.
 * @param {string} url - The URL for which the headers are being prepared.
 * @returns {{ [key: string]: string }} - An object representing the headers to be included in the HTTP request.
 */
  public getHeaders(url: string):{ [key: string]: string } {
    if (this.headers && this.headers.hasOwnProperty(url)) {
      return this.headers[url];
    }
    else {
      return this.headers['default'];
    }
  }
}
