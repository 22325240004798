import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TemplateListComponent } from "../../../libs/shared/src/lib/components/template-list/template-list.component";
import { AddEditTemplateComponent } from "../../../libs/shared/src/lib/components/add-edit-template/add-edit-template.component";

@NgModule({
  declarations: [  
    ],
  imports: [
    CommonModule,
    TemplateListComponent,
    AddEditTemplateComponent
]
})
export class ZenMarketingModule { }
