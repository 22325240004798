<div *ngIf="!initialLoader" class="common-header">
  <app-common-header (methodClicked)="headerEvent($event)" [buttonInfo]="button" [heading]="heading" ></app-common-header>
</div>
<div *ngIf="initialLoader" class="common-card-style">
  <app-skeleton-loader [formLoader]="false"></app-skeleton-loader>
</div>
<div fxLayout="row" *ngIf="!initialLoader" class="common-card-style">
    <div fxFlex="100%" >
       <app-common-card [buttonInfo]="button" (methodClicked)="headerEvent($event)" [dataNotFoundMessage]="noDataMessage" [columns]="columns" [dataSource]="data" [actionSetting]="actions" [hasSearchField]="true" 
       [searchSetting]="searchSettingData" [hasPagination]="true" (actionClicked)="openDialog($event)"   (paginationChange)="handlePaginationChange($event)"  [paginationData]="paginationData" [loading]="languagesListLoader"
          (searchChange)="handleSearchChange($event)" 
          ></app-common-card>
    </div>
</div>
<ng-template #quickEditDisplay>
    <div class="dialog-container" fxLayout="column">
      <h5 class="dialog-header" mat-dialog-title style="padding:0px 20px 10px 20px">
        {{action == 'view' ? 'Visual Language' : action == 'Edit' ? "Personalize Language" : "New Language"}}        <mat-icon (click)="dialogClose();" class="dialogCloseIcon">close</mat-icon>
      </h5> 
      <div mat-dialog-content style="padding: 10px 30px">
        <form fxLayout="column" fxLayoutGap="10px" *ngIf="languageFormGroup" [formGroup]="languageFormGroup">
          <div fxLayoutGap="2%" fxLayout="column">
          <mat-label>Language Name *</mat-label>
          <mat-form-field appearance="outline">
            <input (input)="codeGenerator(languageData.value)" #languageData placeholder="Enter language name" matInput formControlName="language" required data-control="language" trimVal>
            <mat-error data-control="nameError" *ngIf="languageFormGroup?.get('language')?.hasError('required')">
              Language Name is required.
            </mat-error>
          </mat-form-field>
          </div>
          <div fxLayoutGap="2%" fxLayout="column">
          <mat-label>Language Code</mat-label>
          <mat-form-field appearance="outline">
            <input placeholder="Enter language code" matInput formControlName="languageCode" required data-control="languageCode" trimVal>
            <mat-error data-control="nameError" *ngIf="languageFormGroup?.get('languageCode')?.hasError('required')">
              Language Code is required.
            </mat-error>
          </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="center center">
            <button class="cancel-button btn" type="button" mat-button type="button" mat-button (click)="dialogClose()"
            data-control="quickEditCancel">Cancel</button>
            <button class="btn" type="button" mat-button data-control="quickEditUpdate" (click)="onEditMode ? updateLanguage(languageFormGroup.value) : createLanguage(languageFormGroup.value)">
              {{action == 'Create' ? 'Create' : 'Update'}}
            </button>
          </div>
        </form>
        </div>
        <br>
      </div>
  </ng-template>