import { CommonCardColorSchema, SortSetting, Typography } from "../models/common-card-data.model";

/**
 * Enum representing the types of columns available.
 * @readonly
 * @enum {string}
 */
export enum ColumnType {
  /** Represents an image column */
  Image = 'image',
  /** Represents a text column */
  Text = 'text',
  /** Represents a checkbox column */
  Checkbox = 'checkbox',
  /** Represents a date column */
  Date = 'date',
  /** Represents a actions column */
  Action = 'action',
  /** Represents a toggle column*/
  Toggle = 'toggle',
  /**Represents a chip column */
  Chip = 'chip',
  /**Represents a dropdown column */
  DropDown='dropdown'
}

/**
 * Default color schema for common cards.
 * @type {Readonly<CommonCardColorSchema>}
 */
export const DEFAULT_COMMON_CARD_COLOR_SCHEMA: Readonly<CommonCardColorSchema> = {
  // headerBackground: '#009668',
  // headerColor: '#ffffff',
  // rowBackground: '#ffffff',
  // rowColor: '#000000',
  // evenRowBackground:'#f4f4f4',
  // evenRowColor:'#000000'
  headerBackground: '',
  headerColor: '',
  rowBackground: '',
  rowColor: '',
  evenRowBackground:'',
  evenRowColor:''
};

/**
 * Default sorting settings.
 * @type {Readonly<SortSetting>}
 */
export const DEFAULT_SELECTEDSORT: Readonly<SortSetting> = {
  label: '',
  field: '',
  direction: 'asc'
};

/**
 * Default typography settings for headings and body text.
 * @type {Readonly<Typography>}
 */
export const DEFAULT_TYPOGRAPHY: Readonly<Typography> = {
  heading: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '1.6',
    letterSpacing: '0.1em',
    textAlign: '',
    boxShadow:'0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)'
  },
  row: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '15px',
    fontWeight: '400',
    lineHeight: '1.5',
    letterSpacing: '0.5px',
    textAlign: 'center',
    color:'black'
  }
};

/**
 * Enum representing the types of control available.
 * @readonly
 * @enum {string}
 */
export enum FilterType {
  /* Represents an date control */
  Date = 'date',
  /* Represents an select control */
  Select = 'select',
  /* Represents an text control */
  Text = 'text'
} 

/**
 * An object containing no records found message.
 * @type {Readonly<messages>}
 */
export const NO_RECORD_FOUND_MESSAGES ={
  NO_RECORD_APPLICATION : "No Applications Found",
  NO_RECORD_REPORTS : "No Reports Found",
  NO_RECORD_TEMPLATE : "No Templates Found",
  NO_RECORD_USER : "No User Found",
  NO_RECORD_CUSTOMERS : "No Customers Found",
  NO_RECORD_LOGS : "No Logs Found",
  NO_RECORD_LANGUAGES : "No Languages Found",
  NO_RECORD_CONFIGURATION : "No Configuration Found",
  NO_RECORD_CATEGORIES: "No Categories Found",
  NO_RECORD_APPLICATION_DESCRIPTION : "Currently, there are no applications available. Create a new one to get started.",
  NO_RECORD_TEMPLATE_DESCRIPTION : "Currently, there are no templates available. Create a new one to get started.",
  NO_RECORD_USER_DESCRIPTION : "Currently, there are no users available. Create a new one to get started.",
  NO_RECORD_CUSTOMERS_DESCRIPTION : "Currently, there are no customers available. Create a new one to get started.",
  NO_RECORD_LANGUAGES_DESCRIPTION : "Currently, there are no languages available. Create a new one to get started.",
  NO_RECORD_CONFIGURATION_DESCRIPTION : "Currently, there are no configurations available. Create a new one to get started.",
  NO_RECORD_CATEGORIES_DESCRIPTION : "Currently, there are no categories available. Create a new one to get started.",
  NO_RECORD_LOGS_DESCRIPTION : "Currently, there are no logs available.",
  NO_RECORD_REPORTS_DESCRIPTION : "Currently, there are no reports available."
}

export const REGION = ['ap-south-1' , 'us-west-2']