import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BUTTON_TEXT, DIALOG_COLOR_AND_IMAGE, DIALOG_HEADER, DIALOG_TYPE } from '../../constants/common-dialog-data.constants';
import { ButtonText, DialogColor, DialogHeader, DialogType } from '../../models/common-dialog-data.model';
import * as _ from 'lodash';
import { FormControl, UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-common-dialog',
  templateUrl: './common-dialog.component.html',
  styleUrls: ['./common-dialog.component.scss']
})
export class CommonDialogComponent {
  /**
   * @type {DialogType}
   * Assigning the constant DIALOG_TYPE to the dialogType variable.
   */
  dialogType: DialogType = DIALOG_TYPE;
  /**
   * @type {Array<DialogColor>}
   * Assigning the constant DIALOG_COLOR_AND_IMAGE to the dialogColorAndImage variable.
   */
  dialogColorAndImage: Array<DialogColor> = DIALOG_COLOR_AND_IMAGE;
  /**
   * @type {ButtonText}
   * Assigning the constant BUTTON_TEXT to the ButtonText variable.
   */
  buttonText: ButtonText = BUTTON_TEXT;
  /**
   * @type {DialogHeader}
   * Assigning the constant DIALOG_HEADER to the dialogHeader variable.
   */
  dialogHeader: DialogHeader = DIALOG_HEADER;
   /**
  * @type {FormControl}
  * Form control used to store get password data 
  */
  passwordField : FormControl = new FormControl('') ;
    /**
   * This boolean variable tracks whether a password types.
   * @type {boolean}
   */
    showPassword: any = {};
  /**
   * Component constructor which is used to inject the required services.
   * Constructor that injects MAT_DIALOG_DATA for accessing data passed to the dialog
   */
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
  
  /**
   * Angular life cycle hooks
   */
  ngOnInit() { 
    const data = this.dialogColorAndImage.find(v => v.name === this.data.actionType);
    if (data && data.color){
      // this.data.color = data?.color;
      // this.data.circle1 = data?.outerCircleColor1;
      // this.data.circle2 = data?.outerCircleColor2;
      // this.data.circle3 = data?.outerCircleColor3;
      // this.data.image = data?.image;
    }
    if(this.data?.actionType==='success'){
      this.data.header =  this.data?.header!==""?this.data?.header:this.dialogHeader?.success,
      this.data.image=this.data?.image??this.dialogHeader?.successImg,
      this.data.color = this.data?.color??'#19ae4a'
    }
    if(this.data?.actionType==='failure'){
      this.data.header =  this.data?.header!==""?this.data?.header:this.dialogHeader?.failure,
      this.data.image=this.data?.image??this.dialogHeader?.failureImg,
      this.data.color = this.data?.color??'#de393a'
    }
    if(this.data?.actionType==='confirmation'){
      this.data.header =  this.data?.header!==""?this.data?.header:this.dialogHeader?.confirmation,
      this.data.image=this.data?.image??this.dialogHeader?.confirmationImg,
      this.data.color = this.data?.color??'#3e32f7'
    }
    if(this.data?.actionType==='verification'){
      this.data.header =  this.data?.header!==""?this.data?.header:this.dialogHeader?.verification,
      this.data.image=this.data?.image??this.dialogHeader?.verificationImg,
      this.data.color = this.data?.color??'#3e32f7'
    }
    // else{
    //   // this.data.header =  this.dialogHeader.confirmation
    //   this.data.button.right = this.data.button.right;
    //   this.data.button.left = this.data.button.left;
    // }
  }
  lightenColor(hex: string, percent: number) {
    let [r, g, b] = hex.match(/\w\w/g)!.map(x => parseInt(x, 16));
    r = Math.round(r + (255 - r) * (percent / 100));
    g = Math.round(g + (255 - g) * (percent / 100));
    b = Math.round(b + (255 - b) * (percent / 100));
    return `rgb(${r}, ${g}, ${b})`;
  }
    /**
* Method which is used to define whether to display the password in text format or not.
* @param property hold the current form of the password.
*/
showPasswordType(isShowPassword: string) {
  if (this.showPassword) {
    this.showPassword[isShowPassword] = !this.showPassword[isShowPassword];
  }
}
}
