import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpRoutingService } from 'src/app/common/service/http-routing.service';
import { CategoryResponse, ReportResponse, customerReportResponse, httpParams } from '../models/reports.model';
import { getApplicationResponse, getUserListResponse } from 'src/app/zen-mail/Applications/models/applications.model';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  /*
   * Variable which is used to store endpoint url of the view report.
   */
  reportUrl = "microservice/mail/report"

  /**
   * component constructor which is used to inject the required services and modules.
   * @param httpClient To refer to the httpClient module to access the get and put methods.
   */
  constructor(private httpClient: HttpRoutingService) { }

  /**
   *Method which is used to get mail categories list.
   * @type { Observable<CategoryResponse>}
   */
  getMailCategories(): Observable<CategoryResponse> {
    return this.httpClient.getRequest<CategoryResponse>("microservice/mail/category");
  }

  /**
   * Method which is used to get mail report data.
   * @param paramData It has the query params of the Url.
   * @type { Observable<ReportResponse>}
   */
  getReportList(paramData: httpParams): Observable<ReportResponse> {
    return this.httpClient.getRequest(this.reportUrl , paramData);
  }

  /**
   *Method which is used to get mail report data.
   * @type { Observable<customerReportResponse>}
   */
  getMailReport(paramId: number): Observable<customerReportResponse> {
    return this.httpClient.getRequest(this.reportUrl +'/'+ paramId);
  }

  /**
   * Method which is used to get application list data.
   * @param paramData It has the query params of the Url.
   * @type { Observable<getApplicationList> }
   */
  getApplicationList(paramData: httpParams): Observable<getApplicationResponse> {
    return this.httpClient.getRequest('microservice/mail/application?', paramData);  
  }
  /**
  * Method which is used to get user list data from backend.
  * @param paramData It has the query params of the Url.
  * @type { Observable<any>}
  */
  getUserList(paramData: httpParams) : Observable<getUserListResponse> {
    return this.httpClient.getRequest('microservice/mail/user?', paramData);  
  }
}
