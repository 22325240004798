<div *ngIf="!loading" class="common-header">
    <app-common-header [heading]="dynamicTitle[action]" ></app-common-header>
</div>
<div class="loader-card-style" *ngIf="loading; else viewCustomer" >
    <div>
        <app-skeleton-loader [formLoader]="true" [module]="'application'" [isEdit]="action == 'edit'" [isView]="action == 'view'" [isCreate]="action == 'create'"></app-skeleton-loader>
    </div>  
</div>
<ng-template #viewCustomer>
<mat-card  class="add-edit-container common-card-style" fxLayout="column">
    <form *ngIf="applicationForm" (ngSubmit)="action == 'create' ? createApplication() : updateApplication()  " [formGroup]="applicationForm" fxLayout="column" fxLayoutGap="15px">
        <div fxLayout="column" fxLayoutGap="2%">
            <div fxLayout="row" fxLayoutGap="2%">
                <div fxLayoutAlign="start start" fxFlex="auto" [ngStyle]="{'padding-top':'20px'}" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="2%">
                    <h4 class="mat-card-header">Application Details </h4>
                    <p class="accessId" [ngStyle]="{'height':'65%'}" *ngIf="applicationData.applicationData?.accessId"><span [ngStyle]="{'padding-top':'3px'}">{{applicationData.applicationData?.accessId}}</span><span [ngStyle]="{'padding-top':'3px'}"><mat-icon [ngStyle]="{'font-size':'18px','color':'#3e32f7','padding-left':'5px'}" (click)="copyTemplateName(applicationData.applicationData?.accessId)">file_copy</mat-icon></span></p>
                </div>
                <div  fxFlex="auto" fxLayoutGap="30px" fxLayoutAlign="end center" fxLayoutAlign.xs="center end" fxLayout.xs="column">
                    <button type="button" class="cancel-button" mat-button (click)="cancel()" data-control="cancel-button">{{action == 'view' ? 'Back' : 'Cancel'}}</button>
                    <button type="Update"  *ngIf="action !== 'view' " class="view-logs-button btn" mat-button data-control="cancel-button">{{action == 'create' ? 'Create' : "Update"}}</button>
                </div>
            </div>
            <div>
                <hr [ngStyle]="{'border':'0.5px solid #e1e1e1'}">
            </div>
        </div>
        <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="space-between" fxLayoutGap.lt-sm="10px" fxFlex="100%">                
            <div  fxLayout="column" fxFlex="47.5%" fxFlex.lt-sm="100%" fxLayoutGap="2%">
                <mat-label>{{action == "view" ? "User Name" : "User Name *"}}</mat-label>
                <mat-form-field *ngIf="applicationForm.get('recipientDetailsId')" appearance="outline" class="form-container">
                    <mat-select placeholder="Select user name" formControlName="recipientDetailsId">
                        <mat-option *ngFor="let items of userData.recipient.rows" data-control="active-option" [value]="items.id">{{items?.lastName ? items?.firstName + ' ' +  items?.lastName : items?.firstName}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="applicationForm.get('recipientDetailsId')?.hasError('required')">User Name is required</mat-error>
                </mat-form-field>
            </div>
            <div  fxLayout="column" fxFlex="47.5%" fxFlex.lt-sm="100%" fxLayoutGap="2%">
                <mat-label>{{"Application Name"}}</mat-label>
                <mat-form-field appearance="outline" class="form-container">
                    <input type="text" matInput formControlName="applicationName" data-control="customer-name-input" placeholder="Enter application name">
                    <mat-error *ngIf="applicationForm.get('applicationName')?.hasError('required')">Application name is required</mat-error>
                </mat-form-field>
            </div>
            <div  fxLayout="column" fxFlex="47.5%" fxFlex.lt-sm="100%" fxLayoutGap="2%">
                <mat-label>{{"Description Name"}}</mat-label>
                <mat-form-field appearance="outline" class="form-container">
                    <input type="text" matInput formControlName="description" data-control="customer-name-input" placeholder="Enter description">
                    <mat-error *ngIf="applicationForm.get('description')?.hasError('required')">Description is required</mat-error>
                </mat-form-field>
            </div>
            <div  fxLayout="column" fxFlex="47.5%" fxFlex.lt-sm="100%" fxLayoutGap="2%">
                <mat-label>{{"Host URL"}}</mat-label>
                <mat-form-field appearance="outline" class="form-container">
                    <input type="text" matInput formControlName="appHostUrl" data-control="customer-name-input" placeholder="Enter host URL">
                    <mat-error *ngIf="applicationForm.get('appHostUrl')?.hasError('required')">Host url is required</mat-error>
                </mat-form-field>
            </div>
            <div  fxLayout="column" fxFlex="47.5%" fxFlex.lt-sm="100%" fxLayoutGap="2%">
                <mat-label>{{action == "view" ? "Origin URL" : "Origin URL *"}}</mat-label>
                <mat-form-field appearance="outline" class="form-container">
                    <input type="text" matInput formControlName="appOrginUrl" data-control="customer-name-input" placeholder="Enter origin URL">
                    <mat-error *ngIf="applicationForm.get('appOrginUrl')?.hasError('required')">Origin url is required</mat-error>
                </mat-form-field>
            </div>
            <div  fxLayout="column" fxFlex="47.5%" fxFlex.lt-sm="100%" fxLayoutGap="2%">
                <mat-label>{{action == "view" ? "Language" : "Language *"}}</mat-label>
                <mat-form-field appearance="outline" class="form-container">
                    <mat-select placeholder="Select language" formControlName="languageArray" multiple>
                        <mat-option *ngFor="let items of languageData.data.rows" data-control="active-option" [value]="items.id">{{items.language}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="applicationForm.get('languageArray')?.hasError('required')">Language is required</mat-error>
                </mat-form-field>
            </div>
        </div>
    </form>

</mat-card>
</ng-template>