/**
 * Class which exports the required validation pattern and error messages which is used in add-edit-configuration-component
 */
export class ConfigurationValidaorConstants {
    validators = {
        userNameRequired: "User Name is required",
        applicationNameRequired: "Application Name is required",
        categoryRequired: "Category is required",
        serverRequired: "Server is required",
        hostRequired: "Host Url is required",
        userMailRequired: "User Mail is required",
        userMailPattern: "Invalid Email Id",
        passwordRequired: "Password is required",
        emailIdRequired: "From Email Id is required",
        emailIdPattern: "Invalid Email Id",
        portNumberRequired: "Port Number is required",
        portNumberPattern: "Port number must be positive digits or 0",
        portNumberMaxRange: "Port Number must be between 0 and 65535",
    }
}

export const CONFIGURATION_SNACKBAR_MESSAGES = {
    configurationFetchFailed: "Failed to fetch configurations",
    configurationApplicationFetchFailed: "Failed to fetch applications",
    configurationuUserFetchFailed: "Failed to fetch users",
    configurationCategoryFetchFailed: "Failed to fetch categories",
    configurationUpdateSuccess: "Configuration updated successfully",
    configurationUpdateFailed: "Failed to update configuration",
    configurationCreatedSuccess: "Configuration created successfully",
    configurationCreatedFailed: "Failed to create configuration",
    configurationFailedToDelete: "Failed to delete configuration"
}