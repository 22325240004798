import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonInfo, Heading } from '../../constants/common-header.constants';
import { Router } from '@angular/router';

@Component({
  selector: 'app-common-header',
  templateUrl: './common-header.component.html',
  styleUrls: ['./common-header.component.scss']
})
export class CommonHeaderComponent {
  /**
   * Emits an event when a button is clicked.
   * @type { string }
   */
  @Output() methodClicked = new EventEmitter<string>();

  /**
   * Holds information about the buttons to be displayed in the header.
   * @type { boolean }
   */
  @Input() buttonInfo!: ButtonInfo[];
  
  /**
   * Holds information about the header content.
   * @type { Heading }
   */
  @Input() heading!: Heading;
  a = 'mat-stroked-button'
  /**
   * Indicates whether the description text has ellipsis enabled.
   * @type { boolean }
   */
  descriptionEllipsis:boolean = false;

  /**
   * Indicates whether the title text has ellipsis enabled.
   * @type { boolean }
   */
  titleEllipsis:boolean = false;

  /**
   * component constructor which is used to inject the required services.
   * @param router To refer to the router to navigate to the report page.
   */
  constructor(private router: Router) {}

  /**
   * Updates the title ellipsis flag based on the event value.
   * @param event - Boolean indicating if title ellipsis is enabled.
   */
  isTitleEnable(event: any) {
    this.titleEllipsis = event;
  }

  /**
   * Navigates to a link when an icon is clicked.
   * Opens the provided link in a new tab or a default help link if none is provided.
   * @param event - Event object containing the icon link.
   */
  headerIconNavigate(event: { iconLink?: string }) {
    const url = event?.iconLink || 'https://help.getzenbasket.com/';
    window.open(url, "_blank");
  }

  /**
   * Updates the description ellipsis flag based on the event value.
   * @param event - Boolean indicating if description ellipsis is enabled.
   */
  isDescriptionEnable(event: any) {
    this.descriptionEllipsis = event;
  }

  /**
   * Emits a method or navigates based on the button click event.
   * @param event - Event object containing method or link information.
   */
  buttonClicked(event: { method?: string; link?: string }) {
    if (event?.method) {
      this.methodClicked.emit(event.method);
    } else if (event?.link) {
      this.router.navigate(['app/' + event.link]);
    }
  }
}
