<div class="drawer-container" fxLayout="row" >
  <!-- Static Toolbar on Left Side -->
  <div class="static-tool-bar" fxLayout="column" fxLayoutAlign="space-between center">
    <!-- Menus -->
    <div>
      <div (click)="drawer.toggle(); drawerToggle();" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="4px"
        class="drawer-toggle-container">
        <div class="drawer-toggle red"></div>
        <div class="drawer-toggle yellow"></div>
        <div class="drawer-toggle green"></div>
      </div>
      <div class="toolbar-menu">
        <ng-container *ngFor="let icons of menubarData; let i = index">
          <div (click)="menuDataLoading(i , icons)" [ngStyle]="{'background-color':icons?.iconBackgroundColor,'overflow':'hidden'}"
            [class.selected]="icons.isSelected" [matTooltip]=icons.heading matTooltipPosition="right" class="menu-icons"
            fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="17px">
            <mat-icon [ngStyle]="{'color': icons?.iconColor}" *ngIf="!icons?.iconImg;else iconImg">{{icons.iconName}}</mat-icon>
            <ng-template #iconImg>
            <img [src]="icons?.iconImg??''" alt="" height="22px" width="22px">
            </ng-template>
          </div>
        </ng-container>
      </div>
    </div>
    <!-- Logout & User details -->
    <div fxLayoutAlign="center center" fxLayout="column">
      <div class="logout-button" (click)="logout()" fxLayoutAlign="center center">
        <mat-icon>logout</mat-icon>
      </div>
      <div class="user-profile-image-container" [matMenuTriggerFor]="menu" fxLayoutAlign="center center">
        <img src="../../../../assets/icons8-user-96.png" alt="" class="user-profile-image">
      </div>
      <div fxLayoutAlign="center center" [ngStyle]="{'padding-top':'25px'}">
        <span class="version">{{'V | 1.0'}}</span>
      </div>
      <br>
    </div>

  </div>
  <!-- Mat Drawer -->
  <div fxFlex="100%" style="overflow: hidden;">
    <mat-drawer-container (backdropClick)="drawerToggle()">
      <!-- Menu list container -->
      <mat-drawer #drawer class="mat-drawer" [mode]="isMobile ? 'over' : 'side'" [opened]="isOpened">
        <app-menu-drawer>
        </app-menu-drawer>
      </mat-drawer>
      <!-- Content Container -->
      <mat-drawer-content class="mat-drawer-container" [ngClass]="isCollapsed ? 'collapsed' : 'expanded'"
        style="transform: all 0.3s;" fxLayout="column" *ngIf="menus">
        <div class="wholepage-container">
          <router-outlet></router-outlet>
        </div>
      </mat-drawer-content>
    </mat-drawer-container>
  </div>
</div>

<mat-menu #menu="matMenu" yPosition="below" xPosition="after" fxLayout="column">
  <div class="user-details" fxLayout="row" fxLayoutGap="10px">
    <div class="user-profile-image-container" fxLayoutAlign="center center">
      <img src="../../../../assets/icons8-user-96.png" alt="" class="user-profile-image">
    </div>
    <div fxLayout="column" fxLayoutAlign="center" *ngIf="userData">
      <div class="User-name">
        {{userData?.firstName}} {{userData?.lastName}}
      </div>
      <div class="user-mailid" *ngIf="userData?.emailactivity?.emailId">
        {{userData?.emailactivity?.emailId}}
      </div>
    </div>
  </div>
  <div class="mat-menu-divider" fxLayoutAlign=" center"></div>
  <div class="user-details" fxLayout="column" fxLayoutGap="5px">
    <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="10px" class="account-settings isHover" (click)="viewProfile()">
      <mat-icon class="mat-menu-icons">settings_account_box</mat-icon><span>Account Settings</span>
    </div>
    <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="10px" class="account-settings isHover" (click)="onChangePassword()">
      <mat-icon class="mat-menu-icons">person</mat-icon><span>Change password</span>
    </div>
  </div>
  <div>
    <div class="mat-menu-divider"></div>
    <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="10px" class="menu-logout isHover" (click)="logout()">
      <mat-icon class="mat-menu-icons">logout</mat-icon><span>Logout</span>
    </div>
  </div>
</mat-menu>

<!-- <div *ngIf="isDashboaderloader">
  <div fxLayout="row" [ngStyle]="{'padding':'10px'}">
    <div fxLayoutAlign="start center" fxLayout="column" fxLayoutGap="35%" [ngStyle]="{'width':'60px'}">
      <div fxLayout="row" fxLayoutGap="15%">
        <div class="dot1"></div>
        <div class="dot1"></div>
        <div class="dot1"></div>
      </div>
      <div fxLayoutGap="35%" fxLayout="column">
        <div class="dot2"></div>
        <div class="dot3"></div>
        <div class="dot3"></div>
      </div>
    </div>
    <div fxLayout="column" fxLayoutGap="10%" [ngStyle]="{'padding-top':'30px','padding-left':'30px','width':'330px'}">
      <div fxLayout="column" fxLayoutGap="5%" [ngStyle]="{'width':'85%'}">
        <div class="header1"></div>
        <div class="header2"></div>
      </div>
      <div fxLayout="column" fxLayoutGap="5%">
        <div class="header1"></div>
        <div class="header-containers"></div>
        <div class="header-containers"></div>
        <div class="header-containers"></div>
        <div class="header-containers"></div>
        <div class="header-containers"></div>
        <div class="header-containers"></div>
        <div class="header-containers"></div>
        <div class="header-containers"></div>
        <div fxLayout="row" fxLayoutAlign="end" [ngStyle]="{'width':'85%'}">
          <div class="region-containers"></div>
        </div>
      </div>
    </div>
    <div fxFlex="100%" fxLayout="column" fxLayoutGap="2%">
      <div>
        <div class="container1"></div>
      </div>
      <div fxLayout="row" fxLayoutGap="2%">
        <div class="container2"></div>
        <div class="container2"></div>
      </div>
      <div fxLayout="row" fxLayoutGap="2%">
        <div class="container2"></div>
        <div class="container2"></div>
      </div>
    </div>
  </div>
</div> -->