import { Injectable } from '@angular/core';
import { HttpRoutingService } from 'src/app/common/service/http-routing.service';
import { ApplicationId, CreateTemplateResponse, EmailResponse, GetAllParamsData, GetOneTemplateResponse, GetOneUpdatedData, LanguageResponse, TemplateApplicationResponse, TemplateCategoryResponse, TemplateCustomerResponse, TemplateResponse, UpdateResponse } from '../models/template.model';;
import { Observable, of } from 'rxjs';
import { GetOneCategoryResponse } from 'src/app/zen-mail/Categories/models/categories.model';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {
  /**
   * component constructor which is used to inject the required services and modules.
   * @param httpClient To refer to the httpClient module to access the get and put methods.
   */
  constructor(private httpClient: HttpRoutingService,
    private http: HttpClient
  ) { }
  /**
    * Method which is used to get user list data.
    * @param paramData It has the query params of the Url.
    * @type { Observable<TemplateResponse>}
    */
  getAllTemplateList(paramData: any): Observable<TemplateResponse> {
    return this.httpClient.getRequest('microservice/mail/template/all/new/raw', paramData);
  }
  /**
  *Method which is used to get mail template categories list.
  * @type { Observable<CategoryResponse>}
  */
  getTemplateCategories(categoryFilter?: { filterData: string }): Observable<TemplateCategoryResponse> {
    return this.httpClient.getRequest<TemplateCategoryResponse>("microservice/mail/category", categoryFilter);
  }
  /**
 *Method which is used to get mail template customer list.
 * @type { Observable<CategoryResponse>}
 */
  getTemplateCustomer(customerFilter: { [customerFilter: string]: any }): Observable<TemplateCustomerResponse> {
    return this.httpClient.getRequest("microservice/mail/recipientdetails", customerFilter);
  }
  /**
  *Method which is used to get mail template Application list.
  * @type { Observable<CategoryResponse>}
  */
  getTemplateApplication(): Observable<TemplateApplicationResponse> {
    return this.httpClient.getRequest<TemplateApplicationResponse>("microservice/mail/application");
  }
  /**
  *Method which is used to update the mail template.
  * @type { Observable<CategoryResponse>}
  */
  // updateTemplate(id: string, data: GetOneUpdatedData): Observable<UpdateResponse> {
  //   return this.httpClient.putRequest("microservice/mail/template/" + id + '/updatenew', data);
  // }
  private readonly APIUrl = environment.APIUrl;

updateTemplate(id: string, data: GetOneUpdatedData): Observable<HttpResponse<UpdateResponse>> {
    const url = `microservice/mail/template/${id}/updatenew`;
    const fullUrl = this.APIUrl + url?.replace(/#/g, "%23");
    return this.http.put<UpdateResponse>(fullUrl, data, {
        observe: 'response',
    });
}
  /**
*Method which is used to delete the mail template.
* @type { Observable<CategoryResponse>}
*/
  deleteTemplate(deleteId: number,password:string) {
    return this.httpClient.getRequest('microservice/mail/template/' + deleteId + '/delete',{password : password});
  }
  /**
* Method which is used to get languages data from backend.
*/
  getTemplateLanguage(applicaionId?: { filterData: string }): Observable<LanguageResponse> {
    return this.httpClient.getRequest('microservice/mail/languages', applicaionId);
  }
  /**
* Method which is used to create new template.
*/
  createTemplate(template: GetOneUpdatedData): Observable<CreateTemplateResponse> {
    return this.httpClient.postRequest('microservice/mail/template/createnew', template);
  }
  /**
* Method which is used to call particular template.
*/
  getOneTemplate(templateId: string, data: { recipientDetailsId: any }): Observable<GetOneTemplateResponse> {
    return this.httpClient.getRequest("microservice/mail/template/" + templateId + '/new', data);
  }
  /**
* Method which is used to send email.
*/
  sendEmail(emailDetails: EmailResponse): Observable<EmailResponse> {
    return this.httpClient.postRequest("microservice/mail/custom", emailDetails);
  }
  /**
  * Method which is used to get category details.
  * @param data It has the query params of the Url.
  * @type { Observable<string>}
  */
  getOneCategories(categoryId: number): Observable<GetOneCategoryResponse> {
    return this.httpClient.getRequest('microservice/mail/category/' + categoryId + '/get');
  }
    /**
  * Method which is used to get sub category details.
  */
  getAllSubcategories(){
    return this.httpClient.getRequest('microservice/mail/subCategory/get');
  }
  // revertTemplate(templateId:number){
  //  return this.httpClient.putRequest('microservice/mail/template/'+templateId+'/revertnew/raw') 
  // }
  revertTemplate(templateId: number): Observable<HttpResponse<any>> {
    const url = `microservice/mail/template/${templateId}/revertnew/raw`;
    const fullUrl = this.APIUrl + url?.replace(/#/g, "%23");
    return this.http.put<any>(fullUrl, {}, {
        observe: 'response',
    });
}
}
