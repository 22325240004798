<div [ngStyle]="{'padding':'10px 20px 10px 35px'}">
  <mat-card fxLayout="column" fxLayoutGap="2%" *ngIf="!isLoading"
    [ngStyle]="{'border-radius':'15px','background-color':'white','padding':'0px 20px','height':'570px'}">
    <div fxLayout="column" class="fixed-header">
    <div fxLayout="row" fxLayoutGap="2.5%">
      <div fxFlex="4%" fxLayout="row" fxLayoutAlign="center center">
            <img src="../../../../../assets/back.png" (click)="onBack()" alt="" [ngStyle]="{'cursor':'pointer','height':'25px','width':'25px'}">
      </div>
      <div fxFlex="70%" fxLayout="row" fxLayoutAlign="start center" [ngStyle]="{'padding-top':'15px'}">
        <h2>{{getOneMailData?.subject??'No Subject'}}</h2>
      </div>
      <div fxFlex="25%" fxLayout="row" fxLayoutGap="2%" fxLayoutAlign="end center">
        <mat-icon class="mat-icons" [matTooltip]="getOneMailData?.isMarkedSpam?'Not spam':'Report spam'" (click)="onSpamMarked(getOneMailData?.isMarkedSpam)">{{getOneMailData?.isMarkedSpam?'report_off':'report'}}</mat-icon>
        <mat-icon class="mat-icons" [matTooltip]="'Mark as unread'" (click)="onMarkUnread('unread')">drafts</mat-icon>
        <mat-icon class="mat-icons" [matTooltip]="'Delete'" *ngIf="getOneMailData?.isDeleted===false" (click)="onDelete()">delete_outline</mat-icon>
      </div>
    </div>
    <div fxLayout="row" fxLayout.lt-md="column" fxFlex="100%" fxLayoutGap="3%">
      <div fxLayout="row" fxFlex="65%" fxLayoutGap="4%">
        <img src="../../../../../assets/user.png" class="profile">
        <div fxLayout="column">
          <span [ngStyle]="{'font-weight':'600','font-size':'.875rem','color':'#1f1f1f'}">{{senderName?senderName:getOneMailData?.senderId}} <span *ngIf="senderName" [ngStyle]="{'font-weight':'400','font-size':'.75rem','color':'#5e5e5e'}">{{'<'+this.senderEmail+'>'}}</span></span>
          <span fxLayout="row" fxLayoutAlign="start center" [ngStyle]="{'color':'#5e5e5e','font-size':'.75rem'}"><span class="recipent">{{getOneMailData?.status==='send'?getOneMailData?.recipientId.includes(this.extractEmail(getOneMailData?.senderId))?'to me':('to'+' '+getOneMailData?.recipientId):'to '+getOneMailData?.recipientId}}</span><span><mat-icon [ngStyle]="{'cursor':'pointer','padding-top':'5px'}" [matTooltip]="'Show Details'" (click)="showMailDetails($event)">arrow_drop_down</mat-icon> <div *ngIf="isShowMailDetails" class="show-mail-details" fxLayout="column" fxLayoutGap="3%">
            <div *ngFor="let item of emailDetails">
              <div fxLayout="row" fxLayoutGap="3%">
                <span [ngStyle]="{ width: '25%','font-weight': '500', 'font-size': '11px','padding-left':'10px','color':'rgb(94, 94, 94)'}" fxLayout="row" fxLayoutAlign="start start">{{ item.name }}</span>
                <span [ngStyle]="{ width: '3%' }" fxLayout="row"fxLayoutAlign="center  center "> : </span>
                <span [ngStyle]="{ width: '70%', 'font-weight': '400', 'font-size': '12px', color: '#585858' }">
                      <ng-container *ngIf="item.name === 'to'; else otherValues">
                             <div *ngFor="let recipient of item.value;let i = index" [ngStyle]="{ color: '#585858', 'font-weight': '500' }">{{ recipient}}<span *ngIf="i < item.value.length - 1">,</span></div>
                      </ng-container>
                      <ng-template #otherValues> {{ item?.value }}<span *ngIf="item?.fromAddress" [ngStyle]="{ 'font-weight': '500', color: 'var(--primary-color)'}">{{' '+'<' + item.fromAddress + '>' }}</span>
                      </ng-template>
                </span>
              </div>
            </div>
          </div></span></span>
        </div>
        <span [ngStyle]="{'color':'var(--primary-color)','font-weight':'400','font-size':'11px'}"
          class="subscribe">Unsubscribe</span>
      </div>
      <div fxLayout="row" fxFlex="35%" fxLayoutGap="2%" fxLayoutAlign="end none">
        <span
          [ngStyle]="{'font-size':'12px'}">{{getOneMailData?.time_stamp?getFormattedDate(getOneMailData?.time_stamp):'-'}}</span>
        <span (click)="updateStarred()" *ngIf="!getOneMailData?.isDeleted"><mat-icon
            [ngStyle]="{'color':!starred?'gray':'gold','font-size':'21px','cursor':'pointer'}"
            [matTooltip]="'Not starred'">{{starred?'star':'star_border'}}</mat-icon></span>
        <span [ngStyle]="{'color':'var(--primary-color)','font-size':'21px','cursor':'pointer'}"
          [matTooltip]="'Reply'"><mat-icon>reply</mat-icon></span>
        <span [ngStyle]="{'color':'var(--primary-color)','font-size':'21px','cursor':'pointer'}"
          [matTooltip]="'More'"><mat-icon (click)="viewMore($event)">more_horiz</mat-icon>
          <div *ngIf="isViewMore" class="view-more-details" fxLayout="column" fxLayoutGap="2%">
            <ng-container *ngFor="let action of actions">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4%" class="hover-menu" (click)="action.onClick()" *ngIf="action.condition !== true">
              <mat-icon [ngStyle]="{'font-size': '20px'}">{{ action.icon }}</mat-icon>
              <span>{{ action.label }}</span>
            </div>
           </ng-container>
          </div>
        </span>
      </div>
    </div>
  </div>
    <div fxLayout="column" fxLayoutGap="2%" class="scrollable-content" >
      <div fxLayout="row" id="card-content">
        <div [ngStyle]="{'width':'100%'}">
          <div [innerHTML]="mailBody"></div>
        </div>
      </div>
    </div>
  </mat-card>
  <mat-card class="skeleton-header" fxLayout="column" fxLayoutGap="2%" *ngIf="isLoading"
  [ngStyle]="{'border-radius':'15px','background-color':'white','height':'570px','overflow':'hidden'}">
    <div fxLayout="column" fxFlex="100%" fxLayoutGap="2%">
      <div>
        <div class="skeleton-header-label0"></div>
      </div>
    <div fxLayout="row"  fxFlex="100%" fxLayoutGap="3%" fxLayoutAlign="center center">
        <div class="skeleton-header-label1"></div>
        <div class="skeleton-header-label2"></div>
        <div class="skeleton-header-label3"></div>
    </div>
    <div>
      <div class="skeleton-header-label4"></div>
    </div>
  </div>
  </mat-card>
</div>