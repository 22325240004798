<div>
  <div [ngStyle]="{'padding':'20px 20px 0px 20px'}" fxLayout="column">
    <div fxLayout="column" class="fixed-header">
      <div fxLayout="row" fxLayoutGap="3%">
        <div [ngStyle]="{'width':'10%'}">
          <div class="compose" (click)="compose.emit('')" [matTooltip]="'Compose new mail'" fxLayout="row" fxLayoutGap="1%">
           <span [ngStyle]="{'font-size':'13px','color':'white','word-break':'break-word','font-weight':'500'}">Compose</span>
          </div>
        </div>
        <div fxLayout="row" *ngIf="hasSearchField" [ngStyle]="{'width':'60%'}">
          <form [ngStyle]="{'width':'100%'}">
            <mat-form-field appearance="outline" fxLayout="column" [ngClass]="allData.length===0?'disabled-select':''">
              <div fxLayout="row">
                <input matInput [placeholder]="(searchSetting && searchSetting.placeHolder) || ''"
                  [formControl]="searchQuery" [ngStyle]="{'font-size':'14px','font-weight':'500'}">
                <mat-icon data-control="searchIcon" matSuffix
                  [ngStyle]="{'color':'var(--primary-color)','padding-right':'7px'}">search</mat-icon>
              </div>
            </mat-form-field>
          </form>
        </div>
        <div [ngStyle]="{'width':'25%','margin-right':'-2%'}">
          <ng-container *ngIf="hasPagination">
            <div [ngStyle]="{'width':'120%'}">
              <mat-paginator [length]="paginationData.count" [pageSize]="20" [pageSizeOptions]="[3]"
                [hidePageSize]="true" [showFirstLastButtons]="false" aria-label="Select page"
                (page)="onPageChange($event)" [pageIndex]="paginationData.offset / paginationData.limit">
              </mat-paginator>
            </div>
          </ng-container>
        </div>
        <div *ngIf="sortSetting && sortSetting.length > 0" [ngStyle]="{'width':'5%','text-align':'end','padding-top':'7px'}">
          <mat-icon class="sort-icon" [matTooltip]="'sort'" [matMenuTriggerFor]="menu" data-control="sortIcon"
            matSuffix [ngClass]="allData.length===0?'disabled-select':''">swap_vert</mat-icon>
          <mat-menu #menu="matMenu">
            <ng-container *ngFor="let sortData of sortSetting; let i=index">
              <button (click)="onSortChange(sortData,i)" mat-menu-item
                [ngStyle]="{'color':sortData.color??'black'}">{{sortData.label}}</button>
            </ng-container>
          </mat-menu>
        </div>
      </div>
      <!-- <div>
        <mat-tab-group [selectedIndex]="selected.value" (selectedIndexChange)="selected.setValue($event)">
          <mat-tab *ngFor="let tab of tabs; let index = index">
            <ng-template mat-tab-label>
              <mat-icon [ngStyle]="{'cursor':'pointer','font-size':'20px'}">{{tab?.icon}}</mat-icon>
              {{tab?.field}}
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </div> -->
    </div>
    <div [ngStyle]="{'background-color':'white','border-radius':'4px'}" class="scrollable-content">
      <div *ngIf="dataSource && dataSource.length > 0; else noRecordsFound">
        <ng-container>
          <div *ngFor="let item of displayData; let itemIndex = index" fxLayout="column" fxLayoutGap="0%">
            <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="1%" [ngClass]="{
              'selected-background': (isSelected(item.id) && isSingleCheckboxChecked[itemIndex]),
              'transparent-background': !isSelected(item.id),
              'unreadMail-Background-color':!isSelected(item.id) && item?.read_status==='unread',
              'hover-container': onHover
            }" 
            [ngStyle]="{'height':'40px'}" [ngStyle.xs]="{'height':'100%'}" class="hover-shadow">
              <div *ngFor="let column of columns; let i = index"
                [fxFlex]="column?.onHover ? '0%' : (column?.columnWidth ? column?.columnWidth : (100 / flexibleColumnCount) + '%')"
                fxFlex.xs="100%"
                [style]="{'color': column?.color, 'text-align': column.columnAlign ?? 'center', 'display': 'flex', 'padding': column.isDefault == true && item.id == 0 ? '5px 0px' : '', 'place-content': column.columnAlign ?? 'center'}"
                [ngStyle.xs]="{'color': column?.color, 'text-align': 'center', 'display': 'flex', 'place-content': 'center'}"
                [ngClass]="column?.class ? column?.class : ''">
                <div fxLayout="row"  [ngStyle]="{'overflow':'hidden'}">
                  <ng-container [ngSwitch]="column.type" fxLayoutGap="2%">
                    <!-- Checkbox type -->
                    <ng-container *ngSwitchCase="columnType.Checkbox">
                      <mat-checkbox *ngIf="!loading; else loader" (click)="$event.stopPropagation()"
                      (change)="singleCheckBoxClick($event, item, itemIndex)"
                      [checked]="isSingleCheckboxChecked[itemIndex]" [disabled]="column?.disable"
                      [aria-label]="item[column.field]" [attr.data-control]="column.field + i"
                      [ngStyle]="{'bottom':'2px'}" [ngStyle.lt-md]="{'bottom':'2px','right':'5px'}" [ngStyle.lt-sm]="{'bottom':'2px'}">
                    </mat-checkbox>
                    </ng-container>

                    <!-- Icons type -->
                    <ng-container *ngSwitchCase="columnType.Icons">
                      <div fxLayout="column" fxLayoutAlign="center center">
                        <mat-icon *ngIf="!loading; else loader"
                          [ngStyle]="{'color': item[column.field] ? (column?.valueIconColor ? column?.valueIconColor : 'gray') : 'gray','font-size':'21px'}"
                          (click)="onIconClickEvent($event, item, column)">
                          {{ item[column.field] ? column?.valueIconSymbol : column?.DefaultIconSymbol }}
                        </mat-icon>
                      </div>
                    </ng-container>

                    <!-- Text type -->
                    <ng-container *ngSwitchCase="columnType.Text">
                      <div fxLayout="column" fxLayoutAlign="center center" (click)="onClick(item)">
                        <div *ngIf="!loading; else loader"
                          [attr.data-control]="column.field + i"
                           fxLayout="row" class="line-display" [ngClass]="{'small-width': column?.columnWidth && parseWidth(column.columnWidth) < 30, 'large-width': column?.columnWidth && parseWidth(column.columnWidth) >= 30}">
                          <span
                            [ngStyle]="{'color': item[column.isOpened] ? column?.afterOpenedColor :column?.beforeOpenedColor,'font-size':item[column.isOpened]?'14px':'14px','font-weight':item[column.isOpened]?'400':'500'}"
                            >{{extractContent(item[column.field])}}</span>
                            <span *ngIf="column?.subField">{{' - '}}</span>
                          <span [ngStyle]="{'color':'gray'}"
                        *ngIf="column?.subField">{{extractContent(item[column?.subField??""])}}</span>
                        </div>
                      </div>
                    </ng-container>

                    <!-- Date type -->
                    <ng-container *ngSwitchCase="columnType.Date">
                      <div *ngIf="!loading; else loader" [matTooltip]="item[column.field]" [attr.data-control]="column.field + i" fxLayout="column"
                        (click)="onClick(item)" fxLayoutAlign="center center"
                        [ngStyle]="{'font-weight':item[column.isOpened]?'400':'500','font-size':'13px','color': item[column.isOpened] ? column?.afterOpenedColor :column?.beforeOpenedColor}">
                        {{ item[column.field] ? getFormattedDate(item[column.field]) : '-' }}
                      </div>
                    </ng-container>

                    <!-- Action type -->
                    <ng-container *ngSwitchCase="columnType.Action">
                      <div *ngIf="!loading; else loader" [ngStyle]="{'padding-left':onHover?'10px':'0px'}" fxLayout="row" fxLayoutGap="2%">
                        <button *ngFor="let action of item[column.field]" [attr.data-control]="column.field + i"
                          class="action-button" [matTooltip]="action.tooltip"
                          [ngStyle]="{'color': action?.color ? action?.color : '#3498DB'}"
                          (click)="onAction(action.method, item)" [ngClass]="action?.disable?'icon-disable':''">
                          <i class="material-icons" [ngStyle]="{'font-size': '22px'}"
                            *ngIf="!(action.icon === 'delete' && item.id === 0)">
                            {{ action.icon }}
                          </i>
                        </button>
                      </div>
                    </ng-container>

                  </ng-container>
                  <ng-template #loader>
                    <div class="skeleton-list" [ngStyle]="{'width':column.type==='checkbox' || column.type==='icons'?'15px':'100px'}" [ngStyle.xs]="{'padding-top':'6px','padding-bottom':'6px'}">
                      <div class="skeleton-avatar1" [ngStyle]="{'margin-left':column.type==='checkbox'|| column.type==='icons'?'10px':''}" [ngStyle.xs]="{'height':'25px'}"></div>
                    </div> 
                    </ng-template>
                </div>
              </div>
            </div>
            <div class="divider" *ngIf="!(displayData.length=== itemIndex + 1)"></div>
          </div>
        </ng-container>
      </div>
      <ng-template #noRecordsFound>
        <div *ngIf="!loading" class="no-record-found" data-control="noRecordsFound" fxLayout="column"
          fxLayoutAlign="center center" fxLayoutGap="3%">
          <img src="../../../../assets/no-email.webp" alt="noRecordsFound">
          <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="2%">
            <div class="no-record-found__text">{{searchQuery.value ||allData.length!==0?'No Mails Found!':allData.length===0?'No New Mails Yet!':''}}</div>
            <div class="no-record-found-description" *ngIf="!searchQuery?.value && allData.length===0">It looks like your inbox is clear at the moment. Check back later or refresh to see if any new messages arrive.</div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>