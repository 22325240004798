<div class="profile-container" fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="30px" fxFlex="100%" fxLayoutAlign="space-around">
    <div fxLayout="column" fxFlex="70%" fxFlex.lt-sm="auto" fxFlex.lt-md="auto" fxLayoutGap="30px" fxFlexOrder.lt-sm="2">
        <div fxLayout="row" class="mobile" fxLayout.lt-sm="column" fxLayout.lt-md="column" fxLayoutGap="5%">
            <mat-card fxLayout="column" fxLayoutAlign="start start" fxFlex="48%" fxFlex.lt-sm="auto" fxFlex.lt-md="auto" fxLayoutGap="15px" class="address-card-container">
                <div fxLayoutGap="5px" fxLayout="column">
                    <span class="address-header">Address</span>
                    <hr class="header-divider">
                </div>
                <div class="address">
                    {{user.details.address}}
                </div>
            </mat-card>
            <mat-card fxFlex="48%" fxFlex.lt-sm="auto" fxFlex.lt-md="auto" class="subscription-card-container" fxLayout="column"  fxLayoutGap="20px">
                <div class="subscription-header">Subscription</div>
                <div>
                    <div class="subscription-name">{{user.subscrtiption.name}}</div>
                    <span class="subscription-price">{{user.subscrtiption.price}}</span>

                </div>
                <div class="subscription-notice" *ngIf="!user.subscrtiption.isPro" fxLayoutAlign="end center"><span class="subscription-notice-text">Upgrade to Pro Now</span></div>
                <div fxLayout="row" fxLayoutAlign="space-between">
                    <span class="subscription-benefits-text" fxLayoutAlign="start center">View Benefits</span>
                    <button class="btn subscription-button" mat-raised-button>Subscribe</button>
                </div>
            </mat-card>
        </div>
        <mat-card fxFlex="100%" fxFlex.lt-sm="auto" class="settings-card-container">
            <form *ngIf="settingsForm" [formGroup]="settingsForm" (ngSubmit)="onSave()" fxLayout="column" fxLayoutGap="20px">
                <div fxLayoutGap="5px" fxLayout="column">
                    <span class="settings-header">Settings</span>
                    <hr class="header-divider">
                </div>
                <div class="region-container" fxLayout="row" fxLayoutAlign="space-between" fxFlex="100%" fxLayoutGap="10px">
                    <div class="region-text-container" fxLayout="column" fxLayoutGap="10px" fxFlex="75%" fxFlex.lt-sm="auto">
                        <div class="region-header">Region</div>
                        <div class="region-description">{{"Choose a default region (Current Region: " +currentSelected+")"}}</div>
                    </div>
                    <div class="region" fxLayoutAlign="end center" fxFlex="25%" fxFlex.lt-sm="auto">
                        <mat-form-field appearance="fill" class="region-data">
                            <mat-select [(value)]="selectedOption" formControlName="region" (selectionChange)="onSelectionChange($event)">
                                <mat-select-trigger *ngIf="selectedOption">
                                    <div fxLayoutAlign="center" fxLayout="row" class="selected-region">
                                        <img [src]="selectedOption.countryImage" class="region-country-image">
                                    </div>
                                </mat-select-trigger>
                                <mat-option *ngFor="let option of options" [value]="option" fxLayoutAlign="center center" class="mat-options">
                                    <div fxLayoutAlign="center center" fxLayout="row">
                                        <img [src]="option?.countryImage" alt="" class="region-country-image">
                                    </div>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutGap="20px">
                    <!-- <button class="btn settings-cancel-button" mat-raised-button>Cancel</button> -->
                    <button type="submit" style="background-color: var(--primary-color); color: white" class="btn settings-save-button" mat-flat-button>Save</button>
                </div>
            </form>
        </mat-card>
    </div>
    <mat-card class="profile" fxFlex="30%" fxFlex.lt-sm="auto" fxFlexOrder.lt-sm="1">
        <div fxLayout="column" fxLayoutAlign="center center">
            <img src="../../assets/profile-background.jpg" width="100%" height="60px">
            <img src="../../assets/default-profile.jpg" class="profile-image" width="80px" height="80px">
        </div>
        <div class="user-details-container">
            <div class="user-name-container" fxLayoutAlign="center center" *ngIf="userData">
                <span class="user-name">{{userData.firstName}} {{userData.lastName}} </span>
            </div>
            <div class="details-container" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="15px">
                <div class="mail-container" *ngIf="userData?.emailactivity?.emailId" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                    <mat-icon class="icon mail-icon">mail</mat-icon>
                    <span class="icon-text mail-text">{{userData?.emailactivity?.emailId}}</span>
                </div>
                <div class="phone-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                    <mat-icon class="icon phone-icon">stay_current_portrait</mat-icon>
                    <span class="icon-text phone-text">{{user.details.phone}}</span>
                </div>
                <div class="mail-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                    <mat-icon class="icon office-icon">location_city</mat-icon>
                    <span class="icon-text office-text">{{user.details.office}}</span>
                </div>
                <div class="mail-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                    <mat-icon class="icon job-icon">work</mat-icon>
                    <span class="icon-text role-text">{{user.details.workStatus}}</span>
                </div>
            </div>
            <!-- <div class="divider-container" fxLayoutAlign="center center">
                <hr class="divider">
            </div>
            <div class="social-header-container" fxLayouAlign="start start">
                <span class="social-text">{{"Social"}}</span>
            </div> -->
        </div>
    </mat-card>
</div>