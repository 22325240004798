import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { catchError, debounceTime, distinctUntilChanged, map, Observable, of, switchMap, timer } from 'rxjs';
import { EMAIL_DOMAIN } from '../constant/auth.constants';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor(private authService: AuthService) { }
   /**
   * method for custom validation
   * Check whether the email exists in db or not for admin from lambda
   */
  emailExistValidator(): AsyncValidatorFn {
    return (
      control: AbstractControl
    ):
      | Promise<ValidationErrors | null>
      | Observable<ValidationErrors | null> => {
      return timer(500).pipe(
        switchMap(() => {
          const email = `${control.value}${EMAIL_DOMAIN}`;
          return this.authService.checkEmailExist((email).toLowerCase()).pipe(
            map((response:any) => {
              return response && response['isAlreadyExists']
                ? { emailExists: true }
                : null;
            })
          );
        })
      );
    };
  }
  
}
