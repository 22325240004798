import { Component } from '@angular/core';
import { AuthService } from '../../auth/service/auth.service';
import { MatSelectChange } from '@angular/material/select';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CommonSnackbarService } from '../../shared/service/common-snackbar.service';
import { SNACKBAR_MESSAGES, SNACKBAR_HEADING, SNACKBAR_TYPE } from '../../shared/constants/common-snackbar-data.constants';
import { SelectedOption } from '../models/view-profile.model';

@Component({
  selector: 'app-view-profile',
  templateUrl: './view-profile.component.html',
  styleUrls: ['./view-profile.component.scss']
})
export class ViewProfileComponent {
  /**
   * Options array for region selection. Each option contains the value, viewValue (displayed text),
   * countryImage (path to country flag), and sortValue (used for sorting purposes).
   */
  options = [
    { value: 'ap-south-1', viewValue: 'INDIA', countryImage:'../../../../assets/india.jpg' ,sortValue:'IN' },
    { value: 'us-west-2', viewValue: 'USA', countryImage:'../../../../assets/USA.png' ,sortValue:'US' }
  ];

  user = {
    details: {
      name: "Antilin Stanish",
      mail: "antilinstanish@gmail.com",
      phone: "+91 9876543210",
      office: "Centizen",
      workStatus: "Developer",
      address: "784 Kaylah Ridge, Colleenburgh, Florida - 79293, Gibraltar."
    },
    subscrtiption: {
      name: "BASIC",
      price: "5$/month",
      isPro: false
    }
  }

  /** 
   * Holds the currently selected option from the options array 
   * @type { SelectedOption }
   * 
  */
  selectedOption!: SelectedOption;
  
  /** 
   * Holds the currently selected option from the options array 
   * @type { string }
  */
  currentSelected: string = "None";

  /** 
   * Form to handle region selection 
   * @type { FormGroup }
  */
  settingsForm!: FormGroup;

  /** 
   * Subscription object for managing and unsubscribing observables 
   * @type { Subscription }
  */
  subscriptionObject!: Subscription
  userData: any;
  /** 
   * Constructor to inject necessary services: AuthService for handling authentication
   * and region, CommonSnackbarService for showing snackbar notifications.
   */
  constructor(public authService : AuthService, private snackbar: CommonSnackbarService) {}

  /**
   * Lifecycle hook that runs when the component is initialized.
   * It retrieves the selected region from localStorage and initializes the form.
   */
  ngOnInit(): void{
    this.authService.user$.subscribe((res: any) => {
      this.userData = res;
    })
    this.currentSelected = localStorage.getItem('region') || "None";
    this.selectedValue(this.currentSelected);
    this.settingsFormInitialize();
  }

  /**
   * Finds the selected region from the options array based on the value passed.
   * @param selectedOption - The selected region's value from localStorage.
   */
  selectedValue(selectedOption: string | null): void {
    this.selectedOption = this.options?.find((res) => {
      return res?.value === selectedOption
    }) || { value: 'us-west-2', viewValue: 'USA', countryImage:'../../../../assets/USA.png' ,sortValue:'US' } ;
  }

  /**
   * Initializes the settingsForm with the selected region.
   * If no selected region is found, initializes with a null value.
   */
  settingsFormInitialize(): void {
    this.settingsForm = new FormGroup({
      region: new FormControl(this.selectedOption || null)
    })
  }

  /**
   * Handles the change event when a new region is selected.
   * Updates the selectedOption based on the user's selection.
   * @param { MatSelectChange } event - The event that contains the selected value.
   */
  onSelectionChange(event: MatSelectChange): void {
    const selectedOption = event?.value?.value;
    this.selectedValue(selectedOption);
  }

  /**
   * Saves the selected region to localStorage and updates the AuthService BehaviorSubject.
   * If the form is not dirty, shows a snackbar message indicating no changes.
   */
  onSave(): void {
    if(this.settingsForm && this.settingsForm.dirty) {
      this.currentSelected = this.settingsForm?.value?.region?.value;
      localStorage.setItem('region', this.currentSelected);
      this.authService?.region$?.next(this.currentSelected)
      this.snackbar && this.snackbar.OpenSnackBar({ message : "Updated Successfully", 
        heading: SNACKBAR_HEADING.success, actionType : SNACKBAR_TYPE.success });
    }
    else if(this.settingsForm && !this.settingsForm.dirty) {
      this.snackbar && this.snackbar.OpenSnackBar({
        message: SNACKBAR_MESSAGES.noChanges,
        heading: SNACKBAR_HEADING.warning,
        actionType: SNACKBAR_TYPE.warning,
      });
    } 
  }

  /**
   * Lifecycle hook that runs when the component is destroyed.
   * Unsubscribes from any active subscriptions to avoid memory leaks.
   */
  ngOnDestroy(): void {
    if(this.subscriptionObject) {
      this.subscriptionObject.unsubscribe();
    }
  }
}
