import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InboxComponent } from './inbox/components/inbox/inbox.component';
import { ViewMailComponent } from './inbox/components/view-mail/view-mail.component';
import { SharedModule } from '../shared/shared.module';



@NgModule({
  declarations: [
    InboxComponent,
    ViewMailComponent,
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ZenMailsModule { }
