<div class="main-container">
  <!-- Form for content editing -->
  <form class="content-form" [formGroup]="form">
    <!-- Editable content area -->
    <div 
      class="editor-content" 
      #editor 
      [attr.contenteditable]="!form.disabled"
      (input)="onInput()"
      contenteditable="true"
      [innerHTML]="form.get('editorContent')?.value">
    </div>
  </form>

  <!-- Toolbar for editor functionalities -->
  <div class="editor-toolbar" fxLayoutAlign="center center">
    <!-- Loop through toolbar buttons and create a button for each -->
    <button
      class="button"
      *ngFor="let item of toolBarButtons"
      matTooltipPosition="above"
      matTooltip="{{ item.tooltip }}"
      (click)="addTag(item.toolCommand, false)">
      <span class="material-symbols-outlined">
        {{ item.icon }}
      </span>
    </button>
    
    <!-- Link button for creating hyperlinks -->
    <button class="button" matTooltipPosition="above" matTooltip="Link" (click)="createLink()">
      <span class="material-symbols-outlined">
        link
      </span>    
    </button>   

    <!-- Button to select font family -->
    <button
      style="cursor: pointer"
      class="font-family-button"
      fxLayoutAlign="center center"
      matTooltipPosition="above"
      matTooltip="Font Family"
      [matMenuTriggerFor]="fontFamilyMenu">
      <div class="font-family-text">{{ 'Font' }}</div>
      <span fxLayoutAlign="center center" style="font-size: 20px;" class="material-symbols-outlined">
        arrow_drop_up
      </span>
    </button>
    
    <!-- Button to select font size -->
    <button
      class="button"
      matTooltipPosition="above"
      matTooltip="Font Size"
      [matMenuTriggerFor]="fontSizeMenu">
      <span class="material-symbols-outlined">text_fields</span>
    </button>
    
    <!-- Button to apply font color -->
    <button
      style="position: relative"
      class="button"
      matTooltipPosition="above"
      matTooltip="Font Color"
      (input)="applyTextStyle(undefined, undefined, undefined, { color: colorPicker.value, isBgColor: false }, $event)"
      (click)="colorPicker.click()">
      <input
        type="color"
        #colorPicker
        style="position: absolute; width: 0; height: 0; visibility: hidden;"
        id="colorInput"
      />
      <span class="material-symbols-outlined">format_color_fill</span>
    </button>
    
    <!-- Menu for selecting font sizes -->
    <mat-menu #fontSizeMenu="matMenu">
      <ng-container *ngFor="let option of fontSizeOptions">
        <button mat-menu-item (click)="applyTextStyle(undefined, option.value, option.weight)">
          <span
            [ngStyle]="{'font-size': option.value, 'font-weight': option.name !== 'Paragraph' ? 'bold' : 'normal'}">
            {{ option.name }}
          </span>
        </button>
      </ng-container>
    </mat-menu>

    <!-- Menu for selecting font families -->
    <mat-menu #fontFamilyMenu="matMenu">
      <button
        mat-menu-item
        *ngFor="let option of fontFamilyOptions"
        (click)="applyTextStyle(option.value, undefined, undefined)">
        <span [ngStyle]="{'font-family': option.value, 'font-size': '12px'}">
          {{ option.name }}
        </span>
      </button>
    </mat-menu>

    <!-- Button to open alignment menu -->
    <button [matMenuTriggerFor]="alignmentMenu" class="button" matTooltipPosition="above" matTooltip="Align Left">
      <span class="material-symbols-outlined">more_horiz</span>
    </button>
    
    <!-- Menu for text alignment options -->
    <mat-menu #alignmentMenu="matMenu">
      <div>
        <button 
          *ngFor="let alignment of alignments" 
          class="button" 
          matTooltipPosition="above" 
          [matTooltip]="alignment.tooltip" 
          (click)="setAlignment(alignment.value)">
          <span class="material-symbols-outlined">{{ alignment.icon }}</span>
        </button>
        <!-- Button to clear all formatting -->
        <button class="button" matTooltipPosition="above" matTooltip="Clear All Formatting" (click)="clearAllFormatting()">
          <span class="material-symbols-outlined">clear</span>
        </button>
      </div>
    </mat-menu>
  </div>
</div>

<!-- Template for the link dialog -->
<ng-template #linkDialog>
  <div class="dialog-container">
    <h3>Enter the Url</h3>
    <mat-form-field appearance="outline">
      <input matInput placeholder="Type Url here" #linkInput [value]="isEditLink?hrefValue:null">
    </mat-form-field>
    <div class="button-container">
      <!-- Close button for dialog when adding a link -->
      <button *ngIf="!isEditLink" mat-stroked-button color="primary" matDialogClose>Close</button>
      <!-- Button to unlink when editing a link -->
      <button *ngIf="isEditLink" mat-stroked-button color="primary" mat-dialog-close="unlink">Unlink
        <mat-icon>link_off</mat-icon>
      </button>
      <!-- Button to add or edit the link -->
      <button [disabled]="linkInput.value.length<1" mat-raised-button color="primary"
        [mat-dialog-close]="isEditLink?{link:linkInput.value,isEdit:true}:{link:linkInput.value,isEdit:false}">
        {{isEditLink?'Edit Link':'Add Link'}}
      </button>
    </div>
  </div>
</ng-template>
