import { Component } from '@angular/core';
import { TemplateListComponent } from 'libs/shared/src/lib/components/template-list/template-list.component';

@Component({
  selector: 'app-marketing-templates-list',
  templateUrl: './marketing-templates-list.component.html',
  standalone: true,
  imports: [TemplateListComponent],
  styleUrls: ['./marketing-templates-list.component.scss']
})
export class MarketingTemplatesListComponent {

}
