/**
 * An object containing messages for templates.
 * @type {Readonly<messages>}
 */
export const TEMPLATES_MESSAGES ={
  templateEnabled: "Are you sure do you want to enable this template?",
  templateDisabled: "Are you sure do you want to disable this template?",
  notificationEnabled: "Are you sure do you want to enable notification?",
  notificationDisabled: "Are you sure do you want to disable notification?",
  templateEnableUpdatedSuccessfull:"Template Enabled Successfully",
  templateDisableUpdatedSuccessfull:"Template Disabled Successfully",
  templateUpdatedFailed:"Failed To Update The Template",
  templateDeleteMsg:"Are you sure do you want to delete this template?",
  templateDeletedSuccessfull:"Template Deleted Successfully",
  templateDeletedFailed:"Failed To Delete Template",
  templateCreatedSuccess:"Template Created Successfully",
  templateCreatedFailed:"Failed to Create Template",
  templateUpdatedSuccess:"Template Updated Successfully",
  failedApplication:"Something Went Wrong In Getting Applications",
  failedCustomer:"Something Went Wrong In Getting Customers",
  failedCategory:"Something Went Wrong In Getting Categories",
  failedTemplateList:"Something Went Wrong In Getting Templates",
  failedCategoryList:"Something Went Wrong In Getting Categories",
  failedLanguageList:"Something Went Wrong In Getting Languages",
  wrongDeleteTemplate:"Something Went Wrong in Deleting Template",
  unSavedMsg:"You have unsaved changes. Are you sure you want to leave this page?",
  mandatoryMsg:"Please fill out the mandatory fields!",
  wrongTempleteCreation:"Something Went Wrong in Template Creation",
  wrongTempleteUpdation:"Something Went Wrong in Template Updation",
  noChanges:"You have no changes to save.",
  validMail:"Enter Valid Email.",
  existMail:"Provided email repeat in your email list.",
  MailSuccess:"Mail Send Successfully.",
  MailApiWrong:"Something went wrong in sending the email.",
  subCategoryApiWrong:"Something Went Wrong In Getting Sub Categories.",
  templateExists:"Template already exists"
}