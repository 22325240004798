import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton-loader',
  templateUrl: './skeleton-loader.component.html',
  styleUrls: ['./skeleton-loader.component.scss']
})
export class SkeletonLoaderComponent {
  @Input() items = Array(5);
  @Input() items_form: number = 6;
  @Input() formLoader: any;
  @Input() module: any;
  @Input() isCreate: any = false;
  @Input() isEdit: any = false;
  @Input() isView: any = false;
  @Input() isRegion: any;
  @Input() mailLists = Array(15);
  @Input() isMail: any;
}
