<div fxLayout="column">
  <div *ngIf="!initialLoader" class="common-header">
    <app-common-header (methodClicked)="headerEvent($event)" [buttonInfo]="button" [heading]="heading" >
    </app-common-header>
  </div>
<div *ngIf="initialLoader" class="common-card-style">
    <div>
      <app-skeleton-loader [formLoader]="false"></app-skeleton-loader>
    </div>
</div>
<div fxFlex="100%" *ngIf="!initialLoader" class="common-card-style">
  <app-common-card [sortSetting]="sorting" (sortChange)="sortData($event)" [buttonInfo]="button" (methodClicked)="headerEvent($event)" [dataNotFoundMessage]="noDataMessage" [columns]="columns" [dataSource]="data" [actionSetting]="actions" [hasSearchField]="true"
  [searchSetting]="searchSettingData" [hasPagination]="true"  (paginationChange)="handlePaginationChange($event)" [paginationData]="paginationData"  [loading]="categoryListLoader"
     (searchChange)="handleSearchChange($event)" (actionClicked)="handleActionClicked($event)" (primarySelectEmittedData)="primaryDropdownEvent($event)"
     [primaryFilterData]="primaryfilterDetails" [hasPrimaryFilter]="!!(filterApplication.length)"></app-common-card>
</div>
</div>