import { MatFormFieldAppearance } from "@angular/material/form-field";

/**
 * Interface representing the definition of a table column.
 */
export interface columnRef {
  /** Field name of the column */
  field: string;
  /** Header text of the column */
  header?: string;
  /** Type of the column */
  type: ColumnType;
  /** Whether the column is disabled */
  disable?: any;
  /** Color of the column text */
  color?: string;
  /** CSS class for the column */
  class?: string;
  /** Format of the column content */
  format?: string;
  /** width of the column content */
  columnWidth?: string;
  /** Background colors for the respective chip states */
  displayBackgroundColor?: {
    [key: string]: string;
  };
  /** Text for the respective chip states */
  displayText?: {
    [key: string]: string | number | boolean;
  };
  /** Font colors for the respective chip states */
  displayColor?: {
    [key: string]: string;
  };
  /** Default background color of the chip */
  backgroundColor?: string;
  /**removeUnderscore of the column content */
  removeUnderscore?: boolean;
  /** toggle of the column content */
  toggleCondition?: any;
  /** dropdown of the column content */
  dropDownCondition?: any;
  /** dropdown option of the column content */
  dropDownOption?: any;
  /** text align of the column content */
  columnAlign?: string;
  /**to set as default column for default chip */
  isDefault?: boolean;
  DefaultIconSymbol?: string;
  iconValue?: boolean | string;
  valueIconSymbol?: string;
  valueIconColor?: string;
  subField?: any;
  onHover?: boolean | string;
  isOpened?: any;
  beforeOpenedColor?: string;
  afterOpenedColor?: string;
  actions?: any
}
/**
 * Enum representing the types of columns available.
 * @readonly
 * @enum {string}
 */
export enum ColumnType {
  /** Represents an image column */
  Image = 'image',
  /** Represents a text column */
  Text = 'text',
  /** Represents a checkbox column */
  Checkbox = 'checkbox',
  /** Represents a date column */
  Date = 'date',
  /** Represents a actions column */
  Action = 'action',
  /** Represents a toggle column*/
  Toggle = 'toggle',
  /**Represents a chip column */
  Chip = 'chip',
  /**Represents a dropdown column */
  DropDown = 'dropdown',
  /**Represents a dropdown column */
  Icons = 'icons'
}
/**
 * Interface representing a dynamic data source , with arbitrary properties.
 */
export interface DynamicMailDataSource {
  [key: string]: any;
}
/**
 * Interface representing pagination data.
 */
export interface PaginationData {
  /** Offset of the first item */
  offset: number;
  /** Number of items per page */
  limit: number;
  /** Total count of items */
  count: number;
}
/**
 * Interface representing search settings.
 */
export interface SearchSetting {
  /** Text to search for */
  searchText: string;
  /** Placeholder text for the search input */
  placeHolder?: string;
  /** Appearance of the search input field */
  appearance?: MatFormFieldAppearance;
}
/**
 * Interface representing an action that can be performed on a table.
 */
export interface TableAction {
  /** Icon representing the action */
  icon: string;
  /** Tooltip text for the action */
  tooltip: string;
  /** Method name to call when the action is triggered */
  method: string;
  /** Color of the action icon */
  color?: string;
  /** Color of the action icon */
  disable?: boolean;
}
/**
 * Interface representing an action that was clicked, with associated data.
 * @template T - Type of the data associated with the action
 */
export interface ActionClicked<T> {
  /** Method name to call */
  method: string;
  /** Data associated with the action */
  data: T;
}

/**
 * Interface representing sorting settings.
 */
export interface SortSetting {
  /** Label for the sort setting */
  label: string;
  /** Field to sort by */
  field: string;
  /** Direction of the sort ('asc' or 'desc') */
  direction: 'asc' | 'desc';
  color?:string;
}