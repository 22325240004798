import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpRoutingService } from 'src/app/common/service/http-routing.service';
import { createApplication, deleteApplicationResponseStructure, getApplicationResponse, getSingleApplicationResponse, getUserListResponse } from '../models/applications.model';
import { httpParams } from 'src/app/zen-mail/Reports/models/reports.model';
@Injectable({
  providedIn: 'root'
})
export class ApplicationsService {
  /**
   * component constructor which is used to inject the required services and modules.
   * @param httpClient To refer to the httpClient module to access the get and put methods.
   */
  constructor(private httpClient: HttpRoutingService) { }
  /**
    * Method which is used to get user list data from backend.
    * @param paramData It has the query params of the Url.
    * @type { Observable<getUserListResponse>}
    */
  getUserList(paramData: httpParams) : Observable<getUserListResponse> {
    const filterValue = JSON.stringify({roleId: 2});
   return this.httpClient.getRequest(`microservice/mail/recipientdetails?filterData=${filterValue}`, paramData);  
  }

  /**
   * Method which is used to get application list data from backend.
   * @param paramData It has the query params of the Url.
   * @type { Observable<getApplicationResponse>}
   */
  getApplicationsList(paramData: httpParams) : Observable<getApplicationResponse> {
    return this.httpClient.getRequest('microservice/mail/application?', paramData);  
  }

  /**
   * Method which is used to get application list data from backend.
   * @param paramData It has the query params of the Url.
   * @type { Observable<getSingleApplicationResponse>}
   */
  getSingleApplications(id: string) : Observable<getSingleApplicationResponse> {
    return this.httpClient.getRequest('microservice/mail/application/'+id);  
  }

  /**
   * Method which is used to get application list data from backend.
   * @param applicationId It has the application id to be deleted.
   * @type { Observable<any>}
   */
  deleteApplication(applicationId : number , password : string ) : Observable<deleteApplicationResponseStructure> {
    return this.httpClient.postRequest( `microservice/mail/application/${applicationId}/delete`, {password : password});
  }

  // /**
  //  * Method which is used to verify password.
  //  * @param password It has the password data id to be verif.
  //  * @type { Observable<any>}
  //  */
  // chackPassword( password : string ) : Observable<deleteApplicationResponseStructure> {
  //   this.httpClient.postRequest( `microservice/mail/secret/check`, {password : password}).subscribe(x => {
  //     console.log(x)
  //   })
  //   return this.httpClient.postRequest( `/v1/microservice/mail/secret/check`, {password : password});
  // }

  updateApplication(id:string , data : any) : Observable<createApplication>{
    return this.httpClient.putRequest(`microservice/mail/application/${id}/update`,data,{});  
  }

  createApplication(data : any) : Observable<createApplication>{
    return this.httpClient.postRequest('microservice/mail/application/create',data);  
  }

}

