import { NgModule } from '@angular/core';
import { TemplateListComponent } from "../../../libs/shared/src/lib/components/template-list/template-list.component";
import { UserListComponent } from './Users/components/user-list/user-list.component';
import { SharedModule } from '../shared/shared.module';
import { CoreModule } from '../common/common.module';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgxEchartsModule } from 'ngx-echarts';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    SharedModule,
    CoreModule,
    HttpClientModule,
    UserListComponent,
    TemplateListComponent,
    RouterModule,
    NgxEchartsModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    })
]
})
export class ZenMailModule { }
