/**
 * Class which exports the required validation pattern and error messages which is used in add-edit component
 */
export class AddEditViewCustomer {
    validators = {
        userNameRequired: "User Name is required",
        applicationNameRequired: "Application Name is required",
        customerNameRequired: "Customer Name is required",
        phoneNumberRequired: "Phone Number is required",
        phoneNumberPattern: "Invalid format (+12 345678901234)",
        emailIdRequired: "Email Id is required",
        emailIdPattern: "Invalid Email Id",
        customerIdRequired: "Customer Id is required",
        customerIdPattern: "Invalid ID. Use 0, -1, or positive integers only.",
        statusRequired: "Status is required"
    }
}

export const CUSTOMER_SNACKBAR_MESSAGES = {
    customerFetchError: "Failed to fetch customers",
    customerApplicationFetchError: "Failed to fetch applications",
    customerUserFetchError: "Failed to fetch users",
    customerCreatedSuccess: "Customer created successfully",
    customerCreatedFailed: "Failed to create customer",
    customerUpdateSuccess: "Customer updated successfully",
    customerUpdateFailed: "Failed to update customer"
}