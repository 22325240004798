import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActionClicked, columnRef, ColumnType, DynamicMailDataSource, PaginationData, SearchSetting, SortSetting, TableAction } from 'src/app/shared/models/common-mails-data.model';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/shared/service/common.service';
import { MailServicesService } from '../../services/mail-services.service';
import { CommonSnackbarService } from 'src/app/shared/service/common-snackbar.service';
import { MailComposerService } from 'src/app/shared/service/mail-composer.service';
import { AuthService } from 'src/app/auth/service/auth.service';
@Component({
  selector: 'app-inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.scss']
})
export class InboxComponent implements OnInit, OnDestroy {
  /**
  * Variable used to store the table column definition.
  * @type {Array<columnDef>}
  */
  columns: Array<columnRef> = [
    { field: '', header: '', type: ColumnType.Checkbox, columnWidth: '4%', columnAlign: 'start' },
    { field: 'isStarred', header: '', type: ColumnType.Icons, columnWidth: '3%', DefaultIconSymbol: 'star_border', iconValue: 'isStarrd', valueIconSymbol: "star", valueIconColor: 'gold', columnAlign: 'start' },
    { field: 'senderId', header: '', type: ColumnType.Text, columnWidth: '17%', columnAlign: 'start', isOpened: 'isReadStatus', beforeOpenedColor: 'black', afterOpenedColor: 'gray' },
    { field: 'subject', header: '', type: ColumnType.Text, columnWidth: '58%', columnAlign: 'start', isOpened: 'isReadStatus', beforeOpenedColor: 'black', afterOpenedColor: 'gray', subField: 'body' },
    { field: 'time_stamp', header: '', type: ColumnType.Date, columnWidth: '14%', columnAlign: 'end', isOpened: 'isReadStatus', beforeOpenedColor: 'black', afterOpenedColor: 'gray' },
    { field: 'actions', header: '', type: ColumnType.Action, columnWidth: '15%', color: 'black', columnAlign: 'center', onHover: true }
  ];
  /**
  * Variable used to store the table mail actions data.
  * @type {Array<TableAction>}
  */
  mailActions: Array<TableAction> = [
    { icon: 'drafts', tooltip: 'Mark as unread', method: 'onRead', color: 'rgb(62, 50, 247)' },
    { icon: 'delete', tooltip: 'Delete', method: 'onDelete', color: '#ff2929', disable: false }
  ];
  /**
  * Variable used to store the table data.
  * @type {any[]}
  */
  inboxData!: any[];
  /**
 * Variable used to store the table data.
 * @type {number}
 */
  inboxDataCount!: number;
  /**
  * Variable used to store the table Pagination data.
  * @type {PaginationData}
  */
  paginationData: PaginationData = {
    limit: 20,
    count: 0,
    offset: 0
  }
  /**
  * Variable used to store search settings data.
  * @type {SearchSetting}
  */
  searchSettingData: SearchSetting = {
    searchText: '',
    placeHolder: 'Search',
    // appearance: 'outline'
  }
  /**
  * Variable used to store sorting data.
  * @type {SearchSetting}
  */
  sorting: Array<SortSetting> = [
    { label: 'Newest Mail', field: 'time_stamp', direction: 'desc', color: 'black' },
    { label: 'Oldest Mail', field: 'time_stamp', direction: 'asc', color: 'black' }
  ]
  /**
   * Variable used to store the sorting value.
   * @type {string}
   */
  sortingOrder: string = 'DESC';
  /**
  * Variable used to store the observables.
  * @type {Subscription}
  */
  SubscriptionObject: Subscription = new Subscription();
  /**
  * Variable used to store the initial loader value.
  * @type {boolean}
  */
  initialLoader: boolean = false;
  /**
  * Variable used to store the loader value.
  * @type {boolean}
  */
  loader: boolean = false;
  /**
  * Variable used to store the recipent Id.
  * @type {number|null}
  */
  isRecipentId!: number | null;
 /**
 * Indicates whether the current page is the inbox page.
 * @type {boolean}
 */
  isInboxMail: boolean = false;
   /**
 * Indicates whether the current page is the sent page.
 * @type {boolean}
 */
  isSentMail: boolean = false;
   /**
 * Indicates whether the current page is the starred page.
 * @type {boolean}
 */
  isStarredMail: boolean = false;
   /**
 * Indicates whether the current page is the trash page.
 * @type {boolean}
 */
  isTrashMail: boolean = false;
   /**
 * Indicates whether the current page is the spam page.
 * @type {boolean}
 */
  isSpamMail: boolean = false;
   /**
 * Indicates whether the current page is the draft page.
 * @type {boolean}
 */
  isDraftMail: boolean = false;
   /**
 * variable is used to stored current page name
 * @type {string|undefined}
 */
  foundSegment: string | undefined;
  /**
 * component constructor which is used to inject the required services.
 */
  constructor(private route: Router,
    private mailServices: MailServicesService,
    private commonService: CommonService,
    private snackbar: CommonSnackbarService,
    private MailComposerService: MailComposerService,
    public authService: AuthService
  ) {

  }
  /**
* Angular life cycle hook that initiates the component
*/
  ngOnInit(): void {
    this.initialLoader = true;
    this.loader = true;
    this.checkUrlSegment();
    this.findRecipentDetails();
    this.getAllMails();
  }
  /**
   * Evaluates specific segments in the current URL path to set page-specific states.
   * updates the corresponding state page. 
   * handle other segments as needed.
   */
  checkUrlSegment() {
    const params = this.route.url;
    const pathSegments = params.split('/');
    const segmentsToCheck = ['inbox','sent','starred','trash','spam','draft'];
    this.foundSegment = pathSegments.find(segment => segmentsToCheck.includes(segment)); 
    this.isInboxMail=this.foundSegment==='inbox'?true:false;
    this.isSentMail=this.foundSegment==='sent'?true:false;
    this.isStarredMail=this.foundSegment==='starred'?true:false;
    this.isTrashMail=this.foundSegment==='trash'?true:false;
    this.isSpamMail=this.foundSegment==='spam'?true:false;
    this.isDraftMail=this.foundSegment==='draft'?true:false;
  }
  /**
 *Method which is used to find recipentDetails.
 */
  findRecipentDetails() {
    this.SubscriptionObject = this.authService.user$.subscribe((res: any) => {
      this.isRecipentId = res?.emailactivity?.id;
    });
  }
  /**
  * method which is used to fetch data from inbox mails list
  */
  getAllMails(mailData?: any) {
    const data = {
      offset: mailData?.offset ?? 0,
      limit: mailData?.limit ?? 20,
      searchData: mailData?.searchData ?? '',
      sortingOrder: mailData?.sortingOrder ?? 'DESC',
      recipientId: this.isRecipentId ?? null,
      ...(this.isSentMail ? { status: 'send' } : this.isInboxMail ? { status: 'received' }:this.isDraftMail?{status:'draft'} : {}),
      ...(this.isStarredMail ? { starred: true } : {}),
      ...(this.isTrashMail ? {deleted:true} : {}),
      ...(this.isSpamMail ? {isSpam:true} : {}),
    }
    this.SubscriptionObject.add(this.mailServices.getAllEmailsList(data).subscribe({
      next: (mailLists: any) => {
        this.inboxData = mailLists?.data?.emails?.map((email: any) => {
          // Check if `email.body` is encrypted using a custom check function
          const isEncrypted = this.isEncrypted(email.body);
          const decryptedBody = isEncrypted ? this.commonService.decryptAES(email?.body) || '' : email?.body;
          return { ...email,
                       body: decryptedBody}}) ?? [];
        this.inboxDataCount = mailLists?.data?.count;
        this.paginationData.count = this.inboxDataCount;
        const updateColumn=this.isSentMail|| this.isStarredMail||this.isTrashMail?true:false;
        this.updateColumnFields(updateColumn);
        this.initialLoader = false;
        this.loader = false;
      }, error: (err) => {
        this.snackbar.OpenSnackBar({
          message: 'Something went wrong in getting mails',
          heading: 'Failed',
          actionType: 'failure',
          duration: 2
        });
        this.inboxData = [];
        this.paginationData.count = 0;
        this.initialLoader = false;
        this.loader = false;
      }
    }))
  }
   /**
  * method which is used to Check if `data` is encrypted or not
  */
  isEncrypted(data: string): boolean {
    // Check if `data` is encrypted (you can customize this check as needed)
    return /^[A-Za-z0-9+/=]+$/.test(data) && data.length % 4 === 0;
  }
  
  /**
  * method which is used to updated colums based on subject.mail read status and actions
  */
  updateColumnFields(data?: boolean) {
    // Remove star icon if the page is trash mail
    const updatedColumns = this.isTrashMail
      ? this.columns.filter(column => column.field !== 'isStarred')
      : this.columns;
    // Update the columns of field values based on mail status and actions
    const updateColumnsOfFieldValues = this.inboxData?.map((item: any) => ({
      ...item,
      subject: item?.subject || item?.body,
      isReadStatus: item?.read_status === 'read',
      actions: this.mailActions.map(action => ({
        ...action,
        icon    : (action.method === 'onRead' && item?.read_status === 'read')?'drafts':(action.method === 'onRead' && item?.read_status === 'unread')?'mail'
                  :(action.method==='onDelete' && item.isDeleted)?'remove_circle':(action.method==='onDelete' && !item.isDeleted)?'delete':'',
        tooltip : (action.method === 'onRead' && item?.read_status === 'read')?'Mark as unread':(action.method === 'onRead' && item?.read_status === 'unread')?'Mark as read'
                  :(action.method==='onDelete' && item.isDeleted)?'Remove from Trash':(action.method==='onDelete' && !item.isDeleted)?'Delete':'',
        method  : (action.method === 'onRead' && item?.read_status === 'read')?'onRead':(action.method === 'onRead' && item?.read_status === 'unread')?'onUnread'
                  :(action.method==='onDelete' && item.isDeleted)?'onDelete':(action.method==='onDelete' && !item.isDeleted)?'onDelete':'',
        })),           
      senderId: data === true
        ? this.extractEmail(item.recipientId) === this.extractEmail(item.senderId)
          ? (this.isStarredMail || this.isTrashMail ? 'me' : 'To: me')
          : (this.isStarredMail || this.isTrashMail ? item?.senderId : 'To: ' + this.extractEmail(item?.recipientId))
        : item.senderId,
    }));
    this.columns = updatedColumns;
    this.inboxData = updateColumnsOfFieldValues;
  }
/**
* Extract email if present, else return original senderId
*/
  extractEmail(senderId: string): string {
    const match = senderId.match(/<([^>]+)>/);
    return match ? match[1] : senderId;
  }
  /**
* Method which is used for table Pagination.
* @param event It has the Pagination value which is emitted.
*/
  handlePaginationChange(event: PaginationData): void {
    const data = {
      limit: event.limit,
      offset: event.offset,
      searchData: this.searchSettingData.searchText ?? '',
      sortingOrder: this.sortingOrder ?? 'DSEc'
    }
    this.paginationData.limit = event.limit;
    this.paginationData.count = event.count;
    this.paginationData.offset = event.offset;
    this.loader = true;
    this.getAllMails(data);
  }
  /**
* Method which is used for searching the table.
* @param event It has the searched string which is emitted.
*/
  handleSearchChange(event: string): void {
    this.searchSettingData.searchText = event;
    let data;
    if (event && event?.length) {
      data = {
        offset: 0,
        limit: 20,
        searchData: this.searchSettingData.searchText ?? '',
        sortingOrder: this.sortingOrder ?? 'DESC'
      }
      this.loader = true;
      this.getAllMails(data);
    }
    else {
      data = {
        searchData: '',
        offset: 0,
        limit: 20,
        sortingOrder: this.sortingOrder ?? 'DESC'
      }
      this.loader = true;
      this.getAllMails(data);
    }
  }
  /**
* Method which is used for sorting the table.
* @param event It has the searched string which is emitted.
*/
  handleSortingChange(event: any) {
    this.sorting.forEach(sort => sort.color = 'black');
    if (event.direction === "desc") {
      this.sortingOrder = 'DESC';
      this.sorting.find(sort => sort.direction === 'desc')!.color = 'blue';
    } else {
      this.sortingOrder = 'ASC';
      this.sorting.find(sort => sort.direction === 'asc')!.color = 'blue';
    }
    const data = {
      offset: 0,
      limit: 20,
      searchData: this.searchSettingData.searchText,
      sortingOrder: this.sortingOrder ?? 'DESC'
    }
    this.loader = true;
    this.getAllMails(data);
  }
  /**
  * Method which is used to view/edit/delete methods for templates.
  */
  handleActionClicked(event: ActionClicked<DynamicMailDataSource>) {
    const mailId = event?.data?.['id'];
    if (event.method === 'onPageNavigate') {
      this.onNavigatePage(event?.data?.['id'])
    }
    if (event.method === 'onRead' || event.method === 'onUnread') {
      const fieldName = 'read_status';
      const fieldValue = event.method === 'onRead' ? 'unread' : 'read';
      const actionName=event.method === 'onRead' ? 'unread' : 'read';
      this.onUpdateActions(mailId, fieldName, fieldValue,actionName);
    }
    if (event.method === 'onDelete') {
      const fieldName = 'is_deleted';
      const fieldValue = event?.data['isDeleted']===true?false:true;
      const actionName=event?.data['isDeleted']===true? 'removeDelete' : 'delete';
      this.onUpdateActions(mailId, fieldName, fieldValue,actionName);
    }
  }
  /**
   * method which is used to updated  icons based on starred or not
   */
  onStarredChanged(event: any) {
    this.onUpdateActions(event.id, 'is_starred', event.fieldValue);
  }
  /**
 * method which is used to updated action values like delete ,read and unread mail status ,starred and not starred ant etc....
 */
  onUpdateActions(id: number, field: string, fieldValue: string | boolean,actionName?:string) {
    const mailId = id;
    const updateValues = {
      'columnName': [field], 'value': [fieldValue]
    }
    this.SubscriptionObject.add(this.mailServices?.updateEmail(mailId, updateValues).subscribe({
      next: (res:any) => {
        if (res) {
          if(actionName){
            this.snackbar.OpenSnackBar({
              message:res?.success?actionName==='delete'?'Mail moved to Trash.':actionName==='removeDelete'?'Mail moved from Trash':actionName==='read'?'Mail marked as read.':actionName==='unread'?'Mail marked as unread.':'':actionName==='delete'?'Failed to moved Trash.':actionName==='removeDelete'?'Failed to move from the trash.':actionName==='read'?'Mail fsiled to marked as read.':actionName==='unread'?'Mail failed to marked as unread.':'',
              heading:res?.success ?'Success':'Failed',
              actionType: res?.success ? 'success' : 'failure',
              duration: 2
            });
          }
          // this.loader = true;
          this.getAllMails();
        }
      }, error: (err) => {
        this.snackbar.OpenSnackBar({
          message: 'Something went wrong.',
          heading: 'Failed',
          actionType: 'failure',
          duration: 2
        });
      }
    }))
  }
  onNavigatePage(mailId:number){
  if(this.foundSegment!=='draft'){
    this.route.navigate(['app/zenmails/viewMail',this.foundSegment, this.commonService.setParamsObj(mailId)]);
  } else{
    this.callMailComposer(mailId);
  }
  }
  /**
 * method which is used to call composer.
 */
  callMailComposer(mailId?:number) {
    if(mailId){
      this.SubscriptionObject = this.mailServices.getOneEmail(mailId).subscribe({
        next: (getOneEmail: any) => {
         if(getOneEmail){
          const data=getOneEmail?.data;
          this.MailComposerService.openMailComposer(data);
         }
        },error:(err)=>{
          this.snackbar.OpenSnackBar({
            message:'Something went wrong',
            heading:'Failed',
            actionType:'failure',
            duration: 2
          });
        }
      })
    } else{
    this.MailComposerService.openMailComposer();
    }
  }
  /**
* Angular life cycle hook ngOnDestroy is used to unsubscribe the subscribtion.
* @type {void}
*/
  ngOnDestroy(): void {
    if (this.SubscriptionObject) {
      this.SubscriptionObject.unsubscribe();
    }
  }
}
