import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SharedModule } from 'src/app/shared/shared.module';
import { CommonModule } from '@angular/common';
import { MatIconRegistry } from '@angular/material/icon';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { CommonDialogService } from 'src/app/shared/service/common-dialog.service';
import { DeleteConfigurationDetail } from 'src/app/zen-mail/Configurations/models/configuration.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SNACKBAR_HEADING, SNACKBAR_TYPE } from 'src/app/shared/constants/common-snackbar-data.constants';
import { CommonSnackbarService } from 'src/app/shared/service/common-snackbar.service';
import { MailServicesService } from 'src/app/zen-mails/inbox/services/mail-services.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { AuthService } from 'src/app/auth/service/auth.service';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { NavigationEnd, Router } from '@angular/router';
import { filter, Subscription } from 'rxjs';

@Component({
  selector: 'app-mail-compose-window',
  standalone: true,
  imports: [MatFormFieldModule,CommonModule,SharedModule , DragDropModule],
  templateUrl: './mail-compose-window.component.html',
  styleUrls: ['./mail-compose-window.component.scss']
})
export class MailComposeWindowComponent implements OnInit,OnDestroy{
  /**
   * Array to store the email addresses entered by the user for sending emails.
   * @type {string[]}
   */
  mailRecipients: string[] = [];

  /**
   * FormGroup instance to manage the email composition form, including validation and state management.
   * @type {FormGroup}
   */
  applicationForm!: FormGroup;

  /**
   * Initial array for handling email input, likely intended for managing multiple email recipients.
   * @type {string[]}
   */
  emailArray: string[] = [''];
      /**
   * This boolean variable tracks whether a loading occured.
   * @type {boolean}
   */
      isLoading:boolean=false;
        /**
  * Variable used to store the observables.
  * @type {Subscription}
  */
  SubscriptionObject: Subscription = new Subscription();
  senderName!: string;
  senderEmail!: string;
  emailActivityId!:number;
  /**
   * Component constructor used to inject the required services for managing UI interactions,
   * dialog operations, and notifications within the mail compose window.
   * 
   * @param {MatIconRegistry} matIconReg - Service to manage and register Material icons used in the component.
   * @param {CommonDialogService} dialogService - Service for managing dialog interactions, enabling confirmation and alerts within the application.
   * @param {MatDialogRef<MailComposeWindowComponent>} dialogRef - Reference to the dialog for closing the compose window when necessary.
   * @param {CommonSnackbarService} snackbar - Service for displaying snackbar messages to provide user feedback and notifications.
   */
  constructor(
    private matIconReg: MatIconRegistry,
    private dialogService: CommonDialogService,
    private dialogRef: MatDialogRef<MailComposeWindowComponent>,
    private snackbar: CommonSnackbarService,
    private mailService:MailServicesService,
    private commonService: CommonService,
    public authService : AuthService,
    private route: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    /**
     * Regular expression for validating email format.
     */
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Set the default font set class for Material icons.
    this.matIconReg.setDefaultFontSetClass('material-symbols-outlined');

    /**
     * Initialize the application form with controls for email, subject, and content.
     */
    this.applicationForm = new FormGroup({
        email: new FormControl([], Validators.required), 
        emailSubject: new FormControl((this.data && this.data?.subject)??null),
        emailContent: new FormControl((this.data && this.data?.body)??'', Validators.required),
        emailValidator: new FormControl((this.data && this.data?.recipientId)??[], Validators.email),
    });
    this.SubscriptionObject=this.authService.user$.subscribe((res:any)=>{
      if(res){
        this.senderName=res?.firstName+(res?.lastName?(' '+res?.lastName):'');
        this.senderEmail=res?.emailactivity?.emailId;
        this.emailActivityId=res?.emailactivity?.id;
      }
    })
    this.addRecipients(this.data?.recipientId??[]);
    // Watch for changes on the emailValidator control
    this.applicationForm.get('emailValidator')?.valueChanges.subscribe(value => {
      if (value && value.includes(',')) {
        this.addRecipients(value);
      }
    });
  }

  /**
   * Adds a recipient email to the list if the email is valid.
   * @param {string} input - The email address to be added.
   * @param {any} element - The input element for the email address.
   */
  addRecipients(input: string | string[]): void {
    let emails: string[] = [];
    if (Array.isArray(input)) {
      emails = input;
    } else if (typeof input === 'string') {
      emails = input.split(',').map(email => email.trim());
    }
    emails.forEach(email => {
      this.applicationForm.get('emailValidator')?.setValue(email);
      if (!this.applicationForm.get('emailValidator')?.invalid && email) {
        if (!this.mailRecipients.includes(email)) {
          this.mailRecipients.push(email);
        }
      }
    });
    this.applicationForm.get('email')?.setValue(this.mailRecipients);
    this.applicationForm.get('emailValidator')?.setValue('');
  }
  /**
   * Removes a recipient email from the list by index.
   * @param {number} index - The index of the email to be removed.
   */
  removeRecipients(index: number) {
    this.mailRecipients = [
        ...this.mailRecipients.slice(0, index), 
        ...this.mailRecipients.slice(index + 1)
    ];
    this.applicationForm.get('email')?.setValue(this.mailRecipients);
  }

  /**
   * Configures and opens a dialog box for user confirmation.
   * Handles the dialog close event based on user action.
   * 
   * @param {string} header - The title for the dialog box.
   * @param {string} action - The action to be executed upon confirmation.
   * @param {Object} buttonData - Configuration for the dialog buttons.
   * @param {string} [buttonData.right] - Text for the right button.
   * @param {string} [buttonData.left] - Text for the left button.
   * @param {string} [messageData] - Optional message to display in the dialog.
   * @param {DeleteConfigurationDetail} [deleteConfigurationResponse] - API response for deleting configuration details.
   * @returns {void}
   */
  setDialogData(header: string, action: string, buttonData: { right?: string; left?: string }, messageData?: string, deleteConfigurationResponse?: DeleteConfigurationDetail): void {
    // Open a confirmation dialog with the provided configuration.
    const confirmationDialog = this.dialogService && this.dialogService.openDialog({
        header: header,
        message: messageData,
        actionType: action,
        button: buttonData
    });

    // Subscribe to the dialog result to determine user action.
    confirmationDialog.subscribe((result) => {
        if (result) {
            // Close the compose dialog if the user confirms.
            this.closeDialog('close' ,this.applicationForm);
        } 
        // No action required if the user cancels; the dialog remains open.
    });
  }


  /**
   * Closes the dialog if the form is valid; otherwise, shows a warning snackbar.
   * 
   * @param {FormGroup} [value] - The form group value to pass when closing the dialog.
   * @returns {void}
   */
  closeDialog(type : string ,value?: FormGroup ) {
    if(type=="send"){
    if (this.applicationForm.valid) {
        // Close the dialog and pass the form data if valid.
        this.dialogRef.close(value);
        setTimeout(() => {
          this.route.events.pipe(
              filter(event => event instanceof NavigationEnd)
          ).subscribe(() => {
              this.route.navigate([this.route.url]);
          });
      }, 0);
    } else {
        // Show a snackbar notification if the form is invalid.
        this.snackbar && this.snackbar.OpenSnackBar({
            message: 'Some of the email data is invalid.',
            heading: SNACKBAR_HEADING.warning,
            actionType: SNACKBAR_TYPE.warning
        });
    }
  } else if(type=="close" && this.applicationForm.valid){
     // Check if subject, content and recipentid are unchanged or not
     const isSubjectUnchanged = this.applicationForm.value?.emailSubject === this.data?.subject;
     const isContentUnchanged = this.applicationForm.value?.emailContent === this.data?.body;
     const isRecipentUnchanged = JSON.stringify(this.applicationForm.value?.email) === JSON.stringify(this.data?.recipientId);
     if (!isSubjectUnchanged || !isContentUnchanged || !isRecipentUnchanged) {
       const mailDetails = {
         emailActivityId: this.emailActivityId,
         senderId: `${this.senderName} <${this.senderEmail}>`,
         recipientId: this.applicationForm.value?.email ?? [],
         subject: this.applicationForm.value?.emailSubject,
         // Encrypt only if content has changed
         body: !isContentUnchanged 
               ? this.commonService.encryptAES(this.applicationForm.value?.emailContent) 
               : this.data.body,
         status: 'draft',
         readStatus: 'read',
       };
       this.SubscriptionObject = this.mailService.onDraftMail(mailDetails).subscribe({
         next: (response: any) => {
           if (response.success) {
             const foundSegment = this.checkUrlSegment();
             if (foundSegment) {
               this.route.navigate([`app/zenmails/${foundSegment}`]);
             }
             this.dialogRef.close(value);
             this.snackbar.OpenSnackBar({
               message: 'Message save to Draft',
               heading: 'Success',
               actionType: 'success',
               duration: 3,
             });
           }
         },
         error: (err) => {
           this.snackbar.OpenSnackBar({
             message: 'Something went wrong.',
             heading: 'Failed',
             actionType: 'failure',
             duration: 2,
           });
         },
       });
     } else {
       this.dialogRef.close(value);
     }  
  } else{
    this.dialogRef.close(value);
  }
  }
    /**
   * Evaluates specific segments in the current URL path to set page-specific states.
   * updates the corresponding state page. 
   * handle other segments as needed.
   */
    checkUrlSegment(){
      const params = this.route.url;
      const pathSegments = params.split('/');
      const segmentsToCheck = ['inbox','sent','starred','trash','spam','draft'];
      const foundSegment = pathSegments.find(segment => segmentsToCheck.includes(segment));
      return foundSegment;
    }
  onSentMail(){
    if(this.applicationForm.valid){
      this.isLoading=true;
      const isContentUnchanged = this.applicationForm.value?.emailContent === this.data?.body;
      const emailDetails = {
        mailConfiguration: {
          recipients:this.applicationForm.value?.email??[],
          senderName:this.senderName,
          senderEmail: this.senderEmail,
        },
        mailData:{
          subject: this.applicationForm.value?.emailSubject,
          content: !isContentUnchanged 
                   ? this.commonService.encryptAES(this.applicationForm.value?.emailContent) 
                   : this.data.body, 
        }
      }
      this.SubscriptionObject= this.mailService.onSentMail(emailDetails).subscribe({
        next:(res:any)=>{
        if(res.success===true){
          this.isLoading=false;
         this.snackbar.OpenSnackBar({
            message: 'Message sent',
            heading: 'Success',
            actionType: 'success',
            duration: 3
          })
          this.closeDialog('send');
          this.menuBarChanges('Sent','');
          this.route.navigate(['app/zenmails/sent'])
        }
        },error:(err=>{
          this.snackbar.OpenSnackBar({
            message: 'Something went wrong in sending mail',
            heading: 'Failed',
            actionType: 'failure',
            duration: 2
          });
          this.isLoading=false;
        })
      })
  } else{
    this.snackbar && this.snackbar.OpenSnackBar({
      message: 'Please fill out the mandatory fields!',
      heading: SNACKBAR_HEADING.warning,
      actionType: SNACKBAR_TYPE.warning
  });
  }
  }
    /**
   * Updates the menu and submenu selection states based on provided menu and submenu names.
   * @param { string } menuName 
   * @param { string } subMenu 
   */
    menuBarChanges(menuName: string, subMenu?: string) {
      this.SubscriptionObject=this.commonService?.menus.subscribe(res => {
        res.menu.forEach((menu: any) => {
          if(menu.pageCustomTitle == menuName) {
            menu.isSelected = true;
            this.commonService.setMenuId(menu.id)
            this.commonService.setPreviousMenuId(menu.id)
          }
          else menu.isSelected = false;
          menu?.subMenu?.forEach((submenu: any) => {
            if(submenu.pageCustomTitle == subMenu) {
              submenu.isSelected = true;
              this.commonService.setShowSubmenu(true);
            }
            else submenu.isSelected = false
          })
        })
      })
  }

  suspiciousPatternValidator(): ValidatorFn {
    const suspiciousPattern1 = new RegExp(
      /<\s*(script|img|iframe|object|embed|link|style|meta|form|input|button|textarea|base|frame|frameset|applet)\s*[^>]*>|&lt;\s*(script|img|iframe|object|embed|link|style|meta|form|input|button|textarea|base|frame|frameset|applet)\s*[^&]*&gt;|onerror=|onload=|onmouseover=|onfocus=|onkeydown=|onkeyup=|onclick=|onchange=|onblur=|onselect=|onsubmit=|onresize=|onunload=|onabort=|oncontextmenu=/i
    );
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value && suspiciousPattern1.test(control.value)) {
        return { suspiciousPattern: { value: control.value } };
      }
      return null;  // No error if no match is found
    };
  }
  /**
* Angular life cycle hook ngOnDestroy is used to unsubscribe the subscribtion.
* @type {void}
*/
  ngOnDestroy(): void {
    if (this.SubscriptionObject) {
      this.SubscriptionObject.unsubscribe();
    }
  }
}