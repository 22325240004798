
export const snackber_message = {
    FILL_MANTATORY : 'Please fill all the mandatory feilds',
    UPDATE_FAILED  : 'Update application failed',
    UPDATE_SUCCESS : 'Application updated successfully',
    ERROR_CUSTOMER_CREATION : 'Error creating customer',
    ERROR_CUSTOMER_UPDATING : 'Error Updating customer',
    APPLICATION_DELETION_FAILED : 'Failed to delete application',
    APPLICATION_FETCH_FAILED : 'Error fetching application list',
    USER_FETCH_FAILED : 'Error fetching user list',
    APPLICATION_CREATION_FAILED : 'Error creating application',
    APPLICATION_CREATED : 'Application created successfully',
    APPLICATION_UPDATION_FAILED : 'Error updating application',
    APPLICATION_UPDATED : 'Application updated successfully'
}