import { SnackbarDetails, SnackbarHeading, SnackbarOptions, SnackbarPosition, SnackbarType } from "../models/common-snackbar-data.model";

/**
 * An object that maps snackbar types.
 * @type {Readonly<SnackbarType>}
 */
export const SNACKBAR_TYPE: Readonly<SnackbarType> ={
  success: 'success',
  failure: 'failure',
  information: 'information',
  default: 'default',
  warning: 'warning'
}

/**
 * An array of objects containing snackbar details.
 * @type {Array<SnackbarDetails>}
 */
export const SNACKBAR_DETAILS: Array<SnackbarDetails> =[
  { type: 'success', panelClass: 'success-bar', icon: 'check_circle', iconColor: 'rgba(32, 94, 35, 1)' },
  { type: 'failure', panelClass: 'error-bar', icon: 'cancel', iconColor: 'rgba(187, 26, 27, 1)' },
  { type: 'warning', panelClass: 'warning-bar', icon: 'error', iconColor: 'rgba(248, 123, 23, 1)' },
  { type: 'default', panelClass: 'default-bar', icon: 'help', iconColor: 'rgba(91, 91, 91, 1)'}
]

/**
 * An object that defines snackbar positions.
 * @type {Readonly<SnackbarPosition>}
 */
export const SNACKBAR_POSITION: Readonly<SnackbarPosition> ={
  TopRight: { horizontalPosition: 'end', verticalPosition: 'top' },
  TopCenter: { horizontalPosition: 'center', verticalPosition: 'top' },
  TopLeft: { horizontalPosition: 'start', verticalPosition: 'top' },
  BottomRight: { horizontalPosition: 'end', verticalPosition: 'bottom' },
  BottomCenter: { horizontalPosition: 'center', verticalPosition: 'bottom' },
  BottomLeft: { horizontalPosition: 'start', verticalPosition: 'bottom' },
}

/**
 * An object containing snackbar messages.
 * @type {Readonly<SnackbarMessages>}
 */
export const SNACKBAR_MESSAGES ={
  productUpdationsuccess: 'Product updated successfully.',
  productAdded: 'Product added successfully.',
  verifyUserFailed: 'Something went wrong while sending mail.',
  mandatoryField: 'Please fill all the mandatory fields.',
  noChanges:"You have no changes to save.",
}

/**
 * An object containing snackbar heading.
 * @type {Readonly<SnackbarHeading>}
 */
export const SNACKBAR_HEADING: Readonly<SnackbarHeading> ={
  success: 'Success',
  failure: 'Failed',
  information: 'Information',
  alert: 'Alert',
  default: 'Default',
  warning: 'Warning'
}

/**
 * An object containing snackbar heading.
 * @type {Readonly<SnackbarHeading>}
 */
export const SNACKBAR_OPTIONS: Readonly<SnackbarOptions> ={
  success: 'success',
  failure: 'Failure',
  warning: 'warning'
}
