<div *ngIf="!initialLoader" class="common-header">
  <app-common-header [heading]="headerDetails" ></app-common-header>
</div>
<div *ngIf="initialLoader" class="common-card-style">
  <app-skeleton-loader  [formLoader]="true" [module]="'reports'" [isView]="true"></app-skeleton-loader>
</div>
<div fxLayout="column" fxLayoutGap="2%"  class="report-container">
  <div *ngIf="!initialLoader && data.length" fxLayoutAlign="space-between center" fxLayoutAlign.lt-md="center start">
    <div class="table-container commonStyle" fxFlex="75">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="common-header-style" >
      <div style="width: 100%;">
        <div fxLayoutAlign="start center" style="font-weight: 500;">Mail overview</div>
      </div>
      <div fxLayoutAlign="center center">
        <span class="material-symbols-outlined overview-icons">arrow_outward</span>
      </div>
    </div>
    <div>
      <div fxFlex="100%" *ngIf="!initialLoader"  class="common-card-style">
        <app-common-card *ngIf="data.length > 0" (actionClicked)="handleActionClicked($event)" [dataNotFoundMessage]="noDataMessage" [columns]="columns" [dataSource]="data" [actionSetting]="actions" [hasSearchField]="false"
        [loading]="initialLoader"></app-common-card>
      </div>
    </div>
    </div>
    <div fxFlex="23" fxLayout="column"  class="commonStyle pie-chart-container">
    <div class="common-header-style" fxLayout="row" fxLayoutAlign="space-between center">
      <div style="width: 100%;">
        <div fxLayoutAlign="start center" style="font-weight: 500;">Mail overview</div>
      </div>
    </div>
    <div fxLayout="column" style="position: relative" fxFlex="auto" fxLayoutAlign="center center" *ngIf="totalSuccessCount && (totalSuccessCount != 0 || totalFailureCount != 0)"> 
      <div #mouseTarget class="circle-container" fxLayoutAlign="center center">
      <div class="first-circle" [style.background]="'conic-gradient(#2B5BFC 0deg ' + ((totalSuccessCount / (totalSuccessCount + totalFailureCount)) * 360)+ 'deg, transparent ' + ((totalSuccessCount / (totalSuccessCount + totalFailureCount)) * 360) + 'deg 360deg)'"></div>
      <div [style.z-index]="zIndex" class="second-circle" [style.background]="'conic-gradient(transparent 0deg ' + ((totalSuccessCount / (totalSuccessCount + totalFailureCount)) * 360)+ 'deg, #809DFD ' + ((totalSuccessCount / (totalSuccessCount + totalFailureCount)) * 360) + 'deg 360deg)'"></div>
      <div class="inner-circle" fxLayoutAlign="center center" fxLayout="column">
        <div class="total-mails-title"> 
          {{calculateMailDataValue()}}%</div> 
        <div class="total-mails-description" >
          {{totalSuccessCount + totalSuccessCount}} <span style="color: rgba(0,0,0,0.66);">Mails sent</span>
        </div>
      </div>
      <div style=" border-radius: 50%; position: absolute;width: 100%;height: 100%; z-index: 10;" [@fillAnimation]="state"></div>
      </div>
      <div class="chart-description-container">
      <tr class="title-container"  fxLayoutAlign="space-between center">
        <!-- Title for the new message -->
        <td style="margin:0 14px 0 0" fxLayoutAlign="center center">
          <tr>
            <td>
              <div style="width:10px;height:10px;background-color:#2B5BFC;border-radius:50%;"></div>
            </td>
            <td style="font-size:14px;font-weight: 500;">Mails Sent</td>
          </tr>              
        </td>
        <!-- Title for the new message -->
        <td fxLayoutAlign="center center">
          <tr>
            <td>
              <div style="width:10px;height:10px;background-color:#809DFD;border-radius:50%;"></div>
            </td>
            <td style="font-size:14px;;font-weight: 500;">Mails Failed</td>
          </tr>   
        </td>
      </tr>
      </div>
      <div #pieChartLabel class="pie-chart-lable">
        <div style="font-size: 16px; font-weight: 500; padding-bottom: 8px;">
          {{currentLabel.title}}
        </div>
        <div style="font-size: 14px">
          {{currentLabel.value}}
        </div>
        </div>
    </div>
    <div  *ngIf="!(totalSuccessCount && (totalSuccessCount != 0 || totalFailureCount != 0))" fxLayout="column" style="position: relative" fxFlex="auto" fxLayoutAlign="center center"> 
      <div fxLayout="column" fxLayoutAlign="center center">
        <div style="max-width:150px;">
          <img width="100%" src="../../../../../assets/noOverviewData.jpg" alt="">
        </div>
      </div>
    </div>
    </div>
  </div>
  <div *ngIf="!initialLoader && data.length" fxLayout="row wrap"  fxLayoutGap="2%">
    <div fxFlex="23.5%" class="bar-chart container" *ngFor="let items of mailCounts; let i = index ">
      <div fxLayoutGap="8px" fxLayout="column" style="padding: 15px;aspect-ratio: 1 / 1;" class="commonStyle">
        <div class="total-mail-description" fxLayout="column" fxLayoutGap="8px" fxFlex="30" fxLayoutAlign="center start">
            <div class="bar-title">{{mailCounts[i].totalMails}}</div>
            <div class="bar-content">Super admin mails</div>
        </div>
        <div fxLayout="row" fxFlex="70" fxLayouAlign="center space-evenly">
          <div fxLayout="row"  fxLayoutGap="8px" class="bar-chart-container" fxFlex="63">
            <div (mouseover)="pieChartLabelSuccess.style.visibility = 'visible'" (mouseleave)="pieChartLabelSuccess.style.visibility = 'Hidden'" class="bar-chart-element" 
            [ngStyle]="{
              'height': calculateBarHeight(mailCounts[i].successMails, mailCounts[i].totalMails),
              'background-color': 'hsl(' + getColorByIndex(i) + ', 70%, 60%)'
            }">
              <div #pieChartLabelSuccess class="bar-chart-lable">
                <div style="font-size: 16px; font-weight: 500; padding-bottom: 8px;">
                  Success Mails
                </div>
                <div style="font-size: 14px">
                  {{mailCounts[i].successMails}}
                </div>
              </div>
            </div>
            <div (mouseover)="barChartLabelFailed.style.visibility = 'visible'" (mouseleave)="barChartLabelFailed.style.visibility = 'Hidden'"  class="bar-chart-element" 
            [ngStyle]="{
              'height': calculateBarHeight(mailCounts[i].failedMails, mailCounts[i].totalMails),
              'background-color': 'hsla(' + getColorByIndex(i) + ', 70%, 60%,0.66)'
            }">
              <div #barChartLabelFailed class="bar-chart-lable">
                <div style="font-size: 16px; font-weight: 500; padding-bottom: 8px;">
                  Failed Mails
                </div>
                <div style="font-size: 14px">
                  {{mailCounts[i].failedMails}}
                </div>
              </div>
            </div>
          </div>
          <div fxLayoutAlign="space-evenly space-evenly" fxLayoutGap="14px" class="description-container" fxFlex="35" fxLayout="column">
              <div fxLayoutGap="8px" fxLayout="column">
                <div class="bar-title">{{mailCounts[i].successMails}}</div>
                <div class="bar-content">Mails sent</div>
              </div>
              <div fxLayoutGap="8px" fxLayout="column">
                <div class="bar-title">{{mailCounts[i].failedMails}}</div>
                <div class="bar-content">Mails failed</div>
               </div>
          </div>
        </div>        
      </div>
    </div>
  </div>
  <div *ngIf="!(!initialLoader && data.length)" class="no-record-found commonStyle" data-control="noRecordsFound" fxLayout="column" fxLayoutGap="15px" fxLayoutAlign="center center">
    <img src="../../../../../assets/no-data-found.png" alt="noRecordsFound">
    <div class="no-record-found__text">No Data Found</div>
  </div>
</div>

<ng-template #viewReport>
  <app-view-mail-log [logId]="logId" (closeDialog)="closeDialog()" ></app-view-mail-log>
</ng-template>
