<div *ngIf="!initialLoader" class="common-header">
  <app-common-header [heading]="headerDetails" ></app-common-header>
</div>
<div *ngIf="initialLoader" class="common-card-style">
  <app-skeleton-loader [module]="'log'" [isView]="true" [formLoader]="true"></app-skeleton-loader>
</div>
<div *ngIf="!initialLoader" class="view-mail-log-container" fxLayout="column">
  <!--header-->
  <mat-card style="padding:20px 45px;" [ngStyle.lt-sm]="{'padding': '20px 30px'}">
    <div fxLayout="column" fxLayoutGap="2%">
  <div fxLayout="row">
    <div fxLayoutAlign="start center" fxFlex="30%" fxFlex.lt-sm="auto" [ngStyle]="{'padding-top':'20px'}">
      <h4 style="font-weight: 500;text-align: center;">Essential Details </h4>
    </div>
    <div fxFlex="80%" fxFlex.lt-sm="auto" fxLayoutAlign="end center" fxLayoutAlign.lt-sm="end center"
      fxLayoutGap="3%">
        <button (click)="onBackClick()" mat-button class="header-button" data-control="header-button">Back</button>
    </div>
  </div>
  <div>
    <hr [ngStyle]="{'border':'0.5px solid #e1e1e1'}">
  </div>
  </div>
  <!--stepper-->
  <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="2%">
    <!--view log form details-->
    <mat-card class="log-details"  fxFlex="65%" fxFlex.lt-md="50%">
      <form *ngIf="viewLogDetailsForm" [formGroup]="viewLogDetailsForm" fxLayout="column" fxLayoutGap="6px">
        <div fxLayout="column" fxLayout.lt-md="column" fxLayoutGap.lt-md="2%">
          <div fxLayout="column" fxLayoutGap="2%" fxFlex="50%" fxLayout.lt-md="column" fxLayoutGap.lt-md="2%">
            <mat-label class="form-label" data-control="applicationName" fxFlex="40%">Application Name</mat-label>
            <mat-form-field class="form-field" appearance="outline" fxFlex="60%">
              <input class="form-input" data-control="applicationName-input" matInput formControlName="applicationName">
            </mat-form-field>
          </div>
          <div fxLayout="column" fxLayoutGap="2%" fxFlex="50%" fxLayout.lt-md="column" fxLayoutGap.lt-md="2%">
            <mat-label class="form-label" data-control="customerName" fxFlex="40%">Customer Name</mat-label>
            <mat-form-field class="form-field" appearance="outline" fxFlex="60%">
              <input class="form-input" data-control="customerName-input" matInput formControlName="customerName">
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="column" fxLayout.lt-md="column" fxLayoutGap.lt-md="2%">
          <div fxLayout="column" fxLayoutGap="2%" fxFlex="50%" fxLayoutGap.lt-md="2%">
            <mat-label class="form-label" data-control="from" fxFlex="40%">From</mat-label>
            <mat-form-field class="form-field" appearance="outline" fxFlex="60%">
              <input class="form-input" data-control="from-input" matInput formControlName="from">
            </mat-form-field>
          </div>
          <div fxLayout="column" fxLayoutGap="2%" fxFlex="50%" fxLayout.lt-md="column" fxLayoutGap.lt-md="2%">
            <mat-label class="form-label" data-control="to" fxFlex="40%">To</mat-label>
            <mat-form-field class="form-field" appearance="outline" fxFlex="60%">
              <input class="form-input" data-control="to-input" matInput formControlName="to">
            </mat-form-field>
          </div>
        </div>
      </form>
    </mat-card>
    <mat-card class="stepper" fxFlex="35%" fxFlex.lt-md="50%">
      <ng-container *ngIf="stepperData">
        <div fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="5%" [ngStyle]="{'padding':'30px 8px'}">
          <div *ngIf="!isMailFailed">
            <h2 class="tracking-header">Consignment Tracking</h2>
          </div>
          <div fxLayout="column" fxLayoutGap="0%" [ngStyle]="{'width':isMailFailed?'100%':'90%'}" #scrollSection (mouseover)="onWheelScroll()">
            <div *ngIf="!isMailFailed ;else mailFailed">
            <ng-container *ngFor="let process of stepperData;let i=index">
              <div fxLayout="row">
                <div fxFlex="35%" fxLayout="column" fxLayoutAlign="start" [ngStyle]="{'animation-delay': getAnimationDelay(i),'color':process.status?'black':'gray'}" class="animate-left-to-right">
                  <p [ngStyle]="{'font-size':'14px','font-weight':'500'}"><span>{{process.date}}</span><span *ngIf="process.status">{{' at '}}</span> <span>{{process.time}}</span></p>
                </div>
                <div fxFlex="30%">
                  <div fxLayout="column" fxLayoutAlign="center center" class="tracking-container">
                    <div [ngClass]="{'success-outer-circle': isLastTrueStatus(i)}"
                    [ngStyle]="{'animation-delay': isLastTrueStatus(i) ? getAnimationDelay(i) : '0s'}" >
                      <div [ngClass]="{'success-tracking-dots': isAnimating(i), 'default-tracking-dots': !isAnimating(i)}"
                           [ngStyle]="{'animation-delay': getAnimationDelay(i)}"></div>
                    </div>      
                    <div *ngIf="!(stepperData.length === i + 1)">
                      <hr [ngClass]="{
                        'success-hr-tag': process.status && stepperData[i + 1].status}"
                      [ngStyle]="{'animation-delay': getAnimationDelay(i)}" class="default-hr-tag">
                    </div>
                  </div>
                </div>
                <div fxFlex="35%" fxLayout="row"  [ngStyle]="{'animation-delay': getAnimationDelay(i),'color':process.status?'black':'gray'}" class="animate-right-to-left">
                  <p [attr.data-control]="'progress-bar'+i"
                     [ngStyle]="{'font-size':'14px','font-weight':'500'}">{{process?.name}}</p>
                </div>
              </div>
            </ng-container>
          </div>
            <ng-template #mailFailed>
              <div fxLayout="column" fxLayoutGap="10%" fxLayoutAlign="center center">
                <img [src]="mailFailedImg" height="150px" width="150px">
                <p [ngStyle]="{'color':'#f91717','font-weight':'600','font-size':'35px'}">{{'Oops!'}}</p>
                <div [ngStyle]="{'width':'90%'}">
                <p [ngStyle]="{'text-align':'center','font-size':'15px','word-wrap': 'break-word'}">{{viewLogDetails!.maillogstatus[0].reason!==null?viewLogDetails!.maillogstatus[0].reason:'Something went wrong'}}</p>
               </div>
                <button mat-raised-button class="button-container">Try Again</button>
              </div>
            </ng-template>
          </div>
        </div>
      </ng-container>
    </mat-card>
  </div>
  <!--Log template-->
  <div class="content" fxLayout="column" fxLayoutGap="3%">
    <div fxLayout="column" fxLayoutGap="1%">
          <div fxFlex="30%" fxLayoutAlign="start center" [ngStyle]="{'padding-top':'10px'}">
            <h4 style="font-weight: 500;text-align: center;">Mail Content Details </h4>
          </div>
         <div>
          <hr [ngStyle]="{'border':'0.5px solid #e1e1e1'}">
         </div>
    </div>
      <editor [init]="editorConfig" [ngModel]="templateContent ? templateContent : 'No Content Available.'" [disabled]="false"
      ></editor>
  </div>
  </mat-card>
</div>