import { Injectable } from '@angular/core';
import { HttpRoutingService } from 'src/app/common/service/http-routing.service';
import { OverviewData } from '../models/auth.model';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  /**
   * component constructor which is used to inject the required services and modules.
   * @param httpClient To refer to the httpClient module to access the get and put methods.
   */
  constructor(private httpClient: HttpRoutingService) { }
  
  getOverviewDetails(filterValue : OverviewData){
    return this.httpClient.getRequest('microservice/mail/report/mailEvents' , {"filterOption" : filterValue.duration , "event" : filterValue.event});  
  }
}
