import { Pipe, PipeTransform } from '@angular/core';
/**
 * Pipe to determine whether the given value contain underscore it should be removed and change into Title Case.
 */
@Pipe({
  name: 'removeUnderscore'
})
export class RemoveUnderscorePipe implements PipeTransform {
    /**
   * Transforms the input value is used to remove the underscore.
   * @param value The input value (string).
   * @returns A string indicating removed underscore value and change into Titile Case.
   */
    transform(value: string, removeUnderscore: boolean|undefined): string{
      if (typeof value !== 'string' || !removeUnderscore|| removeUnderscore===undefined) {
        return value;
      }
      else{
      let result = value.replace(/_/g, ' ');
      result = result.split(' ').map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }).join(' ');
      return result;
      }
    }
}
