import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { SharedService } from '../../services/shared.service';
import { AuthService } from 'src/app/auth/service/auth.service';
import { TokenData, User } from 'src/app/auth/models/auth.model';
import { MenuItem, SideNavConfig } from '../../models/menu-bar.model';
import { CommonDialogService } from '../../service/common-dialog.service';
import { distinctUntilChanged, filter, Subscription, take } from 'rxjs';
import { CommonService } from '../../service/common.service';

@Component({
  selector: 'app-side-menu-bar',
  templateUrl: './side-menu-bar.component.html',
  styleUrls: ['./side-menu-bar.component.scss']
})
export class SideMenuBarComponent implements OnInit{
    /**
    * viewchild for get Mat-sidenav selector
    */
    @ViewChild(MatSidenav)

    /**
   * The side navigation component.
   * @type {MatSidenav}
   */
    sidenav!: MatSidenav;

    /**
     * Indicates whether the device is mobile.
     * @type {boolean}
     * @default true
     */
    isMobile: boolean = true;

    /**
     * to change animation based on the mat-drawer is closed or opened.
     * @type {boolean}
     * @default false
     */
    isCollapsed: boolean = false;

    /**
     * Indicates whether the side navigation is opened or closed.
     * @type { boolean }
     * @default true
     */
    isOpened: boolean = true;

    /**
     * The menus available in the side navigation.
     * @type {SideNavConfig}
     */
    menus!: SideNavConfig;

    /**
     * The ID of the currently selected menu.
     * @type {number}
     */
    menuId!: number;

    /**
     * Indicates whether to show the submenu.
     * @type {boolean}
     * @default true
     */
    showSubmenu: boolean = true;

    /**
     * The ID of the previously selected menu.
     * @type {number}
     */
    previousMenuId!: number;

    /**
     * The menus available in the side navigation.
     * @type {SideNavConfig}
     */
    menubarData!:any;
    // isDashboaderloader:boolean=false;
    isAdmin:boolean=false;
    userData?: any;
    lastClickedIndex: number | null = 0;
      /**
   * @type {Subscription}
   * Subscription for managing subscriptions.
   */
  subscription: Subscription = new Subscription();
  /**
   * Component constructor to inject required services.
   * @param breakpointObserver To check if it mobileview or webview.
   * @param router To navigate desired route.
   * @param sharedService To get menu details.
   */
  constructor(
    private observer: BreakpointObserver ,
    private router: Router,
    private sharedService : SharedService,
    public authService : AuthService,
    private dialogService:CommonDialogService,
    private commonService: CommonService
  ) {}
    /**
   * Angular life cycle hooks
   */
  ngOnInit() {
    this.subscription.add(this.authService.user$.subscribe((res: User[]|null) => {
      this.userData = res;
    }))
    this.subscription.add(this.authService.role$.pipe(
      filter((tokenData: TokenData | null) => tokenData?.roleId !== null),
      distinctUntilChanged((prev, curr) => prev?.roleId === curr?.roleId),
      // take(1)
    ).subscribe({
      next: (tokenData: TokenData | null) => {
        if(tokenData?.roleId===1){
          this.isAdmin=true;
          this.getmenuDetails(this.isAdmin);
       }else{
        this.isAdmin=false;
        this.getmenuDetails(this.isAdmin);
       }
      }
    }));
  }
  getmenuDetails(isAdmin?:boolean){
    // this.isDashboaderloader=true;
    this.subscription.add(this.sharedService.getMenuDetails().pipe(take(1)).subscribe((res: any) => {
      if(isAdmin){
        this.menubarData = res;
      } else{
        this.menubarData=res;
        this.menubarData = this.menubarData?.map((item:SideNavConfig) => {
          return {...item,
                 menu: item?.menu?.filter((menuItem:MenuItem) => menuItem?.pageCustomTitle !== 'Users')
          };
        });
      }
      // this.menus = this.menubarData[0]
      this.menubarData.forEach((item:any) => {
        item.isSelected = false;
      });
      this.menubarData[0]?.menu.forEach((item: any, index: number) => {
        item.isSelected = false;
      })
      this.menubarData[0].isSelected = true;
      this.menubarData[0].menu[0].isSelected = true;
      this.commonService?.menus?.next(this.menubarData[0]);
      this.commonService.setMenuId(this.menubarData[0].menu[0].id)
      this.menus = this.menubarData[0]
      // setTimeout(() => {
      // this.isDashboaderloader=false;
      // this.router.navigate(["app/mail/dashboard"]);
      // }, 500);
    }))
    this.isMobileScreen();
  }

    /**
  * The method used to detect the current screen size of the window
  */
  isMobileScreen(){
    this.observer.observe(['(max-width: 960px)']).subscribe((screenSize) => {
      if (screenSize.matches) {
        this.isMobile = true;
        this.isCollapsed = true;
      } else {
        this.isMobile = false;
        this.isCollapsed = !this.isOpened;
      }
    });
  }

   /**
  * The method used to load the menu datas.
  * @param index To get the index for selected menu.
  * @param selectedData To get selected menu detail.
  */
  menuDataLoading(index:number, selectedData:any){
    if (this.lastClickedIndex === index) {
      return;
    }
    this.lastClickedIndex = index;
    // if (index === 0) {
    //   this.router.navigate(["app/mail/dashboard"]);
    // } else if (index === 1) {
    //   this.router.navigate(["app/marketing/customers"]);
    // }else if(index===2){
    //   this.router.navigate(["app/zenmails/inbox"]);
    // }
    this.menubarData.forEach((item:any) => {
      item.isSelected = false;
    });
    selectedData?.menu?.forEach((item:any) => {
      item.isSelected = false;
      if(item?.subMenu?.length) {
        item.subMenu.forEach((subItem:any) => {
          subItem.isSelected = false
        })
      }
    });
    selectedData.isSelected = true;
    if(selectedData?.menu[0]?.subMenu?.length) {
      selectedData.menu[0].subMenu[0].isSelected = true
      this.commonService.setShowSubmenu(true)
    }
    selectedData.menu[0].isSelected = true
    this.commonService?.setMenuId(selectedData.menu[0].id)
    this.commonService?.menus?.next(this.menubarData[index]);
    this.subscription.add(this.commonService?.menus?.subscribe(res => {
      this.menus = res;
    }));
    this.subscription.unsubscribe();
    // selectedData.isRegion = true;
    // setTimeout(() => {
    //   this.isDashboaderloader=false;
    //   }, 500);
  }

   /**
  * The method used to sign out.
  */
  logout(){
     this.dialogService.openDialog({
      header: 'Logout',
      message: 'Are you sure you want to logout?',
      actionType: 'success',
      image:'../../../../assets/exit (1).png',
      button: { right: 'Yes, Logout', left: 'Stay' },
      disableClose: true
      }).subscribe((dialogResult:boolean)=>{
        if(dialogResult){
          this.router.navigate(["signin"]);
        }
      })
  }

  viewProfile() {
    this.router.navigate(['app/profile']);
  }

  /**
   * Toggles the state of the drawer and updates the `isCollapsed` variable.
   */
  drawerToggle(): void {
    setTimeout(() => {
      if(!this.isMobile) {
        this.isCollapsed = !this.isCollapsed
      }
      this.isOpened = !this.isOpened;
    }, 0)
  }
   /**
  * The method which is used navigate change password page.
  */
  onChangePassword(){
    this.router.navigate(["change-password"]);
  }
   /**
* Angular life cycle hook ngOnDestroy is used to unsubscribe the subscribtion.
* @type {void}
*/
ngOnDestroy(): void {
  if (this.subscription) {
    this.subscription.unsubscribe();
  }
}
}
