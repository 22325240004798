<div *ngIf="!initialLoader" class="common-header">
  <app-common-header [heading]="heading" ></app-common-header>
</div>
<div  *ngIf="initialLoader" class="common-card-style">
    <app-skeleton-loader [formLoader]="false"></app-skeleton-loader>
</div> 
<div fxLayout.lt-md="column">
    <app-common-filter *ngIf="isFilter" [appliedFiltersData]="routeParams" [filterData]="filterDetails" [isFilterStatus]="isFilterValue"
       (filterClose)="closeFilter()" (emittedData)="emittedEvent($event)" (AppliedFilters)="appliedFiltersLength($event)">
    </app-common-filter>
    <div fxFlex="100%" *ngIf="!initialLoader"  class="common-card-style">
        <app-common-card [sortSetting]="sorting" (sortChange)="sortData($event)" [dataNotFoundMessage]="noDataMessage" [columns]="columns" [dataSource]="data" [actionSetting]="actions" [hasSearchField]="true"
        (actionClicked)="onActionClicked($event)" [appliedFilters]="appliedFilters"
        (primarySelectEmittedData)="primaryDropdownEvent($event)"  [primaryFilterData]="primaryFilterOptions" [hasPrimaryFilter]="!!(primaryFilterOptions?.options?.length)"
        [searchSetting]="searchSettingData" [hasPagination]="true" (paginationChange)="handlePaginationChange($event)" 
        [paginationData]="paginationData"  [optionalEvent]="optionalEvent" [loading]="logsListLoader"
        (searchChange)="handleSearchChange($event)" (optionalEventOccurred)="handleOptionalEvent($event)"></app-common-card>
    </div>
  </div>