import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { AuthConstants } from '../../constant/auth.constants';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../service/auth.service';
import { CommonSnackbarService } from 'src/app/shared/service/common-snackbar.service';
import { VerifyToken } from '../../models/auth.model';

@Component({
  selector: 'app-registor',
  templateUrl: './registor.component.html',
  styleUrls: ['./registor.component.scss']
})

export class RegistorComponent extends AuthConstants implements OnInit {
  /**
   * FormGroup instance for the set password form.
   * @type {FormGroup}
   */
  passwordForm!: FormGroup;
/**
* varialbe which is used to define whether to display the newPassword in text format or not.
*/
  showNewPassword = { newPassword: false };
/**
* varialbe which is used to define whether to display the confirmPassword in text format or not.
*/
  showConfirmPassword = { confirmPassword: false };
/**
* varialbe which is used to define whether to display the oldPassword in text format or not.
*/
  showOldPassword = { oldPassword: false };
  /**
* varialbe which is used to hold the user token.
*/
  userToken!:string;
    /**
* varialbe which is used to indicate form is set password or reset password.
*/
  isChangePassword:boolean=false;
      /**
* varialbe which is used to store the loader value.
*/
  isLoading:boolean=false;
/**
* varialbe which is used to indicate api failed responses.
*/
  ApiFailed: boolean = false;
  /**
* varialbe which is used to indicate password vaidation will be show or not
*/
  isErrorShow:boolean=false;
  /**
* varialbe which is used to indicate user id
*/
  isUserId!:number|string|undefined;
  isFailedToken:boolean=false;
  image='../../../../assets/warning.png'
  isLoadingOldPassword: boolean=false;
  isVerified: boolean=false;
  isVerifiedFailed: boolean=false;
    /**
  * component constructor which is used to inject the required services.
  * @param snackbar To refer to the snack bar messages.
  * @param authService To refer to the AuthService to access the api .
  * @param paramsRouter To refer to the params of the current path.
  */
  constructor(private paramsRouter: ActivatedRoute,
    private authService:AuthService,
    private snackbar: CommonSnackbarService,
    private router: Router
  ){
    super();
  }
  /**
  * Angular life cycle hook that initiates the component
  */
  ngOnInit():void {
    const currentPath = this.paramsRouter.snapshot.routeConfig?.path;
    if(currentPath==='change-password'){
      this.isChangePassword = true;
      this.updateOldPasswordValidators();
    }
    if(!this.isChangePassword){
    this.paramsRouter.queryParams.subscribe((queryParams) => {
      if (queryParams) {
        const paramToken = queryParams['token'];
        this.userToken = encodeURIComponent(paramToken);
        if(this.userToken!=="undefined"){
        const token = {
          token: JSON.stringify(this.userToken)
        };
        this.authService.getUserToken(token).subscribe({
          next: (userDetails:VerifyToken) => {
            if (userDetails) {
              this.isUserId=userDetails?.response?.userId;
            }
          },error:(err)=>{
             this.isFailedToken=true;
          }
        });
         } else{
          this.isFailedToken=true;
         }
      }
    })
  }
    this.passwordFormInitialization();
  }
/**
* Method which is used to initialize the password form.
*/
  passwordFormInitialization() {
    this.passwordForm = new FormGroup({
      oldPassword:new FormControl(''),
      newPassword: new FormControl('', [Validators.required, this.passwordValidator]),
      confirmPassword: new FormControl('', [Validators.required]),
    });
    this.updateOldPasswordValidators();
    this.passwordForm.controls['oldPassword'].valueChanges.subscribe(value => {
      if (value) {
        this.verifyOldPassword(value);
      }
      else{
        this.isLoadingOldPassword=false;
      }
    });
  }
  /**
* Method which is used to verify the old password.
*/
  verifyOldPassword(value:string) {
    this.isLoadingOldPassword = true;
    const oldPassword={
      password:value
    }
    this.authService.verifyUser(oldPassword).subscribe({
      next:(response:VerifyToken)=>{
        if(response?.response?.success===true){
        this.isUserId=response?.response?.userId;
        this.isLoadingOldPassword = false;
        this.isVerified=true;
        }
        if(this.isLoadingOldPassword===true){
          this.isVerified=false;
        }      
      },error:(err)=>{
        this.isLoadingOldPassword = false;
        this.isVerifiedFailed=true;
      }
    })
  }
    /**
* Method which is used to set old password field as a required field.
*/
  updateOldPasswordValidators() {
    const oldPasswordControl = this.passwordForm?.controls['oldPassword'];
    if (this.isChangePassword) {
      oldPasswordControl?.setValidators([Validators.required]);
    } else {
      oldPasswordControl?.clearValidators();
    }
    oldPasswordControl?.updateValueAndValidity();
  }
/**
* Method which is used to define whether to display the password in text format or not.
*/
showPasswordType(field: 'newPassword' | 'confirmPassword'|'oldPassword') {
  if (field === 'newPassword') {
    this.showNewPassword.newPassword = !this.showNewPassword.newPassword;
  } else if (field === 'confirmPassword') {
    this.showConfirmPassword.confirmPassword = !this.showConfirmPassword.confirmPassword;
  }
  else if(field === 'oldPassword'){
    this.showOldPassword.oldPassword = !this.showOldPassword.oldPassword;
  }
}
/**
* Method which is used to check the password validation.
*/
passwordValidator(control: AbstractControl) {
  const value = control.value || '';
  const errors: any = {};
  if (!/[A-Z]/.test(value)) {
    errors.missingUpperCase = true;
  }
  if (!/[a-z]/.test(value)) {
    errors.missingLowerCase = true;
  }
  if (!/[0-9]/.test(value)) {
    errors.missingNumber = true;
  }
  if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
    errors.missingSpecialChar = true;
  }
  if (value.length < 8) {
    errors.invalidLength = true;
  }
  if (/\s/.test(value)) {
    errors.containsWhitespace = true;
  }
  return Object.keys(errors).length ? errors : null;
}
/**
* Method which is used to registor your password.
*/
onSetPassword(){
  if(this.passwordForm.valid && !this.isLoadingOldPassword){
  if(this.passwordForm.controls['newPassword'].value !==this.passwordForm.controls['confirmPassword'].value){
    this.passwordForm.get('confirmPassword')?.setErrors({ passwordMismatch: true });
  }
  if(this.passwordForm.valid && this.passwordForm.controls['newPassword'].value ===this.passwordForm.controls['confirmPassword'].value ){
    this.isLoading=true;
    const passwordDetails={
      password:this.passwordForm.controls['confirmPassword'].value,
      id:this.isUserId,
      isVerified:true
    }
    this.authService.passwordSet(passwordDetails).subscribe({
      next:(passwordDetails)=>{
       if(passwordDetails){
        this.isLoading=false;
        this.snackbar.OpenSnackBar({
          message:this.isChangePassword?'The password has been updated successfully.':'The password has been set up successfully.',
          heading: 'Success',
          actionType: 'success',
          duration: 2
        });
        this.router.navigate(['/signin']);
       }
      },error:(err)=>{
        this.isLoading=false;
        this.ApiFailed=true;
      }
    })
  }
}
}
/**
* Method which is used to show password strength through percentage.
*/
calculatePasswordStrength(): number {
  const errors = this.passwordForm.get('newPassword')?.errors;
  if (!errors) return 100;
  const totalChecks = 5;
  const passedChecks = totalChecks - Object.keys(errors).length;
  return (passedChecks / totalChecks) * 100;
}
/**
* Method which is used to show progress text color based on percentage.
*/
getProgressColor() {
  const strength = this.calculatePasswordStrength();
  if (strength === 100) {
    return 'green'; 
  } else if (strength >= 60) {
    return '#3e32f7';
  } else {
    return 'red';
  }
}
/**
* Method which is used to show password label text based on percentage.
*/
getPasswordStrengthLabel(): string {
  const strength = this.calculatePasswordStrength();
  if (strength === 100) {
    return 'Strong';
  } else if (strength === 60 || strength === 80) {
    return 'Normal';
  } else {
    return 'Weak';
  }
}
/**
* Method which is used to show all password validation.
*/
onShowvalidation(){
  this.isErrorShow=!this.isErrorShow;
}
/**
* Method which is used to disable the paste functionality.
*/
disablePaste(event: ClipboardEvent): void {
  event.preventDefault();
}
onBack(){
  this.router.navigate(['/app/mail/dashboard']);
}
}
