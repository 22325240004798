import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DesignValidation, EmittedData, FilterData, SelectOptions } from '../../models/common-filter.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { FilterType, filterConstants } from '../../constants/common-filter-data.contants';
import * as _ from 'lodash';
import { cloneDeep } from 'lodash';
import { CommonService } from '../../service/common.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-common-filter',
  templateUrl: './common-filter.component.html',
  styleUrls: ['./common-filter.component.scss']
})
export class CommonFilterComponent extends filterConstants implements OnInit {
  /**
   * Variable used to store input data for filter controls.
   * @type {FilterData}
   */
  @Input() filterData!: FilterData[];

  @Input() appliedFiltersData!: any;
  /**
   * Variable used to check visibility of the filter.
   * @type {boolean}
   */
  @Input() isFilterStatus:boolean =false;
  /**
   * Variable used to store input data for filter customisation values.
   * @type {DesignValidation}
   */
  @Input() filterDesign!: DesignValidation;
  /**
   * Variable used to emit output data to parent component.
   * @type {EventEmitter<EmittedData>}
   */
  @Output() emittedData: EventEmitter<EmittedData> = new EventEmitter();
  /**
   * Variable used to emit filter visible status to parent component.
   * @type {EventEmitter}
   */
  @Output() filterClose: EventEmitter<boolean> = new EventEmitter();

  /**
   * Variable used to emit applied filters length to parent component.
   * @type { EventEmitter<number> }
   */
  @Output() AppliedFilters: EventEmitter<number> = new EventEmitter();

  /**
   * Variable used to initialize the form group for the filter controls.
   * @type {FormGroup}
   */
  filterForm: FormGroup = new FormGroup({});
  /**
   * Variable used to store current date.
   * @type {Date}
   */
  today: Date = new Date();
  /**
   * Variable used to check the existence of typed input.
   * @type {boolean}
   */
  isSelectorOptions: boolean = false;
  /**
   * Variable used to store the options of type select.
   * @type {SelectOptions[]}
   */
  selectionConstant: SelectOptions[] = [];
  /**
   * Variable used to store filtered options of type select.
   * @type {SelectOptions[]}
   */
  selectionOptionValue: SelectOptions[] = [];
  /**
   * Variable used to store value of typed input in the search field.
   * @type {FormControl}
   */
  selectorSearchFilter: FormControl = new FormControl(null);
  /**
   * Variable used to emit the filters data.
   * @type {EmittedData}
   */
  emitValue!: EmittedData;
  /**
   * @type {string}
   * Date format used for displaying dates as 'dd MMM, yyyy'.
   */
  dateFormat: string = "dd MMM, yyyy"
  /**
   * Variable to save the applied filters
   * @type {number}
   */
  appliedFilters: number[] = [];
    /**
   * Variable to save the selected filters
   * @type {number}
   */
  selectedFilters: number[] = [];
  /**
   * Variable used to flag the filter is clicked or not
   * @type { boolean }
   */
  isFilterClicked: boolean = true;

  /**
   * Varialbe used to flag the component is initially loaded or not
   * @type { boolean }
   */
  initialLoad: boolean = true;

  /**
   * Variable used to store the observables.
   * @type { Subscription }
   */
  subscriptionObject: Subscription = new Subscription();
  /**
   * Component constructor for injecting needed services.
   * @param datepipe -transforms dates into formatted strings.
   */
  constructor(private datepipe: DatePipe, private commonService: CommonService) { super(); }

  /**
   * Angular life cycle hook used to intialize the component,
   */
  ngOnInit(): void {
    if (this.filterData && this.isFilterStatus) {
      this.formControlInit();
    }
    this.subscriptionObject.add(this.commonService.isFilterClicked.subscribe(res => {
      this.isFilterClicked = res;
    }))
    this.subscriptionObject.add(this.commonService?.cardInitialLoad.subscribe(res => {
      this.initialLoad = res;
    }))
    if(this.appliedFiltersData && this.commonService?.cardInitialLoad?.value) this.paramFilter() 
  }

  /**
   * Initializes the filter form and applies the currently selected filter values to the form controls.
   */
  paramFilter(): void {
    this.formControlInit();
    const filterIndex = this.filterData?.findIndex((res) => res?.field === Object.keys(this.appliedFiltersData)[0]);
    this.filterForm?.get(Object.keys(this.appliedFiltersData)[0])?.setValue(this.appliedFiltersData[Object.keys(this.appliedFiltersData)[0]]);
    this.selectedFilters = [filterIndex];
    this.appliedFilters = cloneDeep(this.selectedFilters);
    this.isFilterClicked = false;
    this.onSelectorSearchFilterClose();
  }
  /**
   * The method initialize the form controls based on the filter data.
   */
  formControlInit(): void {
    this.filterData.forEach((control) => {
      switch (control.type) {
        case FilterType.Select:
          this.onSelectionControl(control);
          break;
        case FilterType.Date:
          this.onDateControl(control);
          break;
        case FilterType.Text:
          this.onTextControl(control);
          break;
      }
    });
  }

  /**
   * The method adds a new FormControl for a selection field to the form group.
   * @param controlData consists of required information about selection control.
   */
  onSelectionControl(controlData: FilterData): void {
    this.filterForm.addControl(controlData.field, new FormControl(null));
  }

  /**
   * The method adds a new FormGroup with begin and end FormControls for a date range field to the form group.
   * @param controlData consists of required information about date control.
   */
  onDateControl(controlData: FilterData): void {
    this.filterForm.addControl(controlData.field,
      new FormGroup({ begin: new FormControl(null), end: new FormControl(null) }))
  }

  /**
   * The method adds a new FormControl with a no-space validation pattern for a text field to the form group.
   * @param controlData consists of required information about text control.
   */
  onTextControl(controlData: FilterData): void {
    this.filterForm.addControl(controlData.field,
      new FormControl(null, [Validators.pattern(this.patternValidator.noSpaceValidation)]));
  }

  /**
   * Adds the specified index to the selected filters if it is not already present.
   * @param { number } index 
   * @returns { void }
   */
  onFilterChange(index: number): void {
    if (!this.selectedFilters.includes(index)) {
      this.selectedFilters.push(index);
    }
  }

  /**
   * The method sets the formatted date values for the specified date filter.
   * @param filterIndex - The index of the filter in the filterData array.
   * @param type - The type of the filter, expected to be 'date'.
   */
  setDateValue(filterIndex: number, type: string): void {
    if (type == 'date' && this.filterForm && this.filterForm.value && this.filterData[filterIndex] && this.filterData[filterIndex].field && this.filterForm.value[this.filterData[filterIndex].field] && this.filterForm.value[this.filterData[filterIndex].field].begin && this.filterForm.value[this.filterData[filterIndex].field].end) {
      this.filterForm.value[this.filterData[filterIndex].field].begin = this.datepipe && this.datepipe.transform(this.filterForm.value[this.filterData[filterIndex].field].begin, this.filterData[filterIndex].format ? this.filterData[filterIndex].format : this.dateFormat);
      this.filterForm.value[this.filterData[filterIndex].field].end = this.datepipe && this.datepipe.transform(this.filterForm.value[this.filterData[filterIndex].field].end, this.filterData[filterIndex].format ? this.filterData[filterIndex].format : this.dateFormat);
    }
  }

  /**
   * The method filters the options in a dropdown based on the user's input.
   * @param value - The input event containing the user's search value.
   * @param filter - The filter configuration object containing the options and display name.
   */
  onSelectorSearchFilter(value: Event, filter?: FilterData): void {
   if(filter && filter.options && filter.displayName){
     let searchValue: string = (value.target as HTMLInputElement).value;
    if (searchValue && searchValue.length) {
      this.isSelectorOptions = true;
      let filterValue: string = searchValue.toString().trim().toLowerCase();
      this.selectionConstant =  filter.options;
      let filterkey: string = filter.displayName;
      const filteredArray = this.selectionConstant.filter((selectorValue: SelectOptions) => (selectorValue[filterkey].toString()).toLowerCase().includes(filterValue));
      this.selectionOptionValue = filteredArray;
    }
    else {
      this.onSelectorSearchFilterClose();
    }
   }
  }

  /**
   * The method closes the search filter and resets the search input and options.
   */
  onSelectorSearchFilterClose(): void {
    this.isSelectorOptions = false;
    this.selectorSearchFilter.setValue(null);
    this.selectionOptionValue = [];
  }

  /**
   * The method to validate date fields in the filter form.
   * @param isAction - A boolean flag to indicate if the validation should be performed.
   */
  onDateValidate(isAction: boolean): void {
    for (const filterValue of this.filterData) {
      if (filterValue.type == 'date' && isAction) {
        if ((this.filterForm.controls[filterValue.field] && this.filterForm.controls[filterValue.field].value) && this.filterForm.controls[filterValue.field].value.begin) {
          filterValue.error = true;
          if ((this.filterForm.controls[filterValue.field] && this.filterForm.controls[filterValue.field].value) && this.filterForm.controls[filterValue.field].value.end) {
            filterValue.error = false;
          }
        }
      }
      else {
        filterValue.error = false;
      }
    }
  }

  /**
   * Handles the apply action for the filter form.
   *
   * This method is called when the user clicks on the "Apply" button to apply the selected filters.
   * It emits the filtered data using the `emittedData` EventEmitter, passing the current value of the filter form.
   * Additionally, it closes the search filter for select inputs to reset the search state.
   */
  onApply(): void {
    this.commonService.isFilterClicked.next(false);
    this.filterData.map( (data,index) => { 
      this.setDateValue(index , data.type)
    })
    setTimeout(() => {
      this.emitValue = _.cloneDeep(this.filterForm.value);
      this.appliedFilters = cloneDeep(this.selectedFilters);
      this.onDateValidate(true);
      this.emittedData.emit(this.emitValue);
      this.AppliedFilters.emit(this.appliedFilters && this.appliedFilters.length);
      this.onSelectorSearchFilterClose();
    }, 300)
  }

  /**
   * Clears the selected form control value based on the given index and field.
   * @param { number } index 
   * @param { string } field 
   */
  clearOneFilter(index: number, field?: string): void {
    const SelectedfilterIndex = this.selectedFilters && this.selectedFilters.findIndex((element) => { return (element === index) });
    if (SelectedfilterIndex >= 0) {
      this.selectedFilters.splice(SelectedfilterIndex, 1);
    }
    const AppliedFilterIndex = this.appliedFilters && this.appliedFilters.findIndex((element) => { return (element === index) });
    if (AppliedFilterIndex >= 0) {
      this.appliedFilters.splice(AppliedFilterIndex, 1);
    }
    if(field && this.filterForm && this.filterForm.get(field))
      this.filterForm && this.filterForm.get(field) && this.filterForm.get(field)?.reset();
    this.emitValue = _.cloneDeep(this.filterForm.value);
    this.emittedData.emit(this.emitValue);
    this.AppliedFilters.emit(this.appliedFilters && this.appliedFilters.length);
  }

  /**
   * Method to find the applied filter index
   * @param { number } index 
   * @returns { number } index of the applied filter
   */
  findAppliedFilter(index: number): number {
    const filterIndex = this.appliedFilters && this.appliedFilters.findIndex((element: any) => { return index == element });
    return filterIndex;
  }

  /**
   * Method to find the selected filter index
   * @param index 
   * @returns index of the applied filter
   */
  findSelectedFilter(index: number): number {
    const filterIndex = this.selectedFilters && this.selectedFilters.findIndex((element: any) => { return index == element });
    return filterIndex;
  }

  /**
   * Method which is used to flag isFilterClicked for filter animation
   */
  toggleFilterStatus(): void {
    this.commonService.isFilterClicked.next(false);
  }
  
  /**
   * Handles the reset action for the filter form.
   *
   * This method is called when the user clicks on the "Reset" button to reset the filter form.
   * It resets the form to its initial state by calling the `reset` method on the filterForm FormGroup.
   * This clears all the form controls and their values.
   */
  onReset(): void {
    this.filterForm.reset();
    this.commonService.isFilterClicked.next(false);
    setTimeout(() => {
      this.selectedFilters = [];
      this.appliedFilters = [];
      this.appliedFiltersData = null
      this.onDateValidate(false);
      this.emitValue = _.cloneDeep(this.filterForm.value);
      this.filterClose.emit(true);
      this.AppliedFilters.emit(this.appliedFilters && this.appliedFilters.length);
    }, 300);
  }

  ngOnDestroy() {
    if(this.subscriptionObject) this.subscriptionObject.unsubscribe();
  }
}