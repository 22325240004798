import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/service/auth.service';
import { MenuItem, SideNavConfig, SubMenu } from '../../models/menu-bar.model';
import { CommonService } from '../../service/common.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-menu-drawer',
  templateUrl: './menu-drawer.component.html',
  styleUrls: ['./menu-drawer.component.scss']
})
export class MenuDrawerComponent implements OnInit{

    /**
     * Indicates whether the device is in a mobile view.
     * @type {boolean}
     * @default true
     */
    isMobile: boolean = true;

    /**
     * The ID of the currently selected menu.
     * @type {number}
     */
    menuId!: number;

    /**
     * Indicates whether to show the submenu.
     * @type {boolean}
     * @default true
     */
    showSubmenu: boolean = true;

    /**
     * The ID of the previously selected menu.
     * @type {number}
     */
    previousMenuId!: number;

    /**
     * Indicates whether the item is selected.
     * @type {boolean}
     * @default false
     */
    selected: boolean = false;

    /**
    * input given for menus
    * @type { SideNavConfig }
    */
    menus?: SideNavConfig;

    options = [
      { value: 'ap-south-1', viewValue: 'INDIA', countryImage:'../../../../assets/india.jpg' ,sortValue:'India' },
      { value: 'us-west-2', viewValue: 'USA', countryImage:'../../../../assets/USA.png' ,sortValue:'United States' }
    ];

    selectedOption!: any;
    /**
    * Variable used to store the observables.
    * @type {Subscription}
    */
    menuSubscription: Subscription = new Subscription();

    /**
    * Component constructor to inject required services.
    * @param router To navigate desired route.
    */
    constructor(private router: Router , private authService : AuthService, private commonService: CommonService) {}
    ngOnInit(){
      this.authService.region$.subscribe((res:string) => {
        this.selectedOption = this.options.filter((v:any)=>{
           return v.value === res;
        });
      })
      this.commonService?.showSubmenu$?.subscribe((value) => {
        this.showSubmenu = value;
      });
  
      this.commonService?.menuId$?.subscribe((id) => {
        this.menuId = id;
      });

      this.commonService?.previousMenuId$?.subscribe((previousId) => {
        this.previousMenuId = previousId
      })
      this.commonService?.menus?.subscribe(menu => {
        this.menus = menu;
        if (this.menus?.menu?.length > 0) {
          this.onRouterNavigation(this.menus.menu[0]);
        }
      });
    }
    /**
    * Method which is used to navigate to route while click menu.
    * @param menu To get particular menu detail.
    * @returns Returns true if menu having submenu.
    */
    onRouterNavigation(menu: MenuItem) {
      if (menu && menu.subMenu && menu.subMenu.length) {
        if (this.previousMenuId !== menu.id) {
          this.commonService.setShowSubmenu(true);
          this.commonService.setMenuId(menu.id);
          this.router.navigate([menu.subMenu[0].pageCustomLink]);
          this.setMenuSelection(menu);
        } else {
          this.commonService.setShowSubmenu(!this.showSubmenu);
        }
        this.commonService.setPreviousMenuId(menu.id);
      }
      if (menu?.menuCustomLink) {
        this.commonService.setMenuId(menu.id);
        if (this.previousMenuId !== menu.id) {
          this.commonService.setMenuId(menu.id);
          this.commonService.setShowSubmenu(false);
          this.router.navigate([menu.menuCustomLink]);
          this.setMenuSelection(menu);
        }
        this.commonService.setPreviousMenuId(menu.id);
      }
    }
    setMenuSelection(menu: MenuItem) {
      this.menus?.menu.forEach((item: MenuItem) => {
        item.isSelected = false;
        if (item.subMenu) {
          item.subMenu.forEach((subItem: SubMenu) => subItem.isSelected = false);
        }
      });
      menu.isSelected = true;
      this.commonService.setMenuId(menu.id);
      if (menu.subMenu && menu.subMenu.length > 0) {
        menu.subMenu[0].isSelected = true;
        this.commonService.setShowSubmenu(true);
      }
    }
    /**
   * Method which is used to navigate to route while clicking submenu.
   * @param routerLink To get router.
   * @param submenu To get selected list.
   */
    onSubMenuClick(routerLink:string, submenu:SubMenu) {
      this.menus?.menu.forEach((item:MenuItem) => {
        if (item.subMenu) {
          item.subMenu.forEach((subItem:SubMenu) => subItem.isSelected = false);
        }
      });
      submenu.isSelected = true;
      this.router.navigate([routerLink]);
    }

    onSelectionChange(event: MatSelectChange) {
      this.selectedOption = event.value;
      this.authService.region$.next(event.value.value);
    }
    
    ngOnDestroy() {
      if(this.menuSubscription) {
        this.menuSubscription.unsubscribe();
      }
    }
}
